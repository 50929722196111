import React, { useEffect, useState } from 'react'
// import ProductPopup from '@/components/PublicPopup'
import SpecificationAttrs from './SpecificationAttrs'
import { on_final_price, getActualPrice, on_total_num } from '@/utils/common'
import { Modal, Input, Button, Statistic, message } from 'antd'
import { getProduceDetailAttrs, getCategory } from '@/apis/goods'
import { useSelector } from 'react-redux'
import './index.scss'
// import SettlementPopup from '../SettlementPopup'
import Footer from './Footer'
// import SettlementLine from '../SettlementLine'
import GoodsCard from './GoodsCard'

interface IProps {
  /**
   * @name 关闭弹框
   */
  onClose: (params) => void
  /**
   * @name 确认按钮
   */
  onConfirm: (params) => void
  /**
   * @name 是否显示
   */
  visible: boolean
  /**
   * @name 盒子信息
   */
  selectSkuBox?: Store.Package.PackageSkuItem
  /**
   * @name 选中的内搭
   */
  selectSkus?: Store.Package.PackageSkuItem[]
  category_list?: any[]
}

const Collection: React.FC<IProps> = (props: IProps) => {
  const { selectSkuBox, selectSkus, category_list } = props
  const [cateIndex, setCateIndex] = useState(0)
  const [cateCurrent, setCateCurrent] = useState('')
  const [productList, setProductList] = useState<
    Store.Collocation.ICategoryList[]
  >([])
  const [hasNumList, setHasNumList] = useState<
    Store.Collocation.ICategoryProductItem[]
  >([])
  const [skuDetail, setSkuDetail] =
    useState<Store.Collocation.ICategoryProductItem>(
      {} as Store.Collocation.ICategoryProductItem
    )
  const [isShow, setIsShow] = useState(false)
  const [attrs, setAttrs] = useState<Store.Product.IProductAttrs>(
    {} as Store.Product.IProductAttrs
  )
  const [isPopBox, setIsPopBox] = useState(false)
  // const [categoryList, setCategoryList] = useState<any>([])

  useEffect(() => {
    if (props.visible) {
      init()
    }
  }, [props])

  // 初始化数据
  const init = async () => {
    // const category_list = await getCategory()
    // setCategoryList(category_list)
    let arr = category_list
    const selectSkuList = selectSkus || []
    if (selectSkuList.length > 0) {
      arr = changeHasNumList(selectSkuList, arr)
    }
    const result = onHasNumList(arr)
    setCateIndex(0)
    setHasNumList(result)
    setProductList(JSON.parse(JSON.stringify(arr)))
  }

  // 已有选中的内搭，格式化内搭列表数据
  const changeHasNumList = (selectSkuList, arr) => {
    console.log(selectSkuList, arr)
    selectSkuList.map((sku) => {
      const categoryIndex = arr.findIndex(
        (item) => Number(item.id) === Number(sku.category_id)
      )
      if (categoryIndex !== -1) {
        const productIndex = arr[categoryIndex].items?.findIndex(
          (item) =>
            Number(item.shop_spu_id) ===
            Number(sku.shop_spu_id ? sku.shop_spu_id : sku.id)
        )
        if (categoryIndex > -1 && productIndex > -1) {
          if (arr[categoryIndex].items[productIndex].is_manay_spce === 1) {
            const selectSku = arr[categoryIndex].items[productIndex].selectSkus
              ? arr[categoryIndex].items[productIndex].selectSkus
              : []
            const obj = {
              category_id: arr[categoryIndex].id,
              shop_spu_id: arr[categoryIndex].items[productIndex].shop_spu_id,
              shop_spu_name: sku.shop_spu_name,
              img: sku.img,
              price: sku.price,
              is_manay_spce:
                arr[categoryIndex].items[productIndex].is_manay_spce,
              shop_sku_id: sku.shop_sku_id,
              shop_sku_ids: [sku.shop_sku_id],
              shop_sku_name: sku.shop_sku_name,
              num: sku.num,
              price_list: sku.price_list,
              spec_info: sku.spec_info,
              shop_id: arr[categoryIndex].items[productIndex].shop_id,
            }
            selectSku.push(obj)
            const num = selectSku.reduce(
              // eslint-disable-next-line @typescript-eslint/no-shadow
              (num, prod) => num + Number(prod.num || 0),
              0
            )
            console.log('selectSku', selectSku);
            
            arr[categoryIndex].items[productIndex].selectSkus = selectSku
            arr[categoryIndex].items[productIndex].num = num
          } else {
            arr[categoryIndex].items[productIndex].num = sku.num
          }
        }
      }
    })
    console.log('arr', arr)

    return arr
  }

  // 处理数据---获取选中内搭品列表
  const onHasNumList = (product?) => {
    const pl = product ? product : productList
    const result = pl.map((item) => {
      return item.items
        .filter((sku) => sku.num > 0)
        .map((sku) => {
          if (sku.selectSkus && sku.selectSkus.length > 0) {
            return sku.selectSkus.map((selectSkusItem) => {
              return {
                category_id: item.id,
                ...selectSkusItem,
                inner_or_outer: 1,
              }
            })
          } else {
            return {
              category_id: item.id,
              ...sku,
              inner_or_outer: 1,
              shop_sku_id: sku.shop_sku_ids[0],
              shop_sku_name: sku.shop_sku_name
                ? sku.shop_sku_name
                : sku.shop_spu_name,
            }
          }
        })
    })
    let flatResult = flat(result)
    if (flatResult) {
      flatResult = flatResult.reduce((prev, curr) => {
        if (prev.findIndex((i) => i.shop_sku_id === curr.shop_sku_id) < 0) {
          prev.push(curr)
        }
        return prev
      }, [])
    }
    return flatResult
  }

  // 处理数组
  const flat = (array) => {
    return array.reduce((prev, curr) => {
      if (Array.isArray(curr) && curr.length > 0) {
        return prev.concat(flat(curr))
      } else if (Array.isArray(curr) && curr.length === 0) {
        return prev
      } else {
        return prev.concat([curr])
      }
    }, [])
  }

  // 操作---更换选中的数量
  const onChangeNum = async (product, index, changenum) => {
    let productList_new: Store.Collocation.ICategoryList[] = []
    productList_new = [...productList]
    // debugger
    if (product.shop_spu_id && product.is_manay_spce === 1) {
      const productAttrs = await getProduceDetailAttrs(
        Number(product.shop_spu_id)
      )
      const { spec_arr, sku_arr } = productAttrs
      console.log('productAttrs', productAttrs);
      
      if (spec_arr.length > 0 && sku_arr.length > 1 && changenum) {
        setAttrs(productAttrs)
        setSkuDetail(product)
        setIsShow(true)
        console.log('product', product);
      } else {
        if (
          selectSkuBox &&
          Number(selectSkuBox.shop_sku_id) ===
            Number(productList_new[cateIndex].items[index].shop_sku_ids[0])
        ) {
          // Taro.showToast({
          //   icon: 'none',
          //   title: '内搭不能与盒子一致',
          // })
          message.error('内搭不能与盒子一致')
          return
        }
        productList_new[cateIndex].items[index].num = changenum
      }
    } else {
      if (
        selectSkuBox &&
        Number(selectSkuBox.shop_sku_id) ===
          Number(productList_new[cateIndex].items[index].shop_sku_ids[0])
      ) {
        // Taro.showToast({
        //   icon: 'none',
        //   title: '内搭不能与盒子一致',
        // })
        message.error('内搭不能与盒子一致')
        return
      }
      productList_new[cateIndex].items[index].num = changenum
    }
    const result = onHasNumList(productList_new)
    setProductList(productList_new)
    setHasNumList(result)
  }

  // 操作---settlementPopup更改数量
  const onSettlementPopupChangeNum = (product, changenum) => {
    let productList_new: Store.Collocation.ICategoryList[] = []
    productList_new = [...productList]
    const category_index = productList_new.findIndex(
      (item) => item.id === product.category_id
    )
    if (product.is_manay_spce && product.is_manay_spce === 1) {
      const itemIndex = productList_new[category_index].items.findIndex(
        (item) => item.shop_spu_id === product.shop_spu_id
      )
      const productItem: Store.Collocation.ICategoryProductItem =
        productList_new[category_index].items[itemIndex]
      const selectSku = productItem.selectSkus ? productItem.selectSkus : []
      const selectIndx = selectSku?.findIndex(
        (sku) => sku.shop_sku_id === product.shop_sku_id
      )
      if (changenum <= 0) {
        selectSku.splice(selectIndx, 1)
      } else {
        selectSku[selectIndx].num = changenum
      }
      productItem.num = selectSku.reduce(
        // eslint-disable-next-line @typescript-eslint/no-shadow
        (num, prod) => num + Number(prod.num || 0),
        0
      )
    } else {
      const itemIndex = productList_new[category_index].items.findIndex(
        (item) => item.shop_spu_id === product.shop_spu_id
      )
      productList_new[category_index].items[itemIndex].num = changenum
    }
    const result = onHasNumList(productList_new)
    setProductList(productList_new)
    setHasNumList(result)
  }

  // 操作---内搭品选择规格属性
  const onSpeciConfirm = (params) => {
    if (
      selectSkuBox &&
      Number(selectSkuBox.shop_sku_id) === Number(params.shop_sku_id)
    ) {
      // Taro.showToast({
      //   icon: 'none',
      //   title: '内搭不能与盒子一致',
      // })
      message.error('内搭不能与盒子一致')
      return
    }
    let productList_new: Store.Collocation.ICategoryList[] = []
    productList_new = [...productList]
    // 先获取内搭品的位置
    const index = productList_new[cateIndex].items.findIndex(
      (item) => item.shop_spu_id === skuDetail.shop_spu_id
    )
    // 获取内搭品
    const productItem: Store.Collocation.ICategoryProductItem =
      productList_new[cateIndex].items[index]
    // 更改内搭品的数量，外部的总数量
    const num = productItem.num ? productItem.num : 0
    productItem.num = num + params.num
    // 内搭品下的sku列表
    const selectSku = productItem.selectSkus ? productItem.selectSkus : []
    // 在sku详情中添加部分属性，用于后期更改数量
    const obj = {
      category_id: productList_new[cateIndex].id,
      shop_spu_id: productItem.shop_spu_id,
      shop_spu_name: productItem.shop_spu_name,
      img: params.img,
      price: params.price,
      is_manay_spce: productItem.is_manay_spce,
      shop_sku_id: params.shop_sku_id,
      shop_sku_ids: [params.shop_sku_id],
      shop_sku_name: productItem.shop_spu_name + '-' + params.customizeName,
      num: params.num,
      shop_id: Number(productItem.shop_spu_id),
      model: params.three_dimensions,
      spec_info: params.spec_info,
    }
    // 匹配是否在已有的sku上做数量的更改
    const skuIndex = selectSku?.findIndex(
      (item) => item.shop_sku_id === obj.shop_sku_id
    )
    if (skuIndex > -1) {
      selectSku[skuIndex].num = selectSku[skuIndex].num + obj.num
    } else {
      // selectSku.push(obj)
    }
    productList_new[cateIndex].items[index].selectSkus = selectSku
    const result = onHasNumList(productList_new)
    setProductList(productList_new)
    setHasNumList(result)
    setIsShow(false)
  }

  // 操作---settlementPopup清空
  const onClear = () => {
    const productList_new = productList.map((item) => {
      item.items = item.items.map((sku) => {
        sku.num = 0
        if (sku.selectSkus && sku.selectSkus.length > 0) {
          sku.selectSkus = []
        }
        return { ...sku }
      })
      return item
    })
    const result = onHasNumList(productList_new)
    setHasNumList(result)
    setProductList(productList_new)
  }

  // 操作---切换左侧分类
  const onChangeCate = (type) => {
    setCateIndex(type)
    setCateCurrent('')
    const a = setTimeout(() => {
      setCateCurrent('top')
      clearTimeout(a)
    }, 10)
  }

  // 提交
  const onSubmit = () => {
    const obj = {
      skuList: [...hasNumList],
      selectSkuBox: selectSkuBox ? selectSkuBox : {},
    }
    setIsPopBox(false)
    console.log(obj);
    
    props.onConfirm(obj)
  }

  // 关闭保存记录
  const onClose = () => {
    const obj = {
      skuList: [...hasNumList],
    }
    setIsPopBox(false)
    props.onClose(obj)
  }

  // 左侧内搭分类
  const renderCateList = () => {
    return productList?.map((item, ind) => {
      const num = item?.items.reduce(
        // eslint-disable-next-line @typescript-eslint/no-shadow
        (num, prod) => num + Number(prod.num || 0),
        0
      )
      return (
        <div
          id={`category_${ind}`}
          className={['main_left_li', cateIndex === ind && 'active'].join(' ')}
          onClick={() => onChangeCate(ind)}
          key={item.id}
        >
          <span className="li_txt">{item.title}</span>
          {num > 0 && <span className="num">{num}</span>}
        </div>
      )
    })
  }

  // 内搭商品
  const renderProductItem = () => {
    return productList[cateIndex]?.items.map((product, index) => (
      <div className="main_right_li" key={product.shop_spu_id}>
        <img src={product.img} className={['main_right_img'].join(' ')}></img>
        <div className="main_right_info">
          <div>
            <div className="main_right_info_con">
              <div className="main_right_title t3">{product.shop_spu_name}</div>
              {product.has_model === 1 && (
                <div className="main_right_three t7 text-primary mt-12 bold">
                  3D
                </div>
              )}
              <div className="main_right_desc_con mt-16"></div>
            </div>

            <div className="main_right_price">
              <div
                className={[
                  'flex flex-wrap',
                  product.num && product.num > 0 ? 'main_right_price-left' : '',
                ].join(' ')}
              >
                <div className="mr-16">
                  <span className="p">¥</span>
                  {product.min_price
                    ? product.min_price === product.max_price
                      ? product.min_price
                      : product.min_price + '~' + product.max_price
                    : product.price}
                </div>
              </div>

              <div className="good_num_box">
                {product.num && Number(product.num) > 0 ? (
                  <div
                    className="good_nun_btn"
                    onClick={() => {
                      if (product.is_manay_spce === 1) {
                        setIsPopBox(true)
                      } else {
                        onChangeNum(
                          product,
                          index,
                          product.num ? product.num - 1 : 1
                        )
                      }
                    }}
                  ></div>
                ) : (
                  <></>
                )}
                {product.num && product.num > 0 ? (
                  <div className="good_nun">{Number(product.num)}</div>
                ) : (
                  <></>
                )}
                <div
                  className="good_nun_btn add"
                  onClick={() =>
                    onChangeNum(
                      product,
                      index,
                      product.num ? product.num + 1 : 1
                    )
                  }
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ))
  }

  // 操作，切换选择
  const onChangeSelect = (goodItem) => {
    console.log('goodItem', goodItem)
    console.log('productList', productList)
    let newProductList = JSON.parse(JSON.stringify(productList))
    let currentIndex = 0
    let num = 0
    newProductList[cateIndex].items = newProductList[cateIndex]?.items.map((item, index) => {
      if (item.shop_spu_id === goodItem.shop_spu_id) {
        if (goodItem.num) {
          item.selectSkus = undefined
          item.num = goodItem.num = num = 0
        } else {
          item.selectSkus = JSON.parse(JSON.stringify([goodItem]))
          item.num = 1
          num = 1
        }
        currentIndex = index
      }
      return item
    })
    console.log('newProductList', newProductList);
    onChangeNum(goodItem, currentIndex, num)
    
    // setProductList(newProductList)
    // changeHasNumList([goodItem], categoryList)
  }

  return (
    <>
      <Modal
        destroyOnClose
        open={props.visible}
        onCancel={onClose}
        footer={null}
        className="collocationPopup"
        bodyStyle={{ padding: '0' }}
        centered
        closeIcon={
          <span className="iconfont icon-yuyinguanbi t2 text-gray mt-20"></span>
        }
        zIndex={103}
      >
        <div className="header_top">
          <span className="t1 mb-24">选择内搭商品</span>
        </div>
        <div className="free_coll">
          {/* <div className="main_con">
            <div className="main_bottom">
              <div className="main_bottom_left">{renderCateList()}</div>
              <div
                className="main_bottom_right"
              >
                <div id="top"></div>
                {renderProductItem()}
              </div>
            </div>
          </div> */}
          <div className="flex flex-y-center mt-28 filter-b flex-wrap">
            {productList?.map((item, index) => (
              <button
                key={index}
                className={`button mr-8 mb-8 button-cate ${
                  index === cateIndex ? 'button-plain ' : 'button-gray'
                }`}
                onClick={() => onChangeCate(index)}
              >
                {item.title}
              </button>
            ))}
          </div>
          <div className='flex flex-x-center'>
          <div className="flex flex-wrap flex-start filter-m mt-28 w-full">
            {productList[cateIndex]?.items.length > 0 &&
              productList[cateIndex]?.items.map((item, index) => (
                <GoodsCard
                  key={index}
                  className={`mb-16`}
                  goodsInfo={item}
                  changeSelect={(goodItem) => onChangeSelect(goodItem)}
                ></GoodsCard>
              ))}
          </div>
          </div>
        </div>

        {/* <SettlementLine
          total_price={on_final_price(hasNumList, selectSkuBox)}
          total_num={on_total_num(hasNumList, selectSkuBox)}
          onClick={() => setIsPopBox(!isPopBox)}
          type={0}
        ></SettlementLine> */}

        {/* footer */}
        {/* <Footer confirmText="选好了" confirm={() => onSubmit()} width={248} /> */}
        <div className="goodsCardfooter">
          <div className="confirm" onClick={() => onSubmit()}>确定</div>
        </div>
      </Modal>

      {/* 规格属性弹框 */}
      <SpecificationAttrs
        img={skuDetail.img}
        changeNum
        visible={isShow}
        attrs={attrs}
        onClose={() => setIsShow(false)}
        onConfirm={onSpeciConfirm}
        goodsInfo={skuDetail as any}
        safeArea
      ></SpecificationAttrs>

      {/* <SettlementPopup
        onGoodNum={onSettlementPopupChangeNum}
        onClear={onClear}
        list={hasNumList}
        visible={isPopBox}
        onClose={() => setIsPopBox(false)}
        selectSkuBox={selectSkuBox || ({} as Store.Package.PackageSkuItem)}
        onConfirm={() => {
          setIsPopBox(false)
          onSubmit()
        }}
      ></SettlementPopup> */}
    </>
  )
}
export default Collection
