/*
 * @Author: axu 1342248325@qq.com
 * @Date: 2022-08-08 18:03:18
 * @LastEditors: axu 1342248325@qq.com
 * @LastEditTime: 2022-08-09 10:05:38
 * @FilePath: /js-view-newbox-h5/src/utils/logger.ts
 * @Description: 友盟埋点
 */
const logger = (
  category: string,
  action: string,
  label?: string,
  value?: any,
  nodeid?: string
) => {
  if (process.env.REACT_APP_ENV === 'production') {
    // @ts-ignore
    if (window._czc) {
      // @ts-ignore
      const _czc = window._czc || []
      _czc.push(['_trackEvent', category, action, label, value, nodeid])
    }
  } else {
    // console.log(`触发埋点：${category},${action},${label}`)
  }
}

export default logger
