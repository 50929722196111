/*
 * @Author: axu 1342248325@qq.com
 * @Date: 2022-07-08 10:39:07
 * @LastEditors: zhao_juchang 2233737325@qq.com
 * @LastEditTime: 2022-09-05 16:54:30
 * @FilePath: /js-view-newbox-h5/src/apis/pay.ts
 * @Description: 结算相关
 */
import request from '@/utils/request'

/**
 * 专线物流可选时间
 */
export const optionalTime = () =>
  request({
    method: 'GET',
    url: '/api/ToC/V1/Order/optionalTime',
  })

/**
 * 专线物流历史记录
 */
export const lastLogistics = () =>
  request({
    method: 'GET',
    url: '/api/ToC/V1/Order/lastLogistics',
  })

/**
 * 生成订单
 */
export const addOrders = (data: any) =>
  request({
    method: 'POST',
    url: '/api/ToC/V1/SaleOrder/add',
    data,
  })

/**
 * 获取结算价格
 */
export const getPayPrice = (data: any) =>
  request({
    method: 'POST',
    url: '/api/ToC/V1/SaleOrder/price',
    data,
  })

/**
 * 获取微信二维码
 */
export const getWechatCode = (data: any) =>
  request({
    method: 'POST',
    url: '/apiToC/V1/Pay/platformWechatPay',
    data,
  })

/**
 * 获取支付宝二维码
 */
export const getAlipayCode = (data: any) =>
  request({
    method: 'POST',
    url: '/api/ToC/V1/Pay/aliPay',
    data,
  })
/**
 * 余额支付
 */
export const balancePay = (data: any) =>
  request({
    method: 'POST',
    url: '/api/ToC/V1/Pay/balancePay',
    data,
  })
