import React, { useEffect, useState } from 'react'
import { useRouter } from '@/hooks'
import Header from '@/components/Header'
import GoodsImgs from './GoodsImgs'
import TemplateList from './TemplateList'
import GroupPopup from '@/components/GroupPopup'
import SpecificationAttrs from '@/components/SpecificationAttrs'
import LoginWrapper from '@/components/LoginWrapper'
import {
  getGoodsInfo,
  getGoodsSize,
  getGoodsDetailImg,
  getMarketImageList,
} from '@/apis/goods'
import logger from '@/utils/logger'
import { disableScroll, enableScroll, getUrlShopId, canCustome } from '@/utils/common'
import GoodsNumsPopover from '@/components/GoodsNumsPopover'
import { addCart, getCartList } from '@/apis/cart'
import InfiniteScroll from 'react-infinite-scroll-component'
import Toast from '@/utils/toast'
import { useAppDispatch, useAppSelector } from '@/store/store'
import './index.scss'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'

const Good: React.FC = () => {
  const { navigateTo, routerParams } = useRouter()
  const { id, templateId } = routerParams
  const dispatch = useAppDispatch()
  const userInfo = useAppSelector((state) => state.user.userInfo)
  const [goodsInfo, setGoodeInfo] = useState<Store.Goods.GoodsInfo>(
    {} as Store.Goods.GoodsInfo
  )
  const [market_img, setMarket_img] = useState<string[]>([])
  const [attrs, setAttrs] = useState<Store.Product.IProductAttrs>(
    {} as Store.Product.IProductAttrs
  )
  const [chooseSku, setChooseSku] = useState<
    Store.Product.IProductAttrsSkus | undefined
  >()
  const [minBuyNum, setMinBuyNum] = useState(1) // 最小购买数量
  const [num, setNum] = useState(minBuyNum)
  const [templateMarket, setTemplateMarket] = useState<any>({})
  const [spu_detail, setSpu_detail] = useState<string[]>([])
  const [templateDateil, setTemplateDateil] = useState<any>({})
  const [showGroupVidible, setShowGroupVidible] = useState(false) // 展示打开会员的弹窗
  const [cartList, setCartList] = useState<Store.Purchase.PruchaseItem[]>(
    [] as Store.Purchase.PruchaseItem[]
  ) // 购物车列表数据
  const [chooseTemplateId, setChooseTemplateId] = useState<number | string>(0)
  const shop = useAppSelector((state) => state.user.shop)

  useEffect(() => {
    logger('商品详情', '查看商品', userInfo.id ? String(userInfo.id) : '0')
    if (id) {
      fetchGoodsInfo()
      fecthGoodsSize()
    }
  }, [id])

  // 获取商品信息
  const fetchGoodsInfo = () => {
    const hide = Toast.loading('拼命加载中....')
    getGoodsInfo({ shop_spu_id: id })
      .then((res) => {
        console.log('res', res)
        setSpu_detail(res.content_img)
        hide()
        setTimeout(() => {
          hide()
        }, 500)

        setGoodeInfo(res)
        setMarket_img(res.main_img)
        if (res.can_custom === 1 && res.template_img.length > 0) {
          if (!templateId) {
            fetchGoodsDetail(Number(res.template_img[0].template_id))
          }
          const obj = { ...templateMarket }
          obj[templateId || res.template_img[0].template_id] = res.market_img
          setTemplateMarket(obj)
        }
        if (res.can_custom === 0) {
          fetchGoodsDetail(0)
        }
      })
      .catch((e) => {
        console.log(e)
        hide()
      })
  }

  // 获取商品尺寸
  const fecthGoodsSize = () => {
    getGoodsSize({ shop_spu_id: id }).then(
      (res: Store.Product.IProductAttrs) => {
        if (res.spec_arr?.length === 0 && res.sku_arr.length === 1) {
          res.sku_arr.forEach((item) => {
            if (
              item.price_list_status &&
              item.price_list &&
              item.price_list.length
            ) {
              item.min_buy_num = Math.max(
                Number(item.min_buy_num),
                Number(item.price_list[0].min_num)
              )
            }
          })
          setChooseSku(res.sku_arr[0])
          setMinBuyNum(res.sku_arr[0].min_buy_num)
          setNum(res.sku_arr[0].min_buy_num)
        } else {
          setAttrs(res)
        }
      }
    )
  }

  // 获取商品详情图
  const fetchGoodsDetail = (templateId: number) => {
    getGoodsDetailImg({ spu_id: id, template_id: templateId }).then((res) => {
      setSpu_detail(res)
      if (templateId) {
        const objs = { ...templateDateil }
        objs[templateId] = res
        setTemplateDateil(objs)
      }
    })
  }

  // 选择sku
  const handelChangeSku = (
    sku: Store.Product.IProductAttrsSkus | undefined
  ) => {
    console.log('sku', sku)
    if (sku?.price_list_status && sku.price_list && sku.price_list.length) {
      sku.min_buy_num = Math.max(
        Number(sku.min_buy_num),
        Number(sku.price_list[0].min_num)
      )
    }
    if (num < sku?.min_buy_num) {
      setNum(sku?.min_buy_num || 1)
    }
    setMinBuyNum(sku?.min_buy_num || 1)
    setChooseSku(sku)
  }

  // 加购
  const handelAddCart = async (dingZhiOrAdd, sale_cart_ids?) => {
    if (!chooseTemplateId) {
      Toast.warning('请先选择模版')
      return
    }
    if (!chooseSku?.shop_sku_id && attrs.spec_arr.length > 0) {
      Toast.warning('请选择规格属性')
      return
    }
    if (!num) {
      Toast.warning('请选择购买数量')
      return
    }

    if (dingZhiOrAdd === 'dingZhi') {
      handelToDingZhi(chooseTemplateId, id, dingZhiOrAdd)
      return
    }
    const cartList = await getCartList()
    console.log('cartList', cartList)

    // 如果购物车有组合，并且没sale_cart_ids 则显示购物车弹窗
    if (cartList.length > 0 && !sale_cart_ids) {
      setCartList(cartList)
      setShowGroupVidible(true)
      disableScroll()
      return
    }
    // 如果购物车没有组合则直接加购
    if (cartList.length <= 0 && chooseSku) {
      const data = {
        shop_sku_id: chooseSku.shop_sku_id,
        num,
        template_id: chooseTemplateId, // 定制品增加模版ID
      }
      const hide = Toast.loading('加购中....')
      addCart(data).then((res) => {
        console.log('res', res)
        hide()
        Toast.success('加购成功')
        // TODO: 购物车显示小圆点
        dispatch.cart.getCartnum()
        // if (res && res > 0) {
        //   handelToCustom(res, id, dingZhiOrAdd)
        // }
      })
      return
    }
    // 如果购物车有组合，并且有sale_cart_ids 则加入组合
    if (cartList.length > 0 && sale_cart_ids && chooseSku) {
      const data = {
        shop_sku_id: chooseSku.shop_sku_id,
        num,
        sale_cart_ids,
        template_id: chooseTemplateId, // 定制品增加模版ID
      }
      const hide = Toast.loading('加购中....')
      addCart(data).then((res) => {
        console.log('res', res)
        hide()
        Toast.success('加购成功')
        setShowGroupVidible(false)
        enableScroll()
        // if (res && res > 0) {
        //   handelToCustom(res, id, dingZhiOrAdd)
        // }
      })
      dispatch.cart.getCartnum()
    }
  }

  // 切换模版
  const onSetChooseTemplateId = async (templateId) => {
    setChooseTemplateId(templateId)
    const res = await getMarketImageList(Number(id), templateId)
    const imageList = res.map((item) => item.render_img)
    setGoodeInfo({
      ...goodsInfo,
      main_img: imageList,
    })
    setMarket_img(imageList)
  }

  // 加购物车的去定制
  const handelToCustom = (sale_cart_item_id, shop_spu_id, dingZhiOrAdd) => {
    console.log(window.localStorage.getItem('BY_userToken'))

    const devurl1 = 'http://192.168.200.72:3333/'
    const url = `${process.env.REACT_APP_NEW_H5}customMadeScss/scss?origin=${
      window.location.origin
    }&shop_id=${getUrlShopId()}&dingZhiOrAdd=${dingZhiOrAdd}&spuId=${Number(
      id
    )}&sale_cart_item_id=${sale_cart_item_id}&shop_spu_id=${shop_spu_id}&mini_token=${window.localStorage.getItem(
      'BY_userToken'
    )}&source=pad`
    // navigateToH5(url)
    window.open(url, '_blank')
  }

  // 只是去定制
  const handelToDingZhi = (chooseTemplateId, shop_spu_id, dingZhiOrAdd) => {
    console.log(window.localStorage.getItem('BY_userToken'))

    const devurl1 = 'http://192.168.200.72:3333/'
    const url = `${process.env.REACT_APP_NEW_H5}customMadeScss/scss?origin=${
      window.location.origin
    }&shop_id=${getUrlShopId()}&dingZhiOrAdd=${dingZhiOrAdd}&spuId=${Number(
      id
    )}&template_id=${chooseTemplateId}&shop_spu_id=${shop_spu_id}&mini_token=${window.localStorage.getItem(
      'BY_userToken'
    )}&source=pad`
    // navigateToH5(url)
    let newWindow = window.open(url, '_blank')
    if (newWindow) {
      setTimeout(() => {
        newWindow?.postMessage({type: 'c_shop_info', info: JSON.stringify(shop)}, '*')
      }, 1500)
    }
  }

  return (
    <div className="good pt-72">
      <Header />
      <InfiniteScroll
        className="InfiniteScroll"
        dataLength={0}
        next={() => {}}
        height={window.innerHeight - 72}
        hasMore={false}
        pullDownToRefresh={false}
        loader={''}
        endMessage={''}
      >
        <div className="main flex mt-52 pb-68 flex-x-center">
          <GoodsImgs market_img={market_img} />

          <div className="main-right">
            <div className="size">
              {/* 规格 */}
              <div className="mt-8 ">
                <SpecificationAttrs
                  attrs={attrs}
                  goodsInfo={goodsInfo}
                  chooseSku={chooseSku}
                  number={num}
                  onConfirm={(sku) => handelChangeSku(sku)}
                ></SpecificationAttrs>
              </div>
              {/* 我的模版 */}
              {goodsInfo.templateListUser &&
              goodsInfo.templateListUser.length > 0 ? (
                <>
                  <TemplateList
                    label="我的模版"
                    templateImg={goodsInfo.templateListUser}
                    chooseTemplateId={chooseTemplateId}
                    setChooseTemplateId={(id) => onSetChooseTemplateId(id)}
                  />
                  <div className="mt-16"></div>
                </>
              ) : null}
              {/* 官方模版 */}
              {goodsInfo.templateListSystem &&
              goodsInfo.templateListSystem.length > 0 ? (
                <>
                  <TemplateList
                    label="官方模版"
                    templateImg={goodsInfo.templateListSystem}
                    chooseTemplateId={chooseTemplateId}
                    setChooseTemplateId={(id) => onSetChooseTemplateId(id)}
                  />
                  <div className="mt-16"></div>
                </>
              ) : null}
              {/* 数量 */}
              <div className="mt-32">
                <div className="t4 bold mb-10">
                  数量{minBuyNum > 1 ? `（起订量：${minBuyNum}）` : ''}
                </div>
                <div className="speci-sku-box flex speci-sku-box-num">
                  {chooseSku &&
                  chooseSku.price_list &&
                  chooseSku.price_list.length > 0 &&
                  chooseSku.price_list_status ? (
                    <GoodsNumsPopover
                      interval_price={chooseSku?.price_list}
                      onChangeNum={(n: number) => setNum(n)}
                      number={num}
                      minBuyNum={minBuyNum || 1}
                      spu_id={Number(id) || 0}
                      noPopover={true}
                    >
                      <div className="select flex flex-y-center flex-between round-xs px-16 ">
                        <span className="t4">{num ? num : '选择数量'}</span>
                        <span className="iconfont icon-arrow t4 text-gray"></span>
                      </div>
                    </GoodsNumsPopover>
                  ) : (
                    <div className="speci-sku-num-box">
                      <div
                        className="speci-sku-btn "
                        onClick={() =>
                          setNum(num > minBuyNum ? num - 1 : minBuyNum)
                        }
                      >
                        <MinusOutlined
                          className={[
                            'iconfont t6 text-dark',
                            num <= minBuyNum ? 'icon-disable' : '',
                          ].join(' ')}
                        />
                      </div>
                      <input
                        className="speci-sku-div"
                        maxLength={4}
                        value={num as any}
                        onChange={(e) => setNum(parseInt(e.target.value))}
                        onBlur={(e) =>
                          setNum(
                            (parseInt(e.target.value) < minBuyNum
                              ? minBuyNum
                              : parseInt(e.target.value)) || minBuyNum
                          )
                        }
                      ></input>
                      <div
                        className="speci-sku-btn"
                        onClick={() => setNum(num + 1)}
                      >
                        <PlusOutlined className="iconfont t6 text-dark" />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {
                canCustome() ? (<LoginWrapper>
                  <button
                    className="button button-plain-white button-lg row mt-32"
                    onClick={() => handelAddCart('dingZhi')}
                  >
                    去定制
                  </button>
                </LoginWrapper>) : null
              }
              {((chooseSku?.status === 1 && chooseSku?.inventory !== 0) ||
                !chooseSku?.sku_id) && (
                <LoginWrapper>
                  <button
                    className={`button button-primary button-lg row ${
                      goodsInfo.can_custom === 1 ? 'mt-8' : 'mt-32'
                    }`}
                    onClick={() => handelAddCart('addCart')}
                  >
                    加入购物车
                  </button>
                </LoginWrapper>
              )}
              {(chooseSku?.status === 2 || chooseSku?.inventory === 0) && (
                <button
                  className={`button disabled button-lg row ${
                    goodsInfo.can_custom === 1 ? 'mt-8' : 'mt-32'
                  }`}
                >
                  {chooseSku?.status === 2 ? '该商品已下架' : '该商品已售罄'}
                </button>
              )}
            </div>
          </div>
        </div>
        {/* 详情图 */}
        <div className="good-detail flex flex-center flex-col py-40">
          <div className="good-detail-main round-sm px-46 py-40">
            <div className="t6 mb-20">商品详情</div>
            {spu_detail.map((item, index) => (
              <img src={item} alt="" key={index} />
            ))}
          </div>
        </div>
      </InfiniteScroll>
      {/* 加入组合弹窗 */}
      <GroupPopup
        visible={showGroupVidible}
        confirm={(sale_cart_ids) => handelAddCart('addCart', sale_cart_ids)}
        onClose={() => {
          setShowGroupVidible(false)
          enableScroll()
        }}
        list={cartList}
      ></GroupPopup>
    </div>
  )
}

export default Good
