import React, { useEffect, useState } from 'react'
import { useRouter } from '@/hooks'
import { getUrlShopId, canCustome } from '@/utils/common'
import './index.scss'

interface ComponentProps {
  orderInfo: any
}

const ProductInformation: React.FC<ComponentProps> = (
  props: ComponentProps
) => {
  const { routerParams } = useRouter()
  const { orderInfo } = props

  // 去定制
  const handelToCustom = (sku_item: any) => {
    console.log(window.localStorage.getItem('BY_userToken'));

    const devurl1 = 'http://192.168.200.72:3333/'
    const url = `${process.env.REACT_APP_NEW_H5}customMadeScss/scss?origin=${window.location.origin}&shop_id=${getUrlShopId()}&spuId=${Number(
      sku_item.shop_spu_id
    )}&sale_order_item_sku_id=${sku_item.id}&shop_spu_id=${sku_item.shop_spu_id
      }&mini_token=${window.localStorage.getItem('BY_userToken')}&source=pad`
    // navigateToH5(url)
    window.open(url, '_blank')
  }

  return (
    <div className="ProductInformation">
      <div className="information-header">
        <div className="col1">商品信息</div>
        <div className="col2">单价</div>
        <div className="col3">数量</div>
        <div className="col4">小计</div>
      </div>
      <div className="information-main">
        {orderInfo.sale_order_item &&
          orderInfo.sale_order_item.map((item: any) => {
            if (item.sale_order_item_sku.length == 1) {
              return (
                <div>
                  <div className="information-main-one">
                    <div className="col1 skuinfo normal">
                      <img
                        className="packages-box-img"
                        src={item.sale_order_item_sku?.[0]?.shop_sku_img}
                      />
                      <div>
                        <div className="">{item.title}</div>
                        <div className="sku-name">
                          {item.sale_order_item_sku?.[0]?.shop_sku_name}
                        </div>
                      </div>
                    </div>
                    <div className="col2 normal">¥{item.sale_order_item_sku[0].price}</div>
                    <div className="col3 normal">{item.quantity}</div>
                    <div className="col4 normal">¥{item.price}</div>
                  </div>
                  <div className="information-main-list">
                    <div className="information-main-list-item">
                      <div className="left">
                        {item.sale_order_item_sku[0].shop_sku_name}
                      </div>
                      <div className="right">
                        {item.sale_order_item_sku[0].is_custom === 1 && canCustome() ? (
                          <div
                            className='packages-box-content-spec-dingzhi'
                            onClick={() => handelToCustom(item.sale_order_item_sku[0])}
                          >
                            去定制
                          </div>
                        ) : (
                          ''
                        )}
                        x{item.sale_order_item_sku[0].num}
                      </div>
                    </div>
                  </div>
                </div>
              )
            } else {
              return (
                <div>
                  <div className="information-main-one">
                    <div className="col1 skuinfo normal">
                      <div className="packages-box-imgList">
                        <img
                          className="packages-box-imgList-img"
                          src={item?.sale_order_item_sku?.[0].shop_sku_img}
                        />
                        {item.sale_order_item_sku?.[1] ? (
                          <img
                            className="packages-box-imgList-img"
                            src={item.sale_order_item_sku?.[1].shop_sku_img}
                          />
                        ) : (
                          <div className="packages-box-imgList-bg"></div>
                        )}
                        {item.sale_order_item_sku?.[2] ? (
                          <img
                            className="packages-box-imgList-img"
                            src={item.sale_order_item_sku?.[2].shop_sku_img}
                          />
                        ) : (
                          <div className="packages-box-imgList-bg"></div>
                        )}
                        {item.sale_order_item_sku?.[3] ? (
                          <img
                            className="packages-box-imgList-img"
                            src={item.sale_order_item_sku?.[3].shop_sku_img}
                          />
                        ) : (
                          <div className="packages-box-imgList-bg"></div>
                        )}
                      </div>
                      <div>
                        <div className="">{item.title}</div>
                        <div className="sku-name">
                          共{item.sale_order_item_sku.length}种商品
                        </div>
                      </div>
                    </div>
                    <div className="col2 normal">¥{item.one_price}</div>
                    <div className="col3 normal">{item.quantity}</div>
                    <div className="col4 normal">¥{item.price}</div>
                  </div>
                  <div className="information-main-list">
                    {item.sale_order_item_sku.map((skuItem: any) => {
                      return (
                        <div className="information-main-list-item">
                          <div className="left">{skuItem.shop_sku_name}</div>
                          <div className="right">
                            {skuItem.is_custom === 1 && canCustome() ? (
                              <div
                                className='packages-box-content-spec-dingzhi'
                                onClick={() => handelToCustom(skuItem)}
                              >
                                去定制
                              </div>
                            ) : (
                              ''
                            )}
                            x{skuItem.num}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )
            }
          })}
      </div>
    </div>
  )
}

export default ProductInformation
