/*
 * @Author: axu 1342248325@qq.com
 * @Date: 2022-07-06 17:33:04
 * @LastEditors: zhao_juchang 2233737325@qq.com
 * @LastEditTime: 2022-09-05 14:06:07
 * @FilePath: /js-view-newbox-h5/src/components/GoodsCard/index.tsx
 * @Description: 商品卡片组件
 */
import React, { memo, useRef } from 'react'
import { isEqual } from 'lodash'
import { navigate_blank } from '@/utils/common'
import { useRouter } from '@/hooks'
import './index.scss'

interface ComponentProps {
  className?: string
  goodsInfo: Store.Collocation.ICategoryProductItem
  changeSelect: (goodsInfo: Store.Collocation.ICategoryProductItem) => void
}
const GoodsCard: React.FC<ComponentProps> = (props: ComponentProps) => {
  const { className = '', goodsInfo, changeSelect } = props
  const { navigateTo, navigateReplace } = useRouter()

  const onChangeSelect = () => {
    console.log('goodsInfo', goodsInfo)
    changeSelect(goodsInfo)
  }

  return (
    <>
      <div
        className={`box-goods-card1 ${className} flex flex-col`}
        onClick={() => onChangeSelect()}
      >
        <div className={`good-card-cover  round-sm `}>
          <div
            className={`iconfont icon-icon_select mr-8 ${
              goodsInfo.num ? 'checked' : ''
            }`}
          ></div>
          <img
            src={goodsInfo.img}
            alt="商品图"
            loading="lazy"
            className="fit-img"
          />
        </div>
        <span className="ellipsis1 t4 bold mt-16 good-card-title">
          {goodsInfo.shop_spu_name}
        </span>
        <span className="t5 text-gray mt-6 good-card-price">
          <span className="price-pre">¥</span>
          {goodsInfo.min_price
            ? goodsInfo.min_price === goodsInfo.max_price
              ? goodsInfo.min_price
              : goodsInfo.min_price + '~' + goodsInfo.max_price
            : goodsInfo.price}
        </span>
      </div>
    </>
  )
}

export default memo(GoodsCard, isEqual)
