import request from '@/utils/request'

// 获取首页分类列表
export const getCategoryList = (params?: any) =>
  request({ method: 'GET', url: '/api/ToC/V1/ShopSpu/getCategoryList', params })

// 获取首页分类数据
export const getCategoryData = (params?: any) =>
request({ method: 'GET', url: '/api/ToC/V1/Home/getCategoryData', params })

// 获取首页banner列表
export const getBanner = (params?: any) =>
request({ method: 'GET', url: '/api/ToC/V1/Index/getBanner', params })

// 获取ipad/pc 新首页主要数据
export const getFirstTagContent = (params?: any) =>
request({ method: 'GET', url: '/api/ToC/V1/Index/getFirstTagContent', params })

// 获取ipad/pc标签
export const getFirstTagInfo = (params?: any) =>
request({ method: 'GET', url: '/api/ToC/V1/Home/getFirstTagInfo', params })

// 获取ipad/pc 标签下商品
export const getTagGoods = (params?: any) =>
request({ method: 'GET', url: '/api/ToC/V1/Home/getTagGoods', params })

// 获取标签树
export const getTagTree = (params?: any) =>
request({ method: 'GET', url: '/api/ToC/V1/Home/getTagTree', params })

// 获取标签树无token验证
export const getTagTree1 = (params?: any) =>
request({ method: 'GET', url: '/api/ToC/V1/Index/getTagTree', params })