import request from '@/utils/request'


/**
 * 获取商品详情
 */
export const getPackageInfo = (params) =>
  request<Store.Package.PackageInfo>({
    method: 'GET',
    url: '/api/ToC/V1/ShopPackage/detail',
    params,
  })