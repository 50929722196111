import React, { useState, memo, useEffect } from 'react'
import { Tabs, Spin } from 'antd'
import GoodsCard from '@/components/GoodsCard'
import { useRouter } from '@/hooks'
import { getGoodsList } from '@/apis/goods'
import { getUrlShopId } from '@/utils/common'
import { isEqual } from 'lodash'
import './index.scss'
import { RightCircleOutlined } from '@ant-design/icons'

interface MallCardProps {
  tags: any
  gridLen: number
}

const MallTabs: React.FC<MallCardProps> = (props: MallCardProps) => {
  const { tags, gridLen } = props
  const { TabPane } = Tabs
  const { navigateTo } = useRouter()
  const [goods, setGoods] = useState<Store.Goods.GoodsListItem[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (tags.length > 0) {
      // setChooseTag(tags[0].id)
    }
  }, [tags])

  const handelToTagGoods = () => {
    // navigateTo(`/tagGoods?first_tag_id=${tags.id}`)
    window.open(`/tagGoods?shop_id=${getUrlShopId()}&first_tag_id=${tags.id}`, '_blank')
  }

  return (
    <div className="MallTabs">
      <div className="mall-commodity commodity py-24">
        <div
          className="mall-card-box"
          id={`mall-card-${tags.id}`}
          onClick={() => handelToTagGoods()}
        >
          <div className="mall-card-title">{tags.title}</div>
          <div className="mall-card-more">
            查看更多
            <RightCircleOutlined className='ml-12' />
          </div>
          <img src={tags.card_img} className="mall-card-img" />
        </div>
        {tags.goods_list &&
          tags.goods_list.length > 0 &&
          tags.goods_list.map((item: any, index: number) => {
            if (index < 8) {
              return (
                <GoodsCard
                  className=""
                  key={index}
                  goodsInfo={item}
                ></GoodsCard>
              )
            } else {
              return null
            }
          })}
      </div>
    </div>
  )
}

export default memo(MallTabs, isEqual)
