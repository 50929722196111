/*
 * @Author: axu
 * @Date: 2021-04-23 18:46:19
 * @LastEditTime: 2021-07-13 16:26:29
 * @LastEditors: Please set LastEditors
 * @Description: 商品详情
 * @FilePath: /fitatom-shop-miniprogram/src/pages/goods/DetailMidInfo/index.tsx
 */
import React, { memo } from 'react'
import './index.scss'

interface IProps {
  name: string
  // minPrice: string
  // maxPrice: string
  showPackPrice: string
  shop_package_title?: string
}

const Index: React.FC<IProps> = (props) => {
  const { name, showPackPrice, shop_package_title } = props

  return (
    <div className="product-info pt-20 px-28 pb-32">
      <div className="t2 bold">{name}</div>
      {shop_package_title ? (
        <div className="t2 text-gray600">{shop_package_title}</div>
      ) : null}
      {showPackPrice && (
        <span className="product-info-price t1">
          <span className="font-18">¥</span>
          {showPackPrice}
        </span>
      )}
    </div>
  )
}

export default memo(Index)
