/*
 * @Author: axu 1342248325@qq.com
 * @Date: 2022-07-04 20:12:26
 * @LastEditors: axu 1342248325@qq.com
 * @LastEditTime: 2022-08-04 16:12:02
 * @FilePath: /my-app/src/store/models/index.ts
 * @Description: models
 */

import { Models } from '@rematch/core'
import { global } from './global'
import { user } from './user'
import { address } from './address'
import { cart } from './cart'
import { app } from './app'
import { home } from './home'

export interface RootModel extends Models<RootModel> {
  global: typeof global
  user: typeof user
  address: typeof address
  cart: typeof cart
  app: typeof app
  home: typeof home
}

export const models: RootModel = {
  global,
  user,
  address,
  cart,
  app,
  home,
}
