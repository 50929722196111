/*
 * @Author: axu 1342248325@qq.com
 * @Date: 2022-07-08 10:39:07
 * @LastEditors: axu 1342248325@qq.com
 * @LastEditTime: 2022-07-15 17:06:03
 * @FilePath: /js-view-newbox-h5/src/apis/goods.ts
 * @Description: 地址相关
 */
import request from '@/utils/request'

/**
 * 获取收货地址
 */
export const getAddressList = () =>
  request<Store.Address.AddressInfo[]>({
    method: 'GET',
    url: '/api/ToC/V1/CUserAddress/list',
  })

/**
 * 新增收货地址
 */
export const addAddress = (data: any) =>
  request({
    method: 'POST',
    url: '/api/ToC/V1/CUserAddress/add',
    data,
  })

/**
 * 编辑收货地址
 */
export const editAddress = (data: any) =>
  request({
    method: 'POST',
    url: '/api/ToC/V1/CUserAddress/edit',
    data,
  })

/**
 * 删除收货地址
 */
export const delAddress = (data: any) =>
  request({
    method: 'POST',
    url: '/api/ToC/V1/CUserAddress/delete',
    data,
  })

/**
 * 设置默认收货地址
 */
export const setDefaultAddress = (data: any) =>
  request({
    method: 'POST',
    url: '/api/ToC/V1/CUserAddress/default',
    data,
  })

/**
 * 获取默认收货地址
 */
export const getDefaultAddress = () =>
  request<Store.Address.AddressInfo>({
    method: 'GET',
    url: '/api/ToC/V1/CUserAddress/defaultAddress',
  })

/**
 * 获取省市区选项
 */
export const getRegionOptions = () =>
  request({
    method: 'GET',
    url: '/api/ToC/V1/CUserAddress/getRegionOptions',
  })
