import React, { useEffect, useState } from 'react'
import { useRouter } from '@/hooks'
import Header from '@/components/Header'
import GoodsImgs from '@/pages/goods/GoodsImgs'
import GroupPopup from '@/components/GroupPopup'
import CollocationPopup from '@/components/CollocationPopup'
import SpecificationAttrs from '@/components/SpecificationAttrs'
import LoginWrapper from '@/components/LoginWrapper'
import GoodsNumsPopover from '@/components/GoodsNumsPopover'
import { getCategory } from '@/apis/goods'
import logger from '@/utils/logger'
import { addCart, getCartList, batchAddSku } from '@/apis/cart'
import InfiniteScroll from 'react-infinite-scroll-component'
import Toast from '@/utils/toast'
import { useAppDispatch, useAppSelector } from '@/store/store'
import './index.scss'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { getPackageInfo } from '@/apis/package'
import {
  on_total_num,
  on_final_price,
  disableScroll,
  enableScroll,
  multiply,
  add,
} from '@/utils/common'
import DetailMidInfo from './DetailMidInfo'
import { message } from 'antd'
import { resolve } from 'path'

const Good: React.FC = () => {
  const { navigateTo, routerParams } = useRouter()
  const { id, templateId } = routerParams
  const dispatch = useAppDispatch()
  const userInfo = useAppSelector((state) => state.user.userInfo)
  const [packageInfo, setPackageInfo] = useState<Store.Package.PackageInfo>(
    {} as Store.Package.PackageInfo
  )
  const [market_img, setMarket_img] = useState<string[]>([])
  const [attrs, setAttrs] = useState<Store.Product.IProductAttrs>(
    {} as Store.Product.IProductAttrs
  )
  const [chooseSku, setChooseSku] = useState<
    Store.Product.IProductAttrsSkus | undefined
  >()
  const [minBuyNum, setMinNum] = useState(1) // 最小购买数量
  const [num, setNum] = useState(minBuyNum)
  const [templateMarket, setTemplateMarket] = useState<any>({})
  const [spu_detail, setSpu_detail] = useState<string[]>([])
  const [showGroupVidible, setShowGroupVidible] = useState(false) // 展示打开会员的弹窗
  const [cartList, setCartList] = useState<Store.Purchase.PruchaseItem[]>(
    [] as Store.Purchase.PruchaseItem[]
  ) // 购物车列表数据
  const [inside_sku, setInside_sku] = useState<Store.Three.ThreeItem[]>([])
  const [hasThree, setHasThree] = useState(false)
  const [show3D, setShow3D] = useState(false)
  const [isCollocationPoupu, setIsCollocationPoupu] = useState(false)
  const [category_list, setCategory_list] = useState<any>([])
  const [showPackPrice, setShowPackPrice] = useState<any>('0')

  useEffect(() => {
    logger('商品详情', '查看商品', userInfo.id ? String(userInfo.id) : '0')
    if (id) {
      fetchpackageInfo()
      onGetCategory()
    }
  }, [id])

  // 获取商品信息
  const fetchpackageInfo = () => {
    const hide = Toast.loading('拼命加载中....')
    getPackageInfo({ id: id })
      .then((res) => {
        console.log('res', res)
        setSpu_detail(res.content_img)
        hide()
        setTimeout(() => {
          hide()
        }, 500)

        res.inner_arr = resolvePrice(res.inner_arr, res.outer_arr)
        setPackageInfo(res)
        setMarket_img(res.main_img)
        if (res.can_custom === 1 && res.template_img.length > 0) {
          const obj = { ...templateMarket }
          obj[templateId || res.template_img[0].template_id] = res.market_img
          setTemplateMarket(obj)
        }
      })
      .catch((e) => {
        console.log(e)
        hide()
      })
  }

  //
  const onGetCategory = async () => {
    const category_list = await getCategory()
    setCategory_list(category_list)
  }

  // 商品加购
  const handelAddCart = async (sale_cart_ids?) => {
    if (!chooseSku?.shop_sku_id && attrs.spec_arr.length > 0) {
      // Toast.warning('请选择规格属性')
      message.warning('请选择规格属性')
      return
    }
    if (!num) {
      // Toast.warning('请选择购买数量')
      message.warning('请选择购买数量')
      return
    }

    const cartList = await getCartList()
    console.log('cartList', cartList)

    // 如果购物车有组合，并且没sale_cart_ids 则显示购物车弹窗
    if (cartList.length > 0 && !sale_cart_ids) {
      setCartList(cartList)
      setShowGroupVidible(true)
      return
    }
    // 如果购物车没有组合则直接加购
    if (cartList.length <= 0 && chooseSku) {
      const data = {
        shop_sku_id: chooseSku.shop_sku_id,
        num,
      }
      const hide = Toast.loading('加购中....')
      addCart(data).then(() => {
        hide()
        // Toast.success('加购成功')
        message.success('加购成功')
        // TODO: 购物车显示小圆点
        dispatch.cart.getCartnum()
      })
      return
    }
    // 如果购物车有组合，并且有sale_cart_ids 则加入组合
    if (cartList.length > 0 && sale_cart_ids && chooseSku) {
      const data = {
        shop_sku_id: chooseSku.shop_sku_id,
        num,
        sale_cart_ids,
      }
      const hide = Toast.loading('加购中....')
      addCart(data).then(() => {
        hide()
        // Toast.success('加购成功')
        message.success('加购成功')
        setShowGroupVidible(false)
      })
      dispatch.cart.getCartnum()
    }
  }

  // 套餐加入购物车
  const goCard = async () => {
    const arr: any[] = []
    packageInfo.outer_arr.map((item) => {
      arr.push({
        shop_sku_id: item.shop_sku_id,
        num: item.num,
        is_box: item.is_box ? item.is_box : 1,
      })
    })
    packageInfo.inner_arr.map((item) => {
      arr.push({
        shop_sku_id: item.shop_sku_id,
        num: item.num,
        is_box: item.is_box ? item.is_box : 0,
      })
    })
    const obj = {
      items: arr,
      sale_cart_ids: [],
      num: num,
    }
    await batchAddSku(obj)
    setTimeout(() => {
      // Taro.showToast({
      //   icon: 'none',
      //   title: '添加成功，在购物车等亲～',
      //   duration: 3000,
      // })
      message.success('添加成功，在购物车等亲～')
    }, 100)
  }

  // 详情页更改数量
  const onChangeNum = (item, num, isMinBugNum?) => {
    const info = packageInfo
    const index = info.inner_arr.findIndex(
      (i) => i.shop_sku_id === item.shop_sku_id
    )

    if ((isMinBugNum && num < item.min_buy_num) || num <= 0) {
      message.warning(`该商品最少购买${item.min_buy_num}件,已删除`)
      info.inner_arr.splice(index, 1)
    } else {
      item.num = num
      info.inner_arr[index] = item
    }
    info.inner_arr = resolvePrice(info.inner_arr, info.outer_arr)

    setPackageInfo(info)
    updateThree(info)
  }

  // 更新3D内搭
  const updateThree = (info, flag?) => {
    console.log(info)
    if (!info.outer_arr[0] || !info.outer_arr[0].model) {
      return
    }
    if (info.inner_arr.length > 0) {
      const index = info.inner_arr.findIndex((item) => !item.model)
      if (index === -1) {
        const arr: Store.Three.ThreeItem[] = []
        info.inner_arr.map((item) => {
          const inner = {
            id: Number(item.shop_sku_id),
            img: item.img,
            count: item.num,
            model: item.model,
          }
          arr.push(inner)
        })
        setInside_sku(arr)
        setHasThree(true)
        // setTimeout(() => {
        //   threeC.current?.refresh()
        // }, 100)
        // if (flag !== '3d') {
        //   if (threeToast) {
        //     if (time) {
        //       clearInterval(time)
        //       time = setTimeout(() => {
        //         setThreeToast(false)
        //       }, 3000)
        //     }
        //   } else {
        //     setThreeToast(true)
        //     time = setTimeout(() => {
        //       setThreeToast(false)
        //     }, 3000)
        //   }
        // }
      } else {
        setHasThree(false)
        setShow3D(false)
      }
    } else {
      setInside_sku([])
      setHasThree(true)
    }
  }

  // 内搭弹框
  const onCollocationConfirm = (params) => {
    const info = { ...packageInfo }
    const skuList = params.skuList
    info.inner_arr = skuList
    console.log('9999999', info.inner_arr)

    info.inner_arr = resolvePrice(info.inner_arr, info.outer_arr)
    setPackageInfo(info)
    updateThree(info)
    setIsCollocationPoupu(false)
    enableScroll()
  }

  // 去商品详情
  const handelToGood = (item) => {
    if (item.is_custom === 1) {
      navigateTo(`/goods_heli/${item.shop_spu_id}`)
      return
    } else {
      navigateTo(`/goods/${item.shop_spu_id}`)
    }
  }

  // 价格处理
  const resolvePrice = (inner_arr, outer_arr) => {
    console.log('inner_arr', inner_arr)

    let innerTotalPrice = 0
    inner_arr = inner_arr.map((item) => {
      let showPrice = '0'
      if (item.price_list && item.price_list.length > 0 && item.price_list_status) {
        item.price_list.map((price_item: any) => {
          if (
            price_item.min_num <= item.num &&
            price_item.max_num >= item.num
          ) {
            innerTotalPrice = add(
              multiply(price_item.sale_price_format, item.num),
              innerTotalPrice
            )
            showPrice = `¥${price_item.sale_price_format}（${price_item.min_num}-${price_item.max_num}件）`
          }
        })
      } else {
        innerTotalPrice = add(multiply(item.price, item.num), innerTotalPrice)
        showPrice = `¥${item.price}`
      }
      item.showPrice = showPrice
      return item
    })
    let outTotalPrice = ''
    if (outer_arr && outer_arr.length > 0) {
      outer_arr.map((item) => {
        if (item.price_list && item.price_list.length > 0 && item.price_list_status) {
          item.price_list.map((price_item: any) => {
            if (
              price_item.min_num <= item.num &&
              price_item.max_num >= item.num
            ) {
              outTotalPrice = `${price_item.sale_price_format}`
            }
          })
        } else {
          outTotalPrice = `${item.price}`
        }
        item.showPrice = `${outTotalPrice}`
      })
    }
    setShowPackPrice(add(innerTotalPrice, outTotalPrice))
    return inner_arr
  }

  return (
    <div className="good pt-72">
      <Header />
      <InfiniteScroll
        className="InfiniteScroll"
        dataLength={0}
        next={() => {}}
        height={window.innerHeight - 72}
        hasMore={false}
        pullDownToRefresh={false}
        loader={''}
        endMessage={''}
      >
        <div className="main flex mt-52 pb-68 flex-x-center">
          <GoodsImgs market_img={market_img} />

          <div className="main-right">
            <div className="size">
              {/* 规格 */}
              {/* <div className="mt-8 ">
                <SpecificationAttrs
                  attrs={attrs}
                  packageInfo={packageInfo}
                  onConfirm={(sku) => handelChangeSku(sku)}
                ></SpecificationAttrs>
              </div> */}
              <div className="title flex flex-col borderBtm">
                <span className="t5 span-gray mt-6 mb-20">
                  {/* {packageInfo.content_span} */}
                  {/* 基本信息 */}
                  <DetailMidInfo
                    name={packageInfo.shop_package_name}
                    shop_package_title={packageInfo.shop_package_title}
                    showPackPrice={showPackPrice}
                  />
                </span>
              </div>
              {/* 数量 */}
              <div className="mt-32">
                <div className="t4 bold mb-10">数量</div>
                <div className="speci-sku-box flex speci-sku-box-num">
                  <div className="speci-sku-num-box">
                    <div
                      className="speci-sku-btn "
                      onClick={() => setNum(num > 1 ? num - 1 : 1)}
                    >
                      <MinusOutlined
                        className={[
                          'iconfont t6 span-dark',
                          num === 1 ? 'icon-disable' : '',
                        ].join(' ')}
                      />
                    </div>
                    <input
                      className="speci-sku-div"
                      type="number"
                      maxLength={4}
                      value={num as any}
                      onChange={(e) => setNum(parseInt(e.target.value) || 1)}
                    ></input>
                    <div
                      className="speci-sku-btn"
                      onClick={() => setNum(num + 1)}
                    >
                      <PlusOutlined className="iconfont t6 span-dark" />
                    </div>
                  </div>
                </div>
              </div>
              {((chooseSku?.status === 1 && chooseSku?.inventory !== 0) ||
                !chooseSku?.sku_id) && (
                <LoginWrapper>
                  <button
                    className={`button button-primary button-lg row mt-44 `}
                    onClick={() => goCard()}
                  >
                    加入购物车
                  </button>
                </LoginWrapper>
              )}
              {(chooseSku?.status === 2 || chooseSku?.inventory === 0) && (
                <button className={`button disabled button-lg row mt-8`}>
                  {chooseSku?.status === 2 ? '该商品已下架' : '该商品已售罄'}
                </button>
              )}
            </div>
          </div>
        </div>
        {/* 内搭列表 */}
        <div className="good-detail flex flex-center flex-col pt-40">
          <div className="package-inside px-28 py-36">
            <div className="t6 mb-20">已选伴手礼盒</div>
            <div className="package-outer-arr">
              {packageInfo.outer_arr &&
                packageInfo.outer_arr.map((item, index) => (
                  <div className="" key={index}>
                    <div
                      className={[
                        'package-inside-item flex',
                        index === 0 ? 'mt-32' : 'mt-32',
                      ].join(' ')}
                    >
                      <div className="flex">
                        <img
                          className="package-inside-item-img mr-20"
                          src={item.img}
                        ></img>
                        <div
                          className={[
                            'flex flex-col package-inside-item-con active',
                          ].join(' ')}
                        >
                          <span className="t6 lh">
                            {item.shop_sku_name.replace(' : ', '-')}
                          </span>
                          <span className="package-inside-item-spec_info">
                            {item.spec_info &&
                              item.spec_info.map((spec_item, spec_index) => {
                                return `${spec_item.value}`
                              })}
                          </span>
                          <span className="t6 span-primary mt-16">
                            ¥{item.showPrice}
                          </span>
                        </div>
                      </div>
                      <div className="package-outer-arr-num">x{item.num}</div>
                    </div>
                  </div>
                ))}
            </div>
            <div className="t6 mb-20">已选内搭</div>
            {packageInfo.inner_arr &&
              packageInfo.inner_arr.map((item, index) => (
                <div className="" key={index}>
                  <div
                    className={[
                      'package-inside-item flex',
                      index === 0 ? 'mt-32' : 'mt-32',
                    ].join(' ')}
                  >
                    <div className="flex" onClick={() => handelToGood(item)}>
                      <img
                        className="package-inside-item-img mr-20"
                        src={item.img}
                      ></img>
                      <div
                        className={[
                          'flex flex-col package-inside-item-con active',
                        ].join(' ')}
                      >
                        <span className="t6 lh ellipsis1">
                          {item.shop_sku_name.replace(' : ', '-')}
                          {item.is_custom ? (
                            <img
                              width={30}
                              className="ml-4"
                              src="https://produce01.xiyin.love/js-view-c-mini-shop/images/dingzhi.png"
                            />
                          ) : null}
                        </span>
                        <span className="package-inside-item-spec_info">
                          {item.spec_info &&
                            item.spec_info.map((spec_item, spec_index) => {
                              return `${spec_item.value}`
                            })}
                        </span>
                        <span className="t6 span-primary mt-16">
                          {item.showPrice}
                        </span>
                      </div>
                    </div>
                    {item.price_list && item.price_list.length > 0 && item.price_list_status ? (
                      <GoodsNumsPopover
                        interval_price={item?.price_list}
                        onChangeNum={(n: number) => onChangeNum(item, n)}
                        number={item.num}
                        minBuyNum={item.min_buy_num as number || 1}
                        spu_id={Number(id) || 0}
                      >
                        <div className="select flex flex-y-center flex-between round-xs px-16 ">
                          <span className="t4">
                            {item.num ? item.num : '选择数量'}
                          </span>
                          <span className="iconfont icon-arrow t4 text-gray"></span>
                        </div>
                      </GoodsNumsPopover>
                    ) : (
                      <div className="flex package-inside-item-btm speci-sku-num-box">
                        <div
                          className="speci-sku-btn "
                          onClick={() => onChangeNum(item, item.num - 1)}
                        >
                          <MinusOutlined
                            className={[
                              'iconfont t6 span-dark',
                              num === 1 ? 'icon-disable' : '',
                            ].join(' ')}
                          />
                        </div>
                        <input
                          className="speci-sku-div"
                          maxLength={4}
                          value={item.num as any}
                          onChange={(e) => {
                            onChangeNum(item, parseInt(e.target.value) || 1)
                          }}
                          onBlur={(e) => {
                            onChangeNum(item, parseInt(e.target.value) || 1, true)
                          }}
                        ></input>
                        <div
                          className="speci-sku-btn"
                          onClick={() => onChangeNum(item, item.num + 1)}
                        >
                          <PlusOutlined className="iconfont t6 span-dark" />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            {/* 增加内搭 */}
            <div
              className="button is-plain-primary row mt-40 package-inside-item-inside addbtn"
              onClick={() => {
                setIsCollocationPoupu(true)
                disableScroll()
              }}
            >
              <span className="mr-8">+</span>增加内搭
            </div>
          </div>
        </div>
        {/* 详情图 */}
        <div className="good-detail flex flex-center flex-col pt-20">
          <div className="good-detail-main round-sm px-46 py-40">
            <div className="t6 mb-20">商品详情</div>
            {spu_detail.map((item, index) => (
              <img src={item} alt="" key={index} />
            ))}
          </div>
        </div>
      </InfiniteScroll>
      {/* 加入组合弹窗 */}
      <GroupPopup
        visible={showGroupVidible}
        confirm={(sale_cart_ids) => handelAddCart(sale_cart_ids)}
        onClose={() => setShowGroupVidible(false)}
        list={cartList}
      ></GroupPopup>
      {/* 内搭弹框 */}
      <CollocationPopup
        visible={isCollocationPoupu}
        onClose={() => {
          setIsCollocationPoupu(false)
          enableScroll()
        }}
        category_list={category_list}
        onConfirm={onCollocationConfirm}
        selectSkuBox={
          packageInfo.outer_arr
            ? packageInfo.outer_arr[0]
            : ({} as Store.Package.PackageSkuItem)
        }
        selectSkus={
          packageInfo.inner_arr
            ? packageInfo.inner_arr
            : ([] as Store.Package.PackageSkuItem[])
        }
      ></CollocationPopup>
    </div>
  )
}

export default Good
