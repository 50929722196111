/*
 * @Author: axu 1342248325@qq.com
 * @Date: 2022-07-08 10:39:07
 * @LastEditors: axu 1342248325@qq.com
 * @LastEditTime: 2022-07-16 14:13:16
 * @FilePath: /js-view-newbox-h5/src/apis/goods.ts
 * @Description: 订单相关
 */
import request from '@/utils/request'

interface OrderList {
  list: Store.Order.OrderItem[]
  last_page: number
  total: number
}
/**
 * 获取订单信息
 */
export const getOrderInfo = (params: any) =>
  request({
    method: 'GET',
    url: '/api/ToC/V1/SaleOrder/info',
    params,
  })

/**
 * 获取订单列表
 */
export const getOrderList = (params?: any) =>
  request<Store.Order.OrderItem[]>({
    method: 'GET',
    url: '/api/ToC/V1/SaleOrder/list',
    params,
  })
