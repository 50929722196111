/*
 * @Author: axu 1342248325@qq.com
 * @Date: 2022-07-08 10:39:07
 * @LastEditors: zhao_juchang 2233737325@qq.com
 * @LastEditTime: 2022-08-30 14:09:41
 * @FilePath: /js-view-newbox-h5/src/apis/goods.ts
 * @Description: 商品相关
 */
import request from '@/utils/request'

interface GoodsList {
  total: number
  last_page: number
  list: Store.Goods.GoodsListItem[]
}

/**
 * 获取所有标签
 */
export const getTagTreeList = (params: any) =>
  request<Store.Category.CategoryItem[]>({
    method: 'GET',
    url: '/api/ToC/V1/Spu/tagTreeList',
    params,
  })

/**
 * 获取商品列表
 */
export const getGoodsList = (params: any) =>
  request<GoodsList>({
    method: 'GET',
    url: '/api/ToC/V1/Spu/spuList',
    params,
  })

/**
 * 获取标签模版列表
 */
export const getTemplateList = (params: any) =>
  request<GoodsList>({
    method: 'GET',
    url: '/api/ToC/V1/Spu/getTemplateList',
    params,
  })

/**
 * 获取商品详情
 */
export const getGoodsInfo = (params: any) =>
  request<GoodsList>({
    method: 'GET',
    url: '/api/ToC/V1/ShopSpu/detail',
    params,
  })

/**
 * 获取商品模版渲染图
 */
export const getGoodsMarketImg = (params: any) =>
  request<Store.Goods.GoodsMarketImg[]>({
    method: 'GET',
    url: '/api/ToC/V1/Spu/templateImg',
    params,
  })

/**
 * 获取商品尺寸
 */
export const getGoodsSize = (params: any) =>
  request<Store.Goods.GoodsSize[]>({
    method: 'GET',
    url: '/api/ToC/V1/ShopSpu/specInfo',
    params,
  })

/**
 * 获取商品详情图
 */
export const getGoodsDetailImg = (params: any) =>
  request<string[]>({
    method: 'GET',
    url: '/api/ToC/V1/Spu/getSpuContentImg',
    params,
  })

/**
* 获取定制品营销图
* @param id
*/
export const getMarketImageList = (
  shop_spu_id: number | string,
  template_id: number | string
) =>
  request<Store.Product.IProductMarkImage[]>({
    method: 'GET',
    url: '/apiToC/V1/ShopSpu/getMarketImageList',
    params: { shop_spu_id, template_id },
    loading: '加载中...',
  })
/**
 * 切换模版渲染图
 */
export const templateImg = (params: any) =>
  request<string[]>({
    method: 'GET',
    url: '/api/ToC/V1/Spu/templateImg',
    params,
  })

/**
 * 获取关联标签
 */
export const getTemplateTagInfo = (params: any) =>
  request<string[]>({
    method: 'GET',
    url: '/api/ToC/V1/Spu/getTemplateTagInfo',
    params,
  })

/**
 * 获取商品属性列表
 */
export const getProduceDetailAttrs = (shop_spu_id: number | string) =>
  request<Store.Product.IProductAttrs>({
    method: 'GET',
    url: '/api/ToC/V1/ShopSpu/specInfo',
    params: { shop_spu_id },
  })


export const getCategory = () =>
  request<Store.Collocation.ICategoryList[]>({
    method: 'GET',
    url: '/api/ToC/V1/ShopSpu/innerOptions',
  })

