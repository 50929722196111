/*
 * @Author: axu 1342248325@qq.com
 * @Date: 2022-07-04 20:12:26
 * @LastEditors: axu 1342248325@qq.com
 * @LastEditTime: 2022-08-05 16:43:05
 * @FilePath: /my-app/src/store/models/global.ts
 * @Description: 用户信息
 */
import { createModel } from '@rematch/core'
import { getCartNum } from '@/apis/cart'
import { RootModel } from '.'

interface CartStroe {
  cart_num: number
}

export const cart = createModel<RootModel>()({
  state: {
    cart_num: 0,
  } as CartStroe,
  reducers: {
    setCartNum: (state, num: number) => {
      return {
        ...state,
        cart_num: num,
      }
    },
  },
  effects: (dispatch) => ({
    async getCartnum() {
      const res: any = await getCartNum()
      dispatch.cart.setCartNum(Number(res))
    },
  }),
})
