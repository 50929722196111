/*
 * @Author: axu
 * @Date: 2021-07-08 19:19:11
 * @LastEditTime: 2021-07-19 14:21:36
 * @LastEditors: Please set LastEditors
 * @Description: 组合弹框
 * @FilePath: /js-div-c-mini-shop/src/components/GroupPopup/index.tsx
 */
import React, { useEffect, useState } from 'react'
// import { div, img, Button, ScrollView } from '@tarojs/components'
// import ProductPopup from '@/components/PublicPopup'
// import { getPurchaseList } from '@/apis/purchase'
import { Modal } from 'antd'
import './index.scss'

interface IProps {
  /**
   * @name 数据
   */
  list: Store.Purchase.PruchaseItem[]
  /**
   * @name 关闭
   */
  onClose: () => void
  /**
   * @name 显示
   */
  visible: boolean
  /**
   * @name 安全区域
   */
  safeArea?: boolean
  /**
   * @name 按钮事件
   */
  confirm: (data) => void
}

const GroupPopup: React.FC<IProps> = (props: IProps) => {
  const { onClose, visible, safeArea, confirm, list } = props
  const [data, setData] = useState<Store.Purchase.PruchaseItem[]>([])

  useEffect(() => {
    setData(list)
  }, [visible])

  // 切换选中状态
  const changeCheck = (index) => {
    const new_list = [...data]
    new_list[index].checked = !new_list[index].checked
    setData(new_list)
  }

  // 新建组合
  const newAdd = () => {
    confirm([])
  }

  // 加入组合
  const add = () => {
    const lists = [...data]
    const new_list = lists.filter((item) => item.checked)
    const sale_cart_ids = new_list.map((item) => item.id)
    console.log('sale_cart_ids', sale_cart_ids)
    confirm(sale_cart_ids)
  }

  // 组合价格
  const on_final_price = (item) => {
    const final_price =
      item.sale_cart_items.reduce(
        (num, sku_item) =>
          num +
          Number(sku_item.off_shelf !== 1 ? sku_item.price : 0) *
            100 *
            Number(sku_item.num),
        0
      ) / 100
    return final_price
  }

  return (
    <>
      {/* <ProductPopup
        position='bottom'
        maskClosable
        onClose={onClose}
        visible={visible}
        safeArea={safeArea}
        closeIcon
      > */}
      <Modal open={visible} onCancel={onClose} footer={null}>
        <div className="group-popup px-28">
          <div className="group-main">
            <div className="group-main-head t2 pt-28">请选择一个组合加入</div>
            <div className="group-main-scroll mt-48 pb-36">
              <div className="group-main-card">
                {data.map((item, index) => (
                  <div className="card-item px-20 mb-20">
                    <div className="card-item-head t3">{item.title}</div>
                    <div className="card-item-con pt-20 pb-28 flex flex-between">
                      {item.sale_cart_items.length <= 1 ? (
                        <img
                          src={item.sale_cart_items[0].img}
                          className="card-item-con-img"
                        ></img>
                      ) : (
                        <div className="card-item-con-cover flex flex-wrap">
                          <img
                            src={
                              item.sale_cart_items[0] &&
                              item.sale_cart_items[0].img
                            }
                            className="img img1"
                          ></img>
                          <img
                            src={
                              item.sale_cart_items[1] &&
                              item.sale_cart_items[1].img
                            }
                            className="img img2"
                          ></img>
                          <img
                            src={
                              item.sale_cart_items[2] &&
                              item.sale_cart_items[2].img
                            }
                            className="img img3"
                          ></img>
                          <img
                            src={
                              item.sale_cart_items[3] &&
                              item.sale_cart_items[3].img
                            }
                            className="img img4"
                          ></img>
                        </div>
                      )}
                      <div className="card-item-con-title flex flex-col flex-between  ml-16">
                        <div className="t5 card-item-con-text">
                          {item.sale_cart_items[0].title}
                          {item.sale_cart_items.length > 1 ? '等多件商品' : ''}
                          <div className="card-item-con-label">
                            {item.sale_cart_items[0].spec}
                          </div>
                        </div>
                        <div className="t3 card-item-con-text text-red">
                          ¥{on_final_price(item)}
                        </div>
                      </div>
                      <div
                        className="flex flex-y-center"
                        onClick={() => changeCheck(index)}
                      >
                        <div
                          className={`iconfont icon-icon_select mr-8 ${
                            item.checked ? 'checked' : ''
                          }`}
                        ></div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="group-main-btm flex px-36">
              <div
                className="button is-plain-primary text-dark"
                onClick={newAdd}
              >
                新建组合并加入
              </div>
              <div className="button text-dark button-primary" onClick={add}>
                加入
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* </ProductPopup> */}
    </>
  )
}
export default GroupPopup
