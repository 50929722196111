/*
 * @Author: axu 1342248325@qq.com
 * @Date: 2022-07-06 17:33:04
 * @LastEditors: zhao_juchang 2233737325@qq.com
 * @LastEditTime: 2022-09-08 09:58:26
 * @FilePath: /js-view-newbox-h5/src/components/Vipmark/index.tsx
 * @Description: 会员角标
 */
import React, { memo, useRef } from 'react'
import { useAppSelector } from '@/store/store'
import './index.scss'

interface ComponentProps {
  radius?: string
  classStyle?: string
}
const Vipmark: React.FC<ComponentProps> = (props: ComponentProps) => {
  const { radius, classStyle = '' } = props
  const webInfo = useAppSelector((state) => state.app.webInfo)

  return (
    <>
      {!webInfo.id && (
        <div
          className={`vipmark ${classStyle}`}
          style={{ borderRadius: radius }}
        >
          <img src={require('../../static/icon/lock.png')} />
        </div>
      )}
    </>
  )
}

export default memo(Vipmark)
