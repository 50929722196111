/*
 * @Author: axu 1342248325@qq.com
 * @Date: 2022-07-11 14:43:19
 * @LastEditors: axu 1342248325@qq.com
 * @LastEditTime: 2022-08-05 19:06:41
 * @FilePath: /js-view-newbox-h5/src/pages/order_detail/index.tsx
 * @Description: 订单详情
 */
import React, { useEffect, useState } from 'react'
import Header from '@/components/Header'
import OrderCard from '@/components/OrderCard'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useAppSelector, store } from '@/store/store'
import AddressCard from '@/components/AddressCard'
import PayCart from '@/components/PayCart'
import ProductInformation from '@/pages/order_detail/ProductInformation'
import { getOrderInfo } from '@/apis/order'
import { useRouter } from '@/hooks'
import './index.scss'

const OrderDetail: React.FC = () => {
  const { routerParams } = useRouter()
  const { id } = routerParams
  const [orderInfo, setOrderInfo] = useState<any>({})
  const shop = useAppSelector((state) => state.user.shop)

  useEffect(() => {
    fetchOrderInfo()
  }, [id])

  const fetchOrderInfo = () => {
    getOrderInfo({ id }).then((res) => {
      setOrderInfo(res)
    })
  }
  return (
    <div className="order-detail pt-72 ">
      <Header />
      <InfiniteScroll
        className="InfiniteScroll flex flex-col flex-y-center"
        dataLength={0}
        next={() => {}}
        hasMore={false}
        pullDownToRefresh={false}
        loader={''}
        endMessage={''}
      >
        <div className="detail mt-60 flex flex-y-start mb-60">
          <div className="detail-left">
            <div className="card-title flex flex-y-center flex-between pb-16">
              <div>
                <span className="t5 mr-30">
                  创建时间：{orderInfo.create_time}
                </span>
                {!shop.config?.customer?.hideExpectedDeliveryTime && (
                  <span className="t5 mr-30">
                    期望发货时间：{orderInfo.expected_delivery_at}
                  </span>
                )}
                <span className="t5">订单编号：{orderInfo.sale_order_sn}</span>
              </div>
              {/* @ts-ignore */}
              <span className={`t5 order-status`}>{orderInfo.status_str}</span>
            </div>
            <AddressCard source="order_detail" addressInfo={orderInfo} />
            <div className="detail-left-goods round-sm pt-4 pb-4 px-32  mt-20">
              {/* 商品信息 */}
              <ProductInformation orderInfo={orderInfo}></ProductInformation>
              <div className="footer pt-28">
                <span className="t-16">运费</span>
                <span>
                  <span className="red t-16 ml-6">¥</span>
                  <span className="red t-22">{orderInfo.freight_price}</span>
                </span>
              </div>
              <div className="footer pb-32">
                <span className="t-18 blod">总计</span>
                <span>
                  <span className="red t-18 ml-6">¥</span>
                  <span className="red t-24">{orderInfo.pay_price}</span>
                </span>
              </div>
            </div>
          </div>
          <PayCart
            source="order_detail"
            // goods={goods}
            orderInfo={orderInfo}
            onPayCall={() => fetchOrderInfo()}
          />
        </div>
      </InfiniteScroll>
    </div>
  )
}

export default OrderDetail
