/*
 * @Author: axu 1342248325@qq.com
 * @Date: 2022-07-06 14:10:41
 * @LastEditors: zhao_juchang 2233737325@qq.com
 * @LastEditTime: 2022-09-09 16:15:33
 * @FilePath: /js-view-newbox-h5/src/components/HeaderSearch/index.tsx
 * @Description: 头部搜索框组件
 */
import React, { useEffect, useState } from 'react'
import { Select, Empty, Input } from 'antd'
import { getGoodsList } from '@/apis/goods'
import { useDebounce } from '@/hooks'
import { navigate_blank } from '@/utils/common'
import { useRouter } from '@/hooks'
import { useAppSelector } from '@/store/store'
import { store } from '@/store/store'
import logger from '@/utils/logger'
import './index.scss'

interface ComponentProps {
  placeholder?: string
  confirm?: (value?: string) => void
}
const HeaderSearch: React.FC<ComponentProps> = (props: ComponentProps) => {
  const { navigateTo, searchParams } = useRouter()
  const { Option } = Select
  const { placeholder = '搜索商品', confirm } = props
  const [chooseItem, setChooseItem] = useState<Store.Goods.GoodsListItem>(
    {} as Store.Goods.GoodsListItem
  )
  const userInfo = useAppSelector((state) => state.user.userInfo)
  const searchInput = useAppSelector((state) => state.global.searchInput)
  const [goods, setGoods] = useState<Store.Goods.GoodsListItem[]>([])
  const [page, setPage] = useState(0)
  const [last_page, setLast_Page] = useState(1)
  const [keyword, setKeyword] = useState('')
  const dispatch = store.dispatch

  useEffect(() => {
    if (searchParams.get('searchInput')) {
      dispatch.global.setSearchInput({
        searchInput: searchParams.get('searchInput'),
      })
    }
  }, [searchParams.get('searchInput')])

  const handleChange = (key: number, options: any) => {
    if (options?.item) {
      let url =
        options.item.can_custom === 1
          ? `/goods/${key}/${options.item.templates[0].template_id || ''}`
          : `/goods/${key}`
      navigate_blank(url)
      setChooseItem(options.item)
    } else {
      setChooseItem({} as Store.Goods.GoodsListItem)
      setGoods([])
    }
  }

  const handleSearch = useDebounce((params: any) => {
    if (!params.key) {
      return
    }
    if (page > last_page) {
      return
    }
    getGoodsList({
      page: params.page,
      page_size: 10,
      keyword: params.key,
    })
      .then((res) => {
        setGoods(params.page === 1 ? [...res.list] : [...goods, ...res.list])
        setPage(params.page)
        setLast_Page(res.last_page)
        setKeyword(params.key)
      })
      .catch(() => {})
  }, 500)

  const handelPopupScroll = (e: any) => {
    e.persist()
    const { target } = e
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      if (page + 1 > last_page) {
        return
      }
      getGoodsList({
        page: page + 1,
        page_size: 10,
        keyword: keyword,
      }).then((res) => {
        setGoods([...goods, ...res.list])
        setPage(page + 1)
      })
    }
  }

  const changeInput = (e: any) => {
    const { value } = e.target
    dispatch.global.setSearchInput({ searchInput: value })
  }

  return (
    <div className={`box-search flex flex-center `}>
      <span className="iconfont icon-search t4 search-icon"></span>
      <Input
        placeholder="搜索商品"
        value={searchInput}
        className="search-input flex flex-center t4"
        onChange={(e) => changeInput(e)}
        onPressEnter={(e) => {
          navigateTo(`/classify?searchInput=${searchInput}`)
          if (confirm) {
            confirm(searchInput)
          }
        }}
      />
    </div>
  )
}

export default HeaderSearch
