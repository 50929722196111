/*
 * @Author: axu 1342248325@qq.com
 * @Date: 2022-07-07 11:08:51
 * @LastEditors: zhao_juchang 2233737325@qq.com
 * @LastEditTime: 2022-09-06 14:31:44
 * @FilePath: /js-view-newbox-h5/src/pages/cart/index.tsx
 * @Description: 购物车
 */
import React, { useEffect, useState } from 'react'
import Header from '@/components/Header'
import { Empty, Modal, message } from 'antd'
import InfiniteScroll from 'react-infinite-scroll-component'
import AddressCard from '@/components/AddressCard'
import OrderCard from '@/components/OrderCard'
import PayCart from '@/components/PayCart'
import GroupNum from '@/components/GroupNum'
import Toast from '@/utils/toast'
import { useRouter } from '@/hooks'
import {
  getCartList,
  delCartList,
  editNum,
  changeNum,
  deleteItem,
  deleteGroup,
} from '@/apis/cart'
import { useAppSelector, useAppDispatch } from '@/store/store'
import logger from '@/utils/logger'
import './index.scss'

const Cart: React.FC = () => {
  const { navigateTo } = useRouter()
  const [goods, setGoods] = useState<Store.Cart.CartItem[]>([])
  const [chooseGoods, setChooseGoode] = useState<any>({})
  const [isAllChoose, setIsAllChoose] = useState(false)
  const addressInfo = useAppSelector((state) => state.address.addressInfo)
  const userInfo = useAppSelector((state) => state.user.userInfo)
  const dispatch = useAppDispatch()
  const [status, setStatus] = useState(0) //0结算状态  1编辑状态
  const [result, setResult] = useState<Store.Purchase.PruchaseItem[]>([])
  const { confirm } = Modal

  useEffect(() => {
    logger('购物车', '查看购物车', userInfo.id ? String(userInfo.id) : '0')
    dispatch.address.getAddressList()
    dispatch.address.fetchRegionOptions()
    fetchCartList()
  }, [])

  // 获取购物车列表
  const fetchCartList = () => {
    getCartList().then((res) => {
      res = res.map((item) => {
        item.sale_cart_items = item.sale_cart_items.map((sku_item) => {
          if (chooseGoods[sku_item.id]) {
            sku_item.checked = true
          }
          return sku_item
        })
        return item
      })

      console.log();
      
      // chooseGoods 过滤掉已经删除的商品
      let _chooseGoods: any = { ...chooseGoods }
      for (const key in _chooseGoods) {
        let flag = false
        res.forEach((group) => {
          group.sale_cart_items.forEach((sku_item) => {
            if (sku_item.id === _chooseGoods[key].id) {
              flag = true
            }
          })
        })
        if (!flag) {
          delete _chooseGoods[key]
        }
      }
      setChooseGoode(_chooseGoods)

      res = resolveShowPrice(res, _chooseGoods)

      setGoods(res)
      let new_result = getResult(res)

      setResult(new_result)
    })
  }

  // 处理显示价格
  const resolveShowPrice = (list: any, _chooseGoods?: any) => {
    console.log('_chooseGoods', _chooseGoods)
    let chooseGoodsNum = {}
    for (const key in _chooseGoods) {
      // 计算选中的商品数量，n个组合中相同单品数量，包括套餐数量乘机以后的数量
      let chooseSkuInfo = _chooseGoods[key]
      chooseGoodsNum[chooseSkuInfo.shop_sku_id] =
        chooseGoodsNum[chooseSkuInfo.shop_sku_id] +
          chooseSkuInfo.num * chooseSkuInfo.groupNum || chooseSkuInfo.num * chooseSkuInfo.groupNum
    }
    console.log('chooseGoodsNum', chooseGoodsNum)

    list.forEach((item) => {
      item.sale_cart_items.forEach((sku_item) => {
        // 设置最小起订量和阶梯价格的最小数量不同处理
        if (sku_item?.price_list_status && sku_item.price_list && sku_item.price_list.length) {
          sku_item.min_buy_num = Math.max(
            Number(sku_item.min_buy_num),
            Number(sku_item.price_list[0].min_num)
          )
          sku_item.num = Math.max(sku_item.min_buy_num, sku_item.num)
        }
        if (sku_item.price_list && sku_item.price_list.length > 0 && sku_item.price_list_status) {
          let curIndexPrice: any = undefined
          sku_item.price_list.forEach((price_item) => {
            // 如果存在选中的商品，就用选中的商品数量去判断
            if (chooseGoodsNum[sku_item.shop_sku_id]) {
              if (
                chooseGoodsNum[sku_item.shop_sku_id] >= price_item.min_num &&
                chooseGoodsNum[sku_item.shop_sku_id] <= price_item.max_num
              ) {
                curIndexPrice = price_item
              }
            } else {
              if (
                sku_item.num >= price_item.min_num &&
                sku_item.num <= price_item.max_num
              ) {
                curIndexPrice = price_item
              }
            }
          })
          sku_item.showPrice = `¥${curIndexPrice?.sale_price_format} (${curIndexPrice?.min_num}-${curIndexPrice?.max_num}件)`
        } else {
          sku_item.showPrice = `¥${sku_item.sale_price || sku_item.price}`
        }
      })
    })
    return list
  }

  // 获取选中的数据，注意区分编辑状态/结算状态
  const getResult = (list1) => {
    const list: Store.Purchase.PruchaseItem[] = []
    const card_list = JSON.parse(JSON.stringify(list1))
    card_list.map((item) => {
      const index = item.sale_cart_items.findIndex(
        (sku_item) =>
          (sku_item.checked && status === 0 && sku_item.status === 1) ||
          (status === 1 && sku_item.checked)
      )
      if (index !== -1) {
        const skuList: Array<any> = []
        item.sale_cart_items.map((sku_item) => {
          if (
            (sku_item.checked && status === 0 && sku_item.status === 1) ||
            (status === 1 && sku_item.checked)
          ) {
            skuList.push(sku_item)
          }
        })

        item.sale_cart_items = skuList
        list.push(item)
      }
    })
    return list
  }

  // 更改商品数量
  const handelChangeNum = async (
    num: number,
    groupIndex: number,
    index: number
  ) => {
    let _goods: any = [...goods]

    if (num < _goods[groupIndex].sale_cart_items[index].min_buy_num) {
      confirm({
        content: '数量小于最小购买数量，确定删除该商品吗？',
        okText: '确定',
        cancelText: '取消',
        onOk() {
          deleteItem({
            sale_cart_item_ids: [_goods[groupIndex].sale_cart_items[index].id],
          }).then((res) => {
            message.success('删除成功')
            fetchCartList()
            dispatch.cart.getCartnum()
          })
        },
        onCancel() {
          console.log('Cancel')
        },
      })
      return
    }
    const price = await editNum({
      sale_cart_item_id: _goods[groupIndex].sale_cart_items[index].id,
      num: num,
    })
    _goods[groupIndex].sale_cart_items[index].num = num
    _goods = resolveShowPrice(_goods, chooseGoods)
    console.log('_goods11', _goods)

    setGoods(_goods)
    const new_result = getResult(_goods)
    setResult(new_result)
  }

  // 更改套餐数量
  const handelChangeGroupNum = async (num: number, groupIndex: number) => {
    let _goods: any = [...goods]
    if (num <= 0) {
      confirm({
        content: '确定删除该组合吗？',
        okText: '确定',
        cancelText: '取消',
        onOk() {
          deleteGroup({
            ids: [_goods[groupIndex].id],
          }).then((res) => {
            message.success('删除成功')
            fetchCartList()
            dispatch.cart.getCartnum()
          })
        },
        onCancel() {
          console.log('Cancel')
        },
      })
      return
    }
    const price = await changeNum({
      id: _goods[groupIndex].id,
      num: num,
    })
    _goods[groupIndex].num = num
    // 修改chooseGoods中的groupNum
    let _chooseGoods: any = { ...chooseGoods }
    console.log('4444', _chooseGoods);
    for (const key in _chooseGoods) {
      if (_chooseGoods[key].groupId === _goods[groupIndex].id) {
        _chooseGoods[key].groupNum = num
      }
    }
    _goods = resolveShowPrice(_goods, _chooseGoods)
    console.log('_goods11', _goods)
    setGoods(_goods)
    const new_result = getResult(_goods)
    setResult(new_result)
  }

  // 选中商品
  const handelChooseGoods = (
    group: any,
    groupIndex: number,
    item: Store.Cart.CartItem,
    index
  ) => {
    let list: any = [...goods]
    // 非会员拦截选中
    // if (item.is_member > 0 && ![1, 2, 3].includes(userInfo.member_level)) {
    //   Toast.warning('请开通会员后再选中会员商品')
    //   return
    // }
    console.log('group', group)

    const _chooseGoods: any = { ...chooseGoods }
    if (_chooseGoods[item.id]) {
      delete _chooseGoods[item.id]
      list[groupIndex].sale_cart_items[index].checked = false
    } else {
      _chooseGoods[item.id] = item
      list[groupIndex].sale_cart_items[index].checked = true
    }
    list[groupIndex].sale_cart_items[index].groupNum = group.num
    list[groupIndex].sale_cart_items[index].groupId = group.id
    // if (Object.keys(_chooseGoods).length === goods.length) {
    //   setIsAllChoose(true)
    // } else {
    //   setIsAllChoose(false)
    // }
    // 判断组合是否全选
    const i = list.findIndex((groupItem: any) => groupItem.id === group.id)
    const j = list[i].sale_cart_items.findIndex((sku_item) => {
      return !_chooseGoods[sku_item.id]
    })
    if (j === -1) {
      list[i].checked = true
    } else {
      list[i].checked = false
    }

    setChooseGoode(_chooseGoods)
    list = resolveShowPrice(list, _chooseGoods)
    console.log('_goods11', list)
    setGoods(list)
    const new_result = getResult(list)
    setResult(new_result)
  }

  // 全选
  const handelAllChoose = (group: any, groupIndex: number) => {
    const _chooseGoods: any = { ...chooseGoods }
    let list: any = [...goods]
    // if (status === 0) {
    const i = list[groupIndex].sale_cart_items.findIndex(
      (sku_item) => sku_item.status === 1
    )
    if (i === -1) {
      return
    }
    // }
    list[groupIndex].checked = !list[groupIndex].checked
    list[groupIndex].sale_cart_items.map((sku_item) => {
      if (list[groupIndex].checked) {
        _chooseGoods[sku_item.id] = sku_item
      } else {
        delete _chooseGoods[sku_item.id]
      }
      sku_item.checked = list[groupIndex].checked
      sku_item.groupNum = group.num
      sku_item.groupId = group.id
    })
    setChooseGoode(_chooseGoods)
    list = resolveShowPrice(list, _chooseGoods)
    console.log('_goods11', list)
    setGoods(list)
    const new_result = getResult(list)
    setResult(new_result)
  }

  // 点击右上角的删除商品
  const handelRemove = (group: any, groupIndex: number) => {
    let sale_cart_item_ids: any = []
    group.sale_cart_items.forEach((item: any) => {
      if (chooseGoods[item.id]) {
        sale_cart_item_ids.push(item.id)
      }
    })
    if (!sale_cart_item_ids.length) {
      message.warning('请选择要删除的商品')
      return
    }
    deleteItem({ sale_cart_item_ids: sale_cart_item_ids }).then((res) => {
      Toast.success('删除成功')
      fetchCartList()
      dispatch.cart.getCartnum()
    })
  }

  return (
    <div className="cart pt-72">
      <Header />
      {goods.length > 0 && (
        <InfiniteScroll
          className="InfiniteScrollCart flex flex-col flex-y-center"
          dataLength={0}
          next={() => {}}
          hasMore={false}
          pullDownToRefresh={false}
          loader={''}
          endMessage={''}
        >
          <div className="cart-main mt-60 mb-60 flex flex-y-start">
            <div className="main-left">
              {/* 地址卡片 */}
              <AddressCard source="cart" addressInfo={addressInfo} />

              {/* 商品 */}
              <div>
                {goods.map((group: any, groupIndex: number) => (
                  <div
                    className="main-left-goods round-sm pt-4 pb-4 px-32  mt-16"
                    key={groupIndex}
                  >
                    <div className="flex flex-between py-20 borderBtm">
                      <div
                        className="flex flex-y-center"
                        onClick={() => handelAllChoose(group, groupIndex)}
                      >
                        <span
                          className={`iconfont icon-icon_select t1 ml-8 ${
                            group.checked ? 'text-icon-light' : 'text-icon-gray'
                          }`}
                        ></span>
                        <span className="t3 ml-14">{group.title}</span>
                      </div>
                      <span
                        className="t3 text-gray"
                        onClick={() => handelRemove(group, groupIndex)}
                      >
                        删除
                      </span>
                    </div>
                    {group.sale_cart_items.map((item, index) => (
                      <div className="flex flex-y-center" key={item.id}>
                        <span
                          className={`iconfont icon-icon_select t1 ml-8  ${
                            chooseGoods[item.id]
                              ? 'text-icon-light'
                              : 'text-icon-gray'
                          }`}
                          onClick={() =>
                            handelChooseGoods(group, groupIndex, item, index)
                          }
                        ></span>
                        <div className="main-left-goods-flex ml-24">
                          <OrderCard
                            size="lg"
                            className="py-28"
                            onChangeNum={(n) =>
                              handelChangeNum(n, groupIndex, index)
                            }
                            info={item}
                            source="cart"
                          />
                        </div>
                      </div>
                    ))}
                    <div className="borderBtm"></div>
                    {/* 套餐数量 */}
                    <GroupNum
                      className="py-20"
                      onChangeNum={(n) => handelChangeGroupNum(n, groupIndex)}
                      info={group}
                    ></GroupNum>
                  </div>
                ))}
              </div>
            </div>

            {/* 结算信息 */}
            <PayCart
              source="cart"
              chooseGoods={Object.values(chooseGoods)}
              result={result}
              goods={goods}
              onAddCall={() => fetchCartList()}
            />
          </div>
        </InfiniteScroll>
      )}
      {goods.length === 0 && (
        <div
          className="flex flex-center"
          style={{ height: window.innerHeight - 72 + 'px' }}
        >
          <Empty description={'暂无内容'} image={Empty.PRESENTED_IMAGE_DEFAULT}>
            <button
              className="button button-primary button-lg cart-empty mt-20"
              onClick={() => navigateTo('/')}
            >
              去选购
            </button>
          </Empty>
        </div>
      )}
    </div>
  )
}

export default Cart
