/*
 * @Author: axu 1342248325@qq.com
 * @Date: 2022-07-06 19:16:00
 * @LastEditors: zhao_juchang 2233737325@qq.com
 * @LastEditTime: 2022-09-06 20:02:55
 * @FilePath: /js-view-newbox-h5/src/components/LoginWrapper/index.tsx
 * @Description: 全局登录组件
 */
import React, { useState, useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { Modal, Input, Button, Statistic } from 'antd'
import { useAppSelector } from '@/store/store'
import Toast from '@/utils/toast'
import { getCode } from '@/apis/user'
import { store } from '@/store/store'
import { disableScroll, enableScroll, getUrlShopId } from '@/utils/common'
import logger from '@/utils/logger'
// import { useRouter } from '@/hooks'
import './index.scss'

interface ComponentProps {
  children?: React.ReactNode
  redirect_uri?: string
}

interface ComponentLoginProps {
  visible: boolean
  onCancel?: () => void
  redirect_uri?: string
  status?: Status
}

type Status = 'login' | 'bindMobile'

export const Login: React.FC<ComponentLoginProps> = (
  props: ComponentLoginProps
) => {
  const { visible, onCancel, redirect_uri, status = 'login' } = props
  const { Countdown } = Statistic
  const [isShow, setIsShow] = useState(false)
  const [loginId, setLoginId] = useState('')
  const [type, setType] = useState(1)
  const [mobile, setMobile] = useState('')
  const [code, setCode] = useState('')
  const [deadline, setDeadline] = useState<number>(0)
  const webInfo = useAppSelector((state) => state.app.webInfo)
  const dispatch = store.dispatch
  const shop = useAppSelector((state) => state.user.shop)
  // const { navigateTo, navigateReplace } = useRouter()

  // 初始化
  useEffect(() => {
    setIsShow(visible)
    setType(1)
    if (visible) {
      disableScroll()
      const base64 =
        'LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDEwMCU7Ym9yZGVyOiBub25lOyBtYXJnaW4tdG9wOiAwO2JveC1zaXppbmc6IGJvcmRlci1ib3g7fQ0KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30NCi5pbXBvd2VyQm94IC5pbmZvIHtkaXNwbGF5OiBub25lO30='
        if (redirect_uri) {
          window.localStorage.setItem('login_redirect_uri', redirect_uri)
        }
      setTimeout(() => {
        // @ts-ignore
        // new WxLogin({
        //   self_redirect: false,
        //   id: 'login_container',
        //   appid: 'wxfce7d397b9a0724b',
        //   scope: 'snsapi_login,snsapi_userinfo,snsapi_base',
        //   redirect_uri: encodeURIComponent(`${window.location.href}`),
        //   state: '',
        //   href: `data:text/css;base64,${base64}`,
        // })
      }, 30)
    } else {
      enableScroll()
    }
  }, [visible])
  useEffect(() => {
    if (!isShow) {
      store.dispatch.global.changeLoginStatus(false)
    }
  }, [isShow])

  // 取消登录
  const handleCancel = () => {
    handelClose()
    dispatch.global.changeLoginStatus(false)
  }

  // 用户id登录：仅开发环境使用
  const handelIdLogin = () => {
    if (!loginId) {
      return
    }
    dispatch.user.useIdLogin(loginId)
    handelClose()
  }

  // 切换登录类型
  const handelChangeLoginType = (type: number) => {
    setType(type)
  }

  // 手机号验证码登录
  const handelMobileSubmit = () => {
    if (!mobile) {
      Toast.warning('请输入手机号')
      return
    }
    if (!/^1[3456789]\d{9}$/.test(mobile)) {
      Toast.warning('请填写正确手机号')
      return
    }
    if (!code) {
      Toast.warning('请输入验证码')
      return
    }
    if (status === 'login') {
      dispatch.user.mobileLogin({ mobile, code }).then((res) => {
        handelClose()
        const login_redirect_uri = window.localStorage.getItem('login_redirect_uri')
        if (login_redirect_uri) {
          // navigateTo(redirect_uri)
          window.location.href = login_redirect_uri
        }
      })
    } else {
      dispatch.user.bindMobile({ mobile, code }).then((res) => {
        handelClose()
      })
    }
  }

  // 获取验证码
  const handelFetchCode = async () => {
    if (!mobile) {
      Toast.warning('请输入手机号')
      return
    }
    if (!/^1[3456789]\d{9}$/.test(mobile)) {
      Toast.warning('请填写正确手机号')
      return
    }
    await getCode({ mobile: mobile })
    setDeadline(new Date().getTime() + 1000 * 60)
  }

  // 倒计时完成
  const handelFinish = () => {
    setDeadline(0)
  }

  // 关闭弹框
  const handelClose = () => {
    setIsShow(false)
    onCancel && onCancel()
    const LoginEl = document.getElementsByClassName('login_wrap_3749u2')[0]
    if (LoginEl) {
      LoginEl.parentNode?.removeChild(LoginEl)
    }
    enableScroll()
  }

  return (
    <Modal
      destroyOnClose
      visible={isShow}
      onCancel={handelClose}
      footer={null}
      width={type === 1 ? 460 : 360}
      bodyStyle={{ padding: '0' }}
      centered
      closeIcon={
        <span className="iconfont icon-yuyinguanbi t2 text-gray"></span>
      }
      zIndex={103}
    >
      <div className="box-loginModal flex">
        {/* 手机号验证码登录 */}
        <div className={`box-mobile ${type === 2 ? 'hide' : ''} px-20`}>
          <span className="t1 bold flex flex-x-center mt-28 ">
            {status === 'login' ? '手机验证码登录' : '绑定手机号'}
          </span>
          <div className="box-mobile-form mt-24 flex flex-y-center">
            <Input
              placeholder="手机号码"
              bordered={false}
              className="t3"
              value={mobile}
              autoComplete="on"
              name="mobile"
              onChange={(e) => setMobile(e.target.value)}
            ></Input>
          </div>
          <div className="box-mobile-form mt-12 flex flex-y-center flex-between">
            <Input
              placeholder="验证码"
              bordered={false}
              className="t3 box-mobile-input"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            ></Input>
            <div className="box-mobile-code t3 text-light flex flex-x-end mr-16">
              {deadline === 0 && (
                <div className="t4 bold pointer" onClick={handelFetchCode}>
                  获取验证码
                </div>
              )}
              {deadline > 0 && (
                <div className="flex flex-y-center">
                  <Countdown
                    value={deadline}
                    onFinish={handelFinish}
                    className="code-cuontdown"
                    format="ss"
                  />
                  <span className="t5 text-gray mt-6">s</span>
                </div>
              )}
            </div>
          </div>
          {shop.config?.customer?.userAgreement ||
          shop.config?.customer?.privacyAgreement ? (
            <div className="box-mobile-agreement">
              请仔细阅读
              <span className="agreement">
                {shop.config?.customer?.userAgreement ? (
                  <a
                    href={shop.config?.customer?.userAgreement}
                    target="_blank"
                  >
                    《用户使用协议》
                  </a>
                ) : null}
                {shop.config?.customer?.userAgreement &&
                shop.config?.customer?.privacyAgreement
                  ? '和'
                  : ''}
                {shop.config?.customer?.privacyAgreement ? (
                  <a
                    href={shop.config?.customer?.privacyAgreement}
                    target="_blank"
                  >
                    《隐私协议》
                  </a>
                ) : null}
              </span>
            </div>
          ) : null}

          <button
            className="button button-primary row t6 mt-50"
            onClick={handelMobileSubmit}
          >
            {status === 'login' ? '登录/注册' : '确定'}
          </button>
          {/* {status === 'login' && !webInfo.id && (
            <div
              onClick={() => handelChangeLoginType(2)}
              className="flex mt-26 flex-center pointer"
            >
              <span className="iconfont icon-weixin t2 text-green"></span>
              <span className="t4 ml-10">微信登录</span>
            </div>
          )} */}
        </div>

        {/* 微信扫码登录 */}
        <div
          className={`box-wechat ${
            type === 1 ? 'hide' : ''
          } flex flex-col flex-y-center`}
        >
          <span className="t1 bold flex flex-center mt-28">微信扫一扫登录</span>
          <div className="wechat-code" id="login_container"></div>
          <div onClick={() => handelChangeLoginType(1)} className="pointer">
            <span className="t4">手机验证码登录</span>
          </div>
          {process.env.REACT_APP_ENV === 'development' && (
            <div className="flex">
              <Input
                value={loginId}
                onChange={(e) => {
                  setLoginId(e.target.value)
                }}
              />
              <Button type="primary" onClick={handelIdLogin}>
                确定
              </Button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}

const LoginWrapper: React.FC<ComponentProps> = (props: ComponentProps) => {
  const { children, redirect_uri } = props
  const [visible, setVisible] = useState(false)
  const userToken = useAppSelector((state) => state.global.userToken)
  const [status, setStatus] = useState<Status>('login')
  const userInfo = useAppSelector((state) => state.user.userInfo)

  const handelVisible = () => {
    if (!userToken) {
      setStatus('login')
      logger('登录', '点击登录')
    } else if (!userInfo.mobile) {
      setStatus('bindMobile')
    }
    setVisible(true)
    disableScroll()
  }

  const handleCancel = () => {
    setVisible(false)
    enableScroll()
  }

  return (
    <div className="box-loginWrapper">
      {children}
      {(!userToken || !userInfo.mobile) && (
        <div
          className="box-loginWrapper-content pointer"
          onClick={handelVisible}
        ></div>
      )}
      <Login
        visible={visible}
        onCancel={handleCancel}
        redirect_uri={redirect_uri}
        status={status}
      />
    </div>
  )
}

// 多种打开方式
export const TriggerLogin = {
  open: (status?: Status, redirect_uri?: string, toHome?: Boolean) => {
    if (toHome) {
      window.location.href = `/?login=true&shop_id=${getUrlShopId()}`
    }
    let div = document.createElement('div')
    div.className = 'login_wrap_3749u2'
    document.body.appendChild(div)
    createRoot(div).render(
      <Provider store={store}>
        <Login visible={true} redirect_uri={redirect_uri} status={status} />
      </Provider>
    )
  },
}

export default LoginWrapper
