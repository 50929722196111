/*
 * @Author: axu 1342248325@qq.com
 * @Date: 2022-07-12 14:27:30
 * @LastEditors: axu 1342248325@qq.com
 * @LastEditTime: 2022-07-15 17:13:41
 * @FilePath: /js-view-newbox-h5/src/components/AddressEdit/index.tsx
 * @Description: 新增/编辑收货地址
 */
import React, { useEffect, useState } from 'react'
import { Modal, Input, Cascader } from 'antd'
import { useAppDispatch, useAppSelector } from '@/store/store'
import Toast from '@/utils/toast'
import './index.scss'

interface ComponentProps {
  visable: boolean
  onClose: () => void
  editAddress?: Store.Address.AddressInfo
}

const AddressEdit: React.FC<ComponentProps> = (props: ComponentProps) => {
  const { visable, onClose, editAddress } = props
  const [address, setAddress] = useState<Store.Address.AddressInfo>({
    province: '',
    city: '',
    area: '',
    detail_address: '',
    username: '',
    mobile: '',
    default: 0,
  })
  const dispatch = useAppDispatch()
  const options = useAppSelector((state) => state.address.regionOptions)

  useEffect(() => {
    if (editAddress?.id) {
      setAddress({ ...editAddress })
    }
  }, [editAddress])

  // 省市区选择
  const handelCascader = (value: any, selectedOptions: any) => {
    setAddress({
      ...address,
      province: selectedOptions[0].label,
      city: selectedOptions[1].label,
      area: selectedOptions[2].label,
    })
  }

  // 提交
  const handelSubmit = () => {
    if (!address.detail_address) {
      Toast.error('请填写详细地址')
      return
    }
    if (editAddress?.id) {
      dispatch.address.editAddressInfo(address)
    } else {
      address.default = 1
      dispatch.address.addAddressInfo(address)
    }
    setAddress({} as Store.Address.AddressInfo)
    onClose()
  }

  // 省市区搜索过滤
  const filter = (inputValue, path) => {
    return path.some(
      (option) =>
        option.label.toLowerCase().indexOf(inputValue.trim().toLowerCase()) > -1
    )
  }

  return (
    <Modal
      visible={visable}
      onCancel={onClose}
      centered={true}
      footer={null}
      // width={550}
      className="modal-address-edit"
      bodyStyle={{ padding: '0' }}
      closeIcon={
        <span className="iconfont icon-yuyinguanbi t2 text-gray mt-20"></span>
      }
      zIndex={102}
    >
      <div className="box-address-edit round-sm flex flex-col flex-y-center pt-48 pb-40 px-40">
        <span className="t1">{editAddress?.id ? '编辑' : '新增'}收货地址</span>
        <div className="mt-24 flex flex-center full">
          <Input
            placeholder="收件人"
            className="box-address-edit-input"
            bordered={false}
            value={address.username}
            onChange={(e) =>
              setAddress({ ...address, username: e.target.value })
            }
          ></Input>
        </div>
        <div className="mt-12 flex flex-center full">
          <Input
            placeholder="手机号码"
            className="box-address-edit-input"
            bordered={false}
            value={address.mobile}
            maxLength={13}
            type="number"
            onChange={(e) =>
              setAddress({ ...address, mobile: e.target.value.slice(0, 13) })
            }
          ></Input>
        </div>
        <div className="mt-12 flex flex-center full">
          <Cascader
            options={options}
            onChange={(value, selectedOptions) =>
              handelCascader(value, selectedOptions)
            }
            placeholder="请选择省市区"
            showSearch={{ filter }}
            bordered={false}
            value={
              address.province
                ? [address.province, address.city, address.area]
                : []
            }
            className="box-address-edit-input flex flex-col flex-x-center"
            dropdownMenuColumnStyle={{ width: '139px' }}
          />
        </div>
        <div className="mt-12 flex flex-center full">
          <Input
            placeholder="详细地址：如街道门牌、楼层房间号等信息"
            className="box-address-edit-input"
            bordered={false}
            value={address.detail_address}
            onChange={(e) =>
              setAddress({ ...address, detail_address: e.target.value })
            }
          ></Input>
        </div>
        <div className="mt-40 full px-30">
          <button
            className="button button-primary row button-lg"
            onClick={handelSubmit}
          >
            确定
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default AddressEdit
