/*
 * @Author: axu 1342248325@qq.com
 * @Date: 2022-07-08 10:39:07
 * @LastEditors: axu 1342248325@qq.com
 * @LastEditTime: 2022-08-04 16:21:09
 * @FilePath: /js-view-newbox-h5/src/apis/goods.ts
 * @Description: 购物车相关
 */
import request from '@/utils/request'

/**
 * 加购
 */
export const addCart = (data: any) =>
  request({
    method: 'POST',
    url: '/api/ToC/V1/SaleCart/add',
    data,
  })

/**
 * 获取购物车列表
 */
export const getCartList = () =>
  request<Store.Cart.CartItem[]>({
    method: 'GET',
    url: '/api/ToC/V1/SaleCart/list',
  })

/**
 * 删除
 */
export const delCartList = (data: any) =>
  request({
    method: 'POST',
    url: '/api/ToC/V1/Cart/delete',
    data,
  })

/**
 * 修改购物车数量
 */
export const editNum = (data: any) =>
  request({
    method: 'POST',
    url: '/api/ToC/V1/SaleCart/changeItemNum',
    data,
  })
/**
 * 修改购物车套餐数量
 */
export const changeNum = (data: any) =>
  request({
    method: 'POST',
    url: '/api/ToC/V1/SaleCart/changeNum',
    data,
  })

/**
 * 获取购物车数量
 */
export const getCartNum = () =>
  request({
    method: 'GET',
    url: '/api/ToC/V1/SaleCart/getHas',
  })

/**
 * 批量加购（套餐）
 */
export const batchAddSku = (data: any) =>
  request({
    method: 'POST',
    url: '/api/ToC/V1/SaleCart/batchAddSku',
    data,
  })

  /**
 * 子购物车删除
 */
export const deleteItem = (data: any) =>
request({
  method: 'POST',
  url: '/api/ToC/V1/SaleCart/deleteItem',
  data,
})
  /**
 * 购物车组合删除
 */
export const deleteGroup = (data: any) =>
request({
  method: 'POST',
  url: '/api/ToC/V1/SaleCart/delete',
  data,
})
