/*
 * @Author: axu 1342248325@qq.com
 * @Date: 2022-07-11 17:17:08
 * @LastEditors: zhao_juchang 2233737325@qq.com
 * @LastEditTime: 2022-09-07 17:46:24
 * @FilePath: /js-view-newbox-h5/src/components/PayCart/index.tsx
 * @Description: 订单详情/购物车 结算卡片
 */
import React, { useEffect, useState } from 'react'
import OrderCollapse from '@/components/OrderCollapse'
import Logistics from '@/components/Logistics'
import { useAppSelector, store } from '@/store/store'
import {
  optionalTime,
  lastLogistics,
  getPayPrice,
  addOrders,
  balancePay,
} from '@/apis/pay'
import DatePicker from '@/components/DatePicker'
import PayCode from '../PayCode'
import Toast from '@/utils/toast'
import { useRouter } from '@/hooks'
import { Input } from 'antd'
import LoginWrapper from '../LoginWrapper'
import { CheckCircleFilled } from '@ant-design/icons'
import './index.scss'

const { TextArea } = Input
interface ComponentProps {
  source: 'order_detail' | 'cart'
  goods?: Store.Cart.CartItem[]
  orderInfo?: any
  onPayCall?: () => void
  result?: any
  chooseGoods?: any
  onAddCall?: () => void
}

interface Logistice {
  type: number
  logistics_company: string
  logistics_address: string
  contact: string
}

interface PayPrice {
  coupon_price: number
  freight_price: number
  is_time_limit_free: number
  logo_custom_num: number
  logo_custom_price: number
  offline_discount_price: number
  pay_price: number
  show_price_type: number
  total_price: number
  show_price: number
}

const PayCart: React.FC<ComponentProps> = (props: ComponentProps) => {
  const {
    source,
    goods,
    chooseGoods,
    orderInfo,
    onPayCall,
    result,
    onAddCall,
  } = props
  const { navigateTo, routerParams, searchParams } = useRouter()
  const webInfo = useAppSelector((state) => state.app.webInfo)
  const [logistice, setLogistice] = useState<Logistice>({} as Logistice)
  const [LogisticsTime, setLogisticsTime] = useState({
    day_of_the_week: ['周一', '周三', '周五'],
    sign: [1, 3, 5],
  })
  const [dateValue, setDateValue] = useState('')
  const [remark, setRemark] = useState('')
  const address = useAppSelector((state) => state.address.addressInfo)
  const [payPrice, setPayPrice] = useState({} as PayPrice)
  const [orderId, setOrderId] = useState('')
  const [payCodeVisiable, setPayCodeVisiable] = useState(false)
  const [can_custom, setCan_custom] = useState(true)
  const userInfo = useAppSelector((state) => state.user.userInfo)
  const [pay_type, set_pay_type] = useState<number | string>(0)
  const shop = useAppSelector((state) => state.user.shop)
  const dispatch = store.dispatch
  useEffect(() => {
    if (source === 'cart') {
      // fetchLogistics()
      // fetchLogisticsHistory()
    }
  }, [])

  useEffect(() => {
    console.log('routerParams', routerParams)
    console.log('orderInfo', orderInfo)

    if (source === 'order_detail' && orderInfo?.id) {
      setPayPrice({
        ...payPrice,
        pay_price: orderInfo.pay_price,
        total_price: orderInfo.total_price,
        freight_price: orderInfo.freight_price,
      })
      setOrderId(orderInfo?.id)
    }
  }, [orderInfo])

  // 获取专线物流的时间
  // const fetchLogistics = () => {
  //   optionalTime().then((res) => {
  //     setLogisticsTime(res)
  //   })
  // }

  //获取专线物流的收货信息
  // const fetchLogisticsHistory = () => {
  //   lastLogistics().then((res) => {
  //     if (res) {
  //       const data = { ...logistice }
  //       data.logistics_address = res.logistics_address
  //       data.logistics_company = res.logistics_company
  //       data.contact = res.contact
  //       setLogistice(data)
  //     }
  //   })
  // }

  // 更改物流
  const handeChangeLogistics = (res: Logistice) => {
    setLogistice(res)
    setDateValue('')
  }

  // 选择发货时间
  const handelChangeDate = (date: string) => {
    setDateValue(date)
  }

  // 结算
  const handelSubmit = () => {
    if (chooseGoods.length === 0) {
      Toast.warning('请选择要购买的商品')
      return
    }
    if (!address.id) {
      Toast.warning('请填写收货地址')
      return
    }
    // if (!logistice.type) {
    //   Toast.warning('请选择发货方式')
    //   return
    // }
    // if (!dateValue) {
    //   Toast.warning('请选择发货日期')
    //   return
    // }
    // if (!pay_type && !webInfo.id) {
    //   Toast.warning('请选择支付方式')
    //   return
    // }
    const hide = Toast.loading('生成订单中')
    const data = handelPayData()
    console.log('data', data)
    // return
    addOrders(data)
      .then((res) => {
        if (res) {
          hide()
          setOrderId(res)
          Toast.success('下单成功')
          // if (webInfo.id) {
          //   navigateTo(`/order_detail/${res}`)
          // }
          onAddCall && onAddCall()
    console.log('orderInfo', orderInfo)
          navigateTo(`/order_detail/${res}?pay_type=${pay_type || ''}`)
        }
      })
      .catch(() => {
        hide()
      })
  }

  useEffect(() => {
    const p_type = searchParams.get('pay_type')
    const type = pay_type || p_type
    console.log('orderId', orderId)
    if (orderId && type) {
      if (!pay_type && p_type) {
        set_pay_type(+p_type)
      }
      // 微信或者支付宝支付 
      if ([1, 2].includes(Number(type))) {
        setPayCodeVisiable(true)
      }
      // 对公转正
      // if (pay_type === -1) {
      //   onBalancePay()
      // }
    }
  }, [orderId, pay_type])
  // 结算数据处理
  const handelPayData = () => {
    const data: any = {
      consignee: address.username,
      province: address.province,
      city: address.city,
      area: address.area,
      detail_address: address.detail_address,
      mobile: address.mobile,
      remark,
      // logistics_type: logistice.type,
      // logistics_company: logistice.logistics_company,
      // logistics_address: logistice.logistics_address,
      // contact: logistice.contact,
      expected_delivery_at: [undefined, null, ''].includes(dateValue)
        ? undefined
        : dateValue,
      type: 1,
    }
    // const cart_ids = goods.map((item) => item.id)
    // const items = goods.map((item) => {
    //   return { sku_id: item.sku_id, num: item.num, cart_id: item.id }
    // })
    const items: Array<any> = []
    console.log('goods', goods)

    if (goods) {
      goods.map((item: any) => {
        const skus: Array<any> = []
        item.sale_cart_items.map((sku_item) => {
          if (sku_item.checked) {
            const obj = {
              shop_sku_id: sku_item.shop_sku_id,
              num: sku_item.num,
              sale_cart_item_id: sku_item.id,
              sale_cart_id: item.id,
            }
            skus.push(obj)
          }
        })
        if (skus.length > 0) {
          const obj = {
            title: item.title,
            num: item.num,
            shop_skus: skus,
          }
          items.push(obj)
        }
      })
    }

    console.log('items', items)

    const arr: any[] = []
    items.map((item: any) => {
      item.shop_skus.map((i: any) => {
        if (i.sale_cart_id) {
          arr.push({
            sale_cart_id: i.sale_cart_id,
            sale_cart_item_id: i.sale_cart_item_id,
          })
        }
      })
    })
    // data.cart_ids = cart_ids
    data.items = items
    data.sale_carts = arr
    return data
  }

  useEffect(() => {
    if (source === 'cart') {
      // 地址，物流类型，时间发生变化时，获取最新价格
      if (chooseGoods.length > 0) {
        fetchOrderPrice()
      } else {
        setPayPrice({} as PayPrice)
      }
    }
    if (chooseGoods) {
      const canCustom = chooseGoods.some((item) => item.can_custom === 1)
      if (canCustom !== can_custom) {
        setDateValue('')
      }
      setCan_custom(canCustom)
    }
  }, [address, logistice, dateValue, chooseGoods])

  // 获取结算金额
  const fetchOrderPrice = () => {
    const data = handelPayData()
    if (data.items.length > 0) {
      getPayPrice(data).then((res) => {
        setPayPrice(res)
      })
    }
  }

  // 取消支付/支付完成
  const handelClosePayCode = () => {
    if (source === 'cart') {
      setPayCodeVisiable(false)
      if (window.location.href.includes(`/order_detail/${orderId}`)) {
        window.location.reload()
      } else {
        navigateTo(`/order_detail/${orderId}`)
      }
    }
    if (source === 'order_detail') {
      setPayCodeVisiable(false)
      onPayCall && onPayCall()
    }
  }

  // 订单详情立即支付
  const handelPay = () => {
    if (!pay_type && !webInfo.id) {
      Toast.warning('请选择支付方式')
      return
    }
    setOrderId(orderInfo.id)
    // orderId = orderInfo.id
    // 微信或者支付宝支付
    if ([1, 2].includes(Number(pay_type))) {
      setPayCodeVisiable(true)
    }
    // 对公转账
    // if (pay_type === -1) {
    //   onBalancePay()
    // }
  }

  // 余额支付
  const onBalancePay = () => {
    balancePay({ order_id: orderId }).then((res) => {
      Toast.success('支付成功')
      dispatch.user.fetchUserInfo()
      if (window.location.href.includes(`/order_detail/${orderId}`)) {
        window.location.reload()
      } else {
        navigateTo(`/order_detail/${orderId}`)
      }
    })
  }
  // 去掉已选商品的vip角标记
  const formatGoods = (chooseGoods: any) => {
    if (chooseGoods) {
      chooseGoods = JSON.parse(JSON.stringify(chooseGoods)).map((item) => {
        item.is_member = 0
        return item
      })
    }
    return chooseGoods
  }

  // 设置支付方式
  const toSetPayType = (type: number) => {
    // if (chooseGoods.length === 0) {
    //   Toast.warning('请选择要购买的商品')
    //   return
    // }
    // if (userInfo.balance < payPrice.pay_price && type === -1) {
    //   Toast.warning('实付价格超过余额，请选择微信或支付宝支付')
    //   return
    // }
    set_pay_type(type)
  }

  // 总价
  const on_final_price = () => {
    const list = result ? JSON.parse(JSON.stringify(result)) : []
    if (list.length <= 0) {
      return 0
    }
    list.map((item) => {
      const total =
        item.sale_cart_items.reduce(
          (num, sku_item) =>
            num + Number(sku_item.price) * 100 * Number(sku_item.num),
          0
        ) / 100
      item.total_price = total * item.num
    })
    const final_price = (
      list.reduce((num, item) => num + Number(item.total_price) * 100, 0) / 100
    ).toFixed(2)
    return final_price
  }

  return (
    <div className="box-pay-card round-sm ml-16 px-24 pt-20">
      {source === 'cart' &&
        !shop.config?.customer?.hideExpectedDeliveryTime && (
          <div className="pt-20">
            <div className="t2 bold">期望发货日期</div>
            <div className="mt-8 mb-24">
              <DatePicker
                onChangeDate={handelChangeDate}
                LogisticsTime={LogisticsTime}
                LogisticsType={logistice.type}
                dateValue={dateValue}
                can_custom={can_custom}
              />
            </div>
          </div>
        )}
      <div className="t2 mb-16">订单金额</div>
      <div className="py-20 borderTop">
        <div className="flex flex-between mb-16">
          <span className="t3 text-gray">商品总额</span>
          <span className="t2 ">
            ¥{payPrice.total_price !== undefined ? payPrice.total_price : '--'}
            {/* ¥{on_final_price() !== undefined ? on_final_price() : '--'} */}
          </span>
        </div>
        <div className="flex flex-between mb-16">
          <span className="t4 text-gray">运费</span>
          <span className="t4 ">
            ¥
            {payPrice.freight_price !== undefined
              ? payPrice.freight_price
              : '--'}
          </span>
        </div>
        <div className="flex flex-between">
          <span className="t4 text-primary">合计</span>
          <span className="t3 bold">
            {[2, 3].includes(payPrice?.show_price_type) ||
            [2, 3].includes(orderInfo?.show_price_type) ? (
              <span>
                ¥
                {payPrice.show_price !== undefined
                  ? payPrice.show_price
                  : orderInfo.show_price}
              </span>
            ) : (
              <span>
                ¥{payPrice.pay_price !== undefined ? payPrice.pay_price : '--'}
              </span>
            )}
          </span>
        </div>
      </div>

      {shop.config?.customer?.corporatePay}
      {/* 选择支付方式 */}
      {((source === 'order_detail' && orderInfo?.status === 1) ||
        source === 'cart') &&
        (shop.config?.customer?.corporatePay ||
          shop.config?.customer?.wxPay || shop.config?.hasZfb) && (
          <div className="py-20 borderTop paybox">
            <div className="t2 bold mb-16">选择支付方式</div>
            {shop.config?.customer?.corporatePay ? (
              <div
                className="flex flex-between mb-16 pointer"
                onClick={() => toSetPayType(-1)}
              >
                <span className="t4 flex flex-y-center">
                  <img
                    src={require('../../static/icon/pursepay.png')}
                    width="19"
                  />
                  <span className="ml-10 vertical-align-sub">对公转账</span>
                  {/* <span className="ml-8 text-gray vertical-align-sub">
                    (¥{userInfo.balance})
                  </span> */}
                </span>
                <span className="t4">
                  <CheckCircleFilled
                    className={`icon-check  ${
                      pay_type === -1 ? 'icon-checked' : ''
                    }`}
                  />
                </span>
              </div>
            ) : null}
            {shop.config?.customer?.wxPay ? (
              <div
                className="flex flex-between mb-16 pointer"
                onClick={() => toSetPayType(1)}
              >
                <span className="t4 flex flex-y-center">
                  <img
                    src={require('../../static/icon/wxpay.png')}
                    width="19"
                  />
                  <span className="ml-10 vertical-align-sub">微信支付</span>
                </span>
                <span className="t4">
                  <CheckCircleFilled
                    className={`icon-check  ${
                      pay_type === 1 ? 'icon-checked' : ''
                    }`}
                  />
                </span>
              </div>
            ) : null}
            {
              shop.config?.hasZfb ? (
                <div
                  className="flex flex-between pointer"
                  onClick={() => toSetPayType(2)}
                >
                  <span className="t4 flex flex-y-center">
                    <img src={require('../../static/icon/alipay.png')} width="19" />
                    <span className="ml-10 vertical-align-sub">支付宝支付</span>
                  </span>
                  <span className="t4">
                    <CheckCircleFilled
                      className={`icon-check  ${
                        pay_type === 2 ? 'icon-checked' : ''
                      }`}
                    />
                  </span>
                </div>
              ) : null
            }
            {pay_type === -1 ? (
              <>
                <div className="t2 bold mt-14 mb-16">对公支付信息</div>
                <div className="corporate-con">
                  <div className="corporate-item flex">
                    <div className="corporate-label">公司名称</div>
                    <div className="corporate-value">
                      {shop.config?.customer?.companyName}
                    </div>
                  </div>
                  <div className="corporate-item flex">
                    <div className="corporate-label">开户行</div>
                    <div className="corporate-value">
                      {shop.config?.customer?.openingBank}
                    </div>
                  </div>
                  <div className="corporate-item flex">
                    <div className="corporate-label">银行卡号</div>
                    <div className="corporate-value">
                      {shop.config?.customer?.bankCardNumber}
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        )}
      {
        <div className="">
          {/* <div className="t3 bold">留言</div> */}
          <div className="mt-2 mb-24">
            <TextArea
              rows={4}
              placeholder={source === 'cart' ? '请输入留言内容' : orderInfo.remark}
              maxLength={200}
              disabled={source === 'cart' ? false : true}
              value={remark}
              onChange={(e) => {
                setRemark(e.target.value)
              }}
            />
          </div>
        </div>
      }
      {source === 'cart' && (
        <LoginWrapper>
          <button
            className="button button-lg button-primary row pay-button mb-24"
            onClick={handelSubmit}
          >
            确认订单
          </button>
        </LoginWrapper>
      )}
      {source === 'order_detail' && orderInfo?.status === 1 && (
        <LoginWrapper>
          <button
            className="button button-lg button-primary row pay-button mb-24"
            onClick={handelPay}
          >
            立即支付
          </button>
        </LoginWrapper>
      )}
      <PayCode
        visible={payCodeVisiable}
        onClose={() => handelClosePayCode()}
        order_id={orderId}
        pay_type={pay_type}
        order_time={orderInfo?.create_time}
      ></PayCode>
    </div>
  )
}

export default PayCart
