import { createModel } from '@rematch/core'
import { getWebInfo } from '@/apis/app'
import { RootModel } from '.'

const webInfo: any = JSON.parse(window.localStorage.getItem('webInfo') || '{}')

export const app = createModel<RootModel>()({
  state: {
    webInfo: webInfo,
    hasFetchWebInfo: false, // 避免reload的时候重复请求
  },
  reducers: {
    setWebInfo: (state, webInfo: any) => {
      return {
        ...state,
        webInfo,
      }
    },
    setHasFetchWebInfo: (state, hasFetchWebInfo: any) => {
      return {
        ...state,
        hasFetchWebInfo,
      }
    },
  },
  effects: (dispatch) => ({
    fetchWebInfo() {
      let hasFetchWebInfo: any = JSON.parse(
        window.localStorage.getItem('hasFetchWebInfo') || 'false'
      )
      if (!hasFetchWebInfo) {
        // window.localStorage.setItem('hasFetchWebInfo', JSON.stringify(true))
        // getWebInfo().then((res: any) => {
        //   window.localStorage.setItem('webInfo', JSON.stringify(res))
        //   dispatch.app.setWebInfo(res)
        //   if (!window.location.href.includes('?code=')) {
        //     window.location.reload()
        //   }
        // })
      }
      window.localStorage.setItem('hasFetchWebInfo', JSON.stringify(true))
      dispatch.app.setHasFetchWebInfo(true)
      setTimeout(() => {
        window.localStorage.setItem('hasFetchWebInfo', JSON.stringify(false))
      }, 5000)
    },
  }),
})
