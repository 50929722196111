import { createModel } from '@rematch/core'
import { getAddressList, addAddress } from '@/apis/address'
import { getTagTree1 } from '@/apis/home'
import { getUrlShopId } from '@/utils/common'
import { RootModel } from '.'

export const home = createModel<RootModel>()({
  state: {
    tagTree: [] as Store.Home.TagTree[], // 标签树
  } as Store.Home.IHome,
  reducers: {
    setTagTree: (state, tagTree: Store.Home.TagTree[]) => {
      return {
        ...state,
        tagTree,
      }
    },
  },
  effects: (dispatch) => ({
    getTagTree() {
      let shopId = getUrlShopId()
      getTagTree1({'shop_id': shopId}).then((res: Store.Home.TagTree[]) => {
        console.log('tagTree res', res)
        dispatch.home.setTagTree(res)
      })
    },
    async addAddressInfo(info: Store.Address.AddressInfo) {
      await addAddress(info)
      dispatch.address.getAddressList()
    },
  }),
})
