/*
 * @Author: axu 1342248325@qq.com
 * @Date: 2022-07-04 20:12:26
 * @LastEditors: axu 1342248325@qq.com
 * @LastEditTime: 2022-07-15 17:09:32
 * @FilePath: /my-app/src/store/models/global.ts
 * @Description: 地址相关
 */
import { createModel } from '@rematch/core'
import {
  getAddressList,
  addAddress,
  delAddress,
  editAddress,
  setDefaultAddress,
  getRegionOptions,
} from '@/apis/address'
import { RootModel } from '.'

export const address = createModel<RootModel>()({
  state: {
    addressList: [],
    addressInfo: {} as Store.Address.AddressInfo,
    regionOptions: [],
  } as Store.Address.IAddress,
  reducers: {
    setAddressList: (state, addressList: Store.Address.AddressInfo[]) => {
      return {
        ...state,
        addressList,
      }
    },
    setAddressInfo: (state, addressInfo: Store.Address.AddressInfo) => {
      return {
        ...state,
        addressInfo,
      }
    },
    setRegionOptions: (state, regionOptions: []) => {
      return {
        ...state,
        regionOptions,
      }
    },
  },
  effects: (dispatch) => ({
    getAddressList() {
      getAddressList().then((res: Store.Address.AddressInfo[]) => {
        dispatch.address.setAddressList(res)
        if (res.length === 0) {
          dispatch.address.setAddressInfo({} as Store.Address.AddressInfo)
          return
        }
        const item = res.find((item) => item.default === 1)
        if (item) {
          dispatch.address.setAddressInfo(item)
        }
      })
    },
    async addAddressInfo(info: Store.Address.AddressInfo) {
      await addAddress(info)
      dispatch.address.getAddressList()
    },
    async editAddressInfo(info: Store.Address.AddressInfo) {
      await editAddress(info)
      dispatch.address.getAddressList()
    },
    async delAddressInfo(info: Store.Address.AddressInfo) {
      if (info.id) {
        await delAddress({ ids: [info.id] })
        dispatch.address.getAddressList()
      }
    },
    async setDefaultAddressInfo(info: Store.Address.AddressInfo) {
      if (info.id) {
        await setDefaultAddress({ id: info.id })
        dispatch.address.getAddressList()
      }
    },
    fetchRegionOptions() {
      getRegionOptions().then((res) => {
        dispatch.address.setRegionOptions(res)
      })
    },
  }),
})
