import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { store } from '@/store/store'
import { Router, Route } from 'react-router-dom'
import AppRoutes from '@/router'
import '@/styles/public.scss'
import '@/styles/mini.scss'
import '@/styles/theme.scss'
import { checkOrientation, getUrlShopId } from '@/utils/common'

const container = document.getElementById('root')!
const root = createRoot(container)
// const state = store.getState()
// store.dispatch.app.fetchWebInfo()

// 初始状态检查一次
checkOrientation()
// 监听窗口大小变化事件（包括横竖屏切换）
window.addEventListener('resize', checkOrientation)
const getShopInfo = async () => {
  const shop_id = getUrlShopId()
  if (shop_id) {
    await store.dispatch.user.onGetShopInfo({ shop_id: shop_id })
  }
}
getShopInfo()

root.render(
  //React.StrictMode
  <Provider store={store}>
    <AppRoutes />
  </Provider>
)
