/*
 * @Author: axu 1342248325@qq.com
 * @Date: 2022-07-11 16:27:10
 * @LastEditors: axu 1342248325@qq.com
 * @LastEditTime: 2022-07-18 22:06:08
 * @FilePath: /js-view-newbox-h5/src/components/AddressCard/index.tsx
 * @Description: 地址卡片
 */
import React, { useState, forwardRef, useImperativeHandle } from 'react'
import AddressEdit from '@/components/AddressEdit'
import AddressList from '@/components/AddressList'
import { disableScroll, enableScroll } from '@/utils/common'
import './index.scss'
import { useAppDispatch } from '@/store/store'

interface ComponentProps {
  source: 'order_detail' | 'cart' | 'header'
  addressInfo: Store.Address.AddressInfo
}

const AddressCard = forwardRef((props: ComponentProps, onRef: any) => {
  const { source, addressInfo } = props
  const [addAddressVisable, setAddAddressVisable] = useState(false)
  const [addressListVisable, setAddressListVisable] = useState(false)
  const [editAddress, setEditAddress] = useState<Store.Address.AddressInfo>(
    {} as Store.Address.AddressInfo
  )
  const dispatch = useAppDispatch()

  // 编辑icon
  const handelEdit = () => {
    if (addressInfo.id) {
      setAddressListVisable(true)
      disableScroll()
    } else {
      setEditAddress({} as Store.Address.AddressInfo)
      setAddAddressVisable(true)
      disableScroll()
    }
  }

  // 展示地址列表
  const handelShowAddressList = () => {
    dispatch.address.getAddressList()
    setAddressListVisable(true)
    disableScroll()
  }

  // 新增地址
  const handelAddAddress = () => {
    setEditAddress({} as Store.Address.AddressInfo)
    setAddAddressVisable(true)
    disableScroll()
  }

  // 编辑地址
  const handelEditAddress = (item: Store.Address.AddressInfo) => {
    setEditAddress(item)
    setAddAddressVisable(true)
    disableScroll()
  }

  // 暴露给父组件的属性
  useImperativeHandle(onRef, () => ({
    handelShowAddressList,
  }))
  return (
    <div className="box-address round-sm">
      {(source === 'order_detail' || (source === 'cart' && addressInfo.id)) && (
        <div className="address-main py-24 px-32">
          <div className="address-main-head mb-16 flex flex-between flex-y-center">
            <span className="t2 bold">
              收货人：{addressInfo?.username || addressInfo?.consignee}，
              {addressInfo?.mobile}
            </span>
            {source === 'cart' && (
              <div className="flex flex-y-center pointer" onClick={handelEdit}>
                <span className="iconfont icon-bianji t4 text-primary"></span>
                <span className="t4 text-primary ml-8">编辑</span>
              </div>
            )}
          </div>
          {source === 'cart' && (
            <div className="address-main-head t3 borderTop text-gray pt-20  font-normal">
              地址：{addressInfo?.province}
              {addressInfo?.city}
              {addressInfo?.area}
              {addressInfo?.detail_address}
            </div>
          )}
          {source === 'order_detail' && (
            <div className="address-main-head t3 borderTop text-gray pt-20  font-normal">
              地址：{addressInfo?.address}
              {addressInfo?.detail_address}
            </div>
          )}
        </div>
      )}
      {source === 'cart' && !addressInfo.id && (
        <div className="address-main py-26 px-28 flex flex-between flex-y-center">
          <div className="flex flex-y-center">
            <span className="iconfont icon-address t1 text-gray"></span>
            <span className="t2 text-gray bold ml-10">暂无收货地址</span>
          </div>
          <div className="flex flex-y-center pointer" onClick={handelEdit}>
            <span className="iconfont icon-edit t4 text-light"></span>
            <span className="t4 text-light ml-8">编辑</span>
          </div>
        </div>
      )}

      <AddressEdit
        editAddress={editAddress}
        visable={addAddressVisable}
        onClose={() => {
          setAddAddressVisable(false)
          enableScroll()
        }}
      />
      <AddressList
        visable={addressListVisable}
        onClose={() => {
          setAddressListVisable(false)
          enableScroll()
        }}
        onAddAddress={handelAddAddress}
        onEditAddress={handelEditAddress}
      />
    </div>
  )
})

export default AddressCard
