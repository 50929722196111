/*
 * @Author: axu 1342248325@qq.com
 * @Date: 2022-07-09 10:13:34
 * @LastEditors: axu 1342248325@qq.com
 * @LastEditTime: 2022-08-10 17:16:29
 * @FilePath: /js-view-newbox-h5/src/apis/custom.ts
 * @Description: 定制相关接口
 */

import request from '@/utils/request'

/**
 * 获取商品详情
 */
export const getGoodsInfo = (data: { spu_id: number }) =>
  request({
    method: 'GET',
    url: '/api/ToC/V1/Spu/newInfo',
    params: data,
  })

/**
 *
 * @param data
 * @returns
 */
export const getTemplatesInfo = (data: any) =>
  request({
    method: 'GET',
    url: '/api/ToC/V1/Spu/getTemplatesInfo',
    params: data,
  })

/*
 * 文字转图片
 */
export const textToPicture = (data: any) => {
  const url = {
    '1': '/api/ToC/V1/Template/textToPicture',
    '2': '/api/ThirdParty/Plugs/textToPicture',
    '3': '/api/Admin/Template/textToPicture',
  }
  return request({
    method: 'POST',
    // @ts-ignore
    url: url[data.source],
    data: data,
  })
}

/*
 * 转简笔画
 */
export const getStickyImg = async (data: { image_url: string }) => {
  const url = '/api/ThirdParty/Plugs/getSimplePicture'
  const result = await request<string>({
    method: 'POST',
    url,
    data,
  })
  // if (Number(result.ok) === 0) {
  //   setTimeout(() => {
  //     Toast.show({ icon: '', content: '自动抠图失败，请重新上传' });
  //   }, 500);
  // }
  return result
}

/*
 * logo抠图
 */
export const getGdLogo = async (data: any) => {
  const url = {
    '1': '/api/ToC/V1/Custom/gaoding',
    '2': '/api/ThirdParty/Plugs/gaoding',
    '3': '/api/Admin/Plugs/gaoding',
  }
  const result = await request<string>({
    method: 'GET',
    // @ts-ignore
    url: url[String(data.source)],
    params: data,
  })
  // if (Number(result.ok) === 0) {
  //   setTimeout(() => {
  //     Toast.show({ icon: '', content: '自动抠图失败，请重新上传' });
  //   }, 500);
  // }
  return result.img
}

/*
 * logo改色
 */
export const LogoColor = (data: any) => {
  const url = {
    '1': '/api/ToC/V1/Plugs/changeLogoColor',
    '2': '/api/ThirdParty/Plugs/changeLogoColor',
    '3': '/api/Admin/Plugs/changeLogoColor',
  }
  return request({
    method: 'GET',
    // @ts-ignore
    url: url[String(data.source)],
    params: data,
  })
}

/*
 * 获取素材库列表
 */
export const getAdminMaterialLibrary = (data: any) =>
  request({
    method: 'GET',
    url: '/api/Admin/MaterialLibrary/list',
    params: data,
  })

/*
 * 新增素材库
 */
export const addAdminMaterialLibrary = (data: any) =>
  request({
    method: 'POST',
    url: '/api/Admin/MaterialLibrary/add',
    data: data,
  })

/*
 * 移动素材库
 */
export const moveAdminMaterialLibrary = (data: any) =>
  request({
    method: 'POST',
    url: '/api/Admin/MaterialLibrary/moveToGlobal',
    data: data,
  })

/*
 * 删除素材库
 */
export const delAdminMaterialLibrary = (data: any) =>
  request({
    method: 'POST',
    url: '/api/Admin/MaterialLibrary/delete',
    data: data,
  })

/*
 * 获取素材库列表
 */
export const getMaterialLibrary = (data: any) =>
  request({
    method: 'GET',
    url: '/api/ToC/V1/MaterialLibrary/list',
    params: data,
  })

/*
 * 获取我的素材库列表
 */
export const getMaterialLibraryUserList = (data: any) =>
  request({
    method: 'GET',
    url: '/api/ToC/V1/MaterialLibrary/userList',
    params: data,
  })

/*
 * 删除我的素材库列表
 */
export const materialLibraryDelUser = (data: any) =>
  request({
    method: 'POST',
    url: '/api/ToC/V1/MaterialLibrary/delUser',
    data: data,
  })

/*
 * 获取订单素材库列表
 */
export const getMaterialLibraryOrder = (data: any) =>
  request({
    method: 'GET',
    url: '/api/ThirdParty/Order/materialLibraryList',
    params: data,
  })

/**
 * 后台模版配置/获取模板信息
 */
export const getAdminTemplateInfo = (data: any) => {
  const url = {
    '1': '/api/ToC/V1/Template/adminInfo',
    '2': '/api/ThirdParty/Order/adminInfo',
    '3': '/api/Admin/Template/info',
  }
  return request({
    method: 'GET',
    // @ts-ignore
    url: url[data.source],
    params: data,
  })
}

/*
 * 模版库获取模版库列表
 */
export const getUserCustomTemplateList = (params: any) =>
  request({
    method: 'GET',
    url: '/api/ToC/V1/Custom/getUserCustomTemplateList',
    params,
  })

/*
 * 获取官方模版库列表
 */
export const getOfficialTemplateList = (data: any) =>
  request({
    method: 'GET',
    url: '/api/ToC/V1/Custom/getTemplateList',
    params: data,
  })

/*
 * 删除模版库模版
 */
export const delUserCustomTemplate = (data: any) =>
  request({
    method: 'POST',
    url: '/api/ToC/V1/Custom/delUserCustomTemplate',
    data: data,
  })

/*
 * 获取历史订单
 */
export const getOrderCustomList = (data: any) =>
  request({
    method: 'GET',
    url: '/api/ThirdParty/Plugs/getOrderCustomList',
    params: data,
  })

/*
 * 裁剪
 */
export const clipImage = (data: any) =>
  request({
    method: 'POST',
    url: '/api/ThirdParty/Plugs/cuttingImage',
    data,
  })

/*
 * 保存模板信息
 */
export const saveTemplateConfigNew = (data: any) => {
  const url = {
    '1': '/api/ToC/V1/Template/newConfig',
    '2': '/api/ThirdParty/Order/customInfoNewConfig',
    '3': '/api/Admin/Template/newConfig',
  }
  return request({
    method: 'POST',
    // @ts-ignore
    url: url[data.source],
    data: data,
  })
}

/*
 * 保存模版到模版库
 */
export const saveCustomTemplateInfo = (data: any) =>
  request({
    method: 'POST',
    url: '/api/ToC/V1/Custom/saveCustomTemplateInfo',
    data: data,
  })

/*
 * 获取运营图
 */
export const getMarketImg = (data: any) =>
  request({
    method: 'POST',
    url: '/api/ToC/V1/Template/getMarketImg',
    data: data,
  })

/**
 * 获取子订单详情
 */
export const getOrderItemInfo = (data: any) =>
  request({
    method: 'GET',
    url: '/api/ThirdParty/Order/orderItemInfo',
    params: data,
  })

/*
 * spu定制效果预览图
 */
export const spuPreview = (data: any) =>
  request({
    method: 'GET',
    url: '/api/ThirdParty/Order/preview',
    params: data,
  })

/*
 * 框定位信息
 */
export function getSpuInfo(params: any) {
  return request({
    url: '/api/Admin/Goods/getSpuInfo',
    method: 'GET',
    params,
  })
}

/*
 * 保存spu定位信息
 */
export const saveSpuLocationInfo = (data: any) =>
  request({
    method: 'POST',
    url: '/api/Admin/Goods/saveSpuLocationInfo',
    data: data,
  })

/**
 * 后台模版配置获取预览商品
 */

export const getTemplateGoodsList = (params: any) =>
  request({
    method: 'GET',
    url: '/api/Admin/goods/templateGoodsList',
    params: params,
  })

// 预览页，获取spu详情
export function getSpuTemplateInfo(params: any) {
  return request({
    url: '/api/Admin/Goods/getSpuTemplate',
    method: 'GET',
    params,
  })
}

/*
 * 获取logo列表
 */
export const LogoList = (data) =>
  request({
    method: 'GET',
    url: '/api/ToC/V1/ShuiMu/logoList',
    params: data,
  })

/*
 * 获取水母logo二维码
 */
export const getWxQrcodeOfProduction = (data) =>
  request({
    method: 'GET',
    url: '/api/ToC/V1/ShuiMu/wxQrcodeOfProduction',
    params: data,
  })

/*
 * 下载水母logo压缩包
 */
export const logoZipOfProduction = (data) =>
  request({
    method: 'GET',
    url: '/api/ToC/V1/ShuiMu/logoZipOfProduction',
    params: data,
  })

/*
 * 新增我的素材
 */
export const MaterialLibraryAddUser = (data) =>
  request({
    method: 'POST',
    url: '/api/ToC/V1/MaterialLibrary/addUser',
    data: data,
  })

/**
 * 获取自定义营销页面详情
 */
export const getCustomInfo = (params: any) =>
  request({
    method: 'GET',
    url: '/api/ToC/V1/Index/getInfo',
    params,
  })
