import React, { useEffect, useState } from 'react'
import { scrollTo } from '@/utils/common'
import { useAppSelector } from '@/store/store'
import { Spin, Affix, FloatButton, Popover } from 'antd'
import './index.scss'
import { CustomerServiceOutlined } from '@ant-design/icons'

interface ComponentProps {
  topId?: string
}

const Kefu: React.FC<ComponentProps> = (props: ComponentProps) => {
  const { topId } = props
  const shop = useAppSelector((state) => state.user.shop)

  const renderKefu = () => {
    return (
      <div className="kefuPop">
        <div className="kefuPop-title">客服</div>
        {shop.config.customer.qrcode ? (
          <img
            src={shop.config.customer.qrcode}
            style={{ width: '100px', height: '100px' }}
            className="kefuPop-qrcode"
          />
        ) : null}
        <div className="kefuPop-mobile">
          联系电话：{shop.config ? shop.config.customer.mobile : ''}
        </div>
      </div>
    )
  }

  return (
    <Popover
      placement="left"
      content={() => shop.config.customer && renderKefu()}
    >
      <FloatButton
        shape="circle"
        type="default"
        style={{ right: 94 }}
        icon={<CustomerServiceOutlined />}
      />
    </Popover>
  )
}
export default Kefu
