import React, { useState, memo, useEffect } from 'react'
import { Tabs, Spin } from 'antd'
import GoodsCard from '@/components/GoodsCard'
import { useRouter } from '@/hooks'
import { getGoodsList } from '@/apis/goods'
import { isEqual } from 'lodash'
import './index.scss'

interface MallTagProps {
  tagList: any
}

const MallTag: React.FC<MallTagProps> = (props: MallTagProps) => {
  const { tagList } = props

  const scrollTo = (item) => {
    setTimeout(() => {
      console.log(`mItem `)
      let el: any = document.getElementById(`mall-card-${item.id}`)
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      })
    }, 300)
  }
  return (
    <div className="MallTag">
      {tagList && tagList.length > 0
        ? tagList.map((item: any, index: number) => {
            return (
              <div
                className="MallTag-item"
                key={index}
                onClick={() => scrollTo(item)}
              >
                <img src={item.icon_img} className="MallTag-item-icon" />
                <div className="MallTag-item-title ellipsis2">{item.title}</div>
              </div>
            )
          })
        : null}
    </div>
  )
}

export default MallTag
