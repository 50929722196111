/*
 * @Author: axu 1342248325@qq.com
 * @Date: 2022-07-04 15:27:23
 * @LastEditors: zhao_juchang 2233737325@qq.com
 * @LastEditTime: 2022-09-07 16:54:18
 * @FilePath: /js-view-newbox-h5/src/router/routeConfig.tsx
 * @Description: 路由列表
 */
import { lazy } from 'react'
import { useRoutes } from 'react-router-dom'
import { isPc } from '@/utils/common'
import Design_template from '@/pages/design_template/index'
import Goods from '@/pages/goods/index'
import GoodsHeli from '@/pages/goods_heli/index'
import NotFound from '@/pages/notFound/index'
import Package from '@/pages/package/index'
import Cart from '@/pages/cart/index'
import MCart from '@/m_pages/m_cart/index'
import Order from '@/pages/order/index'
import OrderDetail from '@/pages/order_detail/index'
import Mall from '@/pages/mall/index'
import Tag_goods from '@/pages/tag_goods/index'
import PcMarketing from '@/pages/pcMarketing/index'
// const Design_template = lazy(() => import('@/pages/design_template/index'))

namespace SyncRoute {
  export type Routes = {
    path: string
    element: any
    children?: Routes[]
    isAuth?: boolean
    mobile?: any
  }
}

const GetRoutes = () => {
  const RouteConfig: any = [
    {
      path: '/',
      element: <Mall/>,
      // element: lazy(() => import('@/pages/design_template/index')),
      // mobile: lazy(() => import('@/m_pages/m_mall')),
    },
    {
      path: '/classify',
      element: <Design_template/>,
      // element: lazy(() => import('@/pages/design_template/index')),
      // mobile: lazy(() => import('@/m_pages/m_mall')),
    },
    {
      path: '/tagGoods',
      element: <Tag_goods/>,
    },
    {
      path: '/goods',
      element: <Goods/>,
      children: [
        {
          path: ':id',
          element: <Goods/>,
        },
      ],
    },
    {
      path: '/goods_heli',
      element: <GoodsHeli/>,
      children: [
        {
          path: ':id',
          element: <GoodsHeli/>,
        },
      ],
    },
    {
      path: '/package',
      element: <Package/>,
      children: [
        {
          path: ':id',
          element: <Package/>,
        },
      ],
    },
    {
      path: '/cart',
      element: <Cart/>,
      // mobile: <MCart/>,
    },
    {
      path: '/pcMarketing',
      element: <PcMarketing/>,
      children: [
        {
          path: ':id',
          element: <PcMarketing/>,
        },
      ],
    },
    {
      path: '/order',
      element: <Order/>,
    },
    {
      path: '/order_detail',
      element: <OrderDetail/>,
      children: [
        {
          path: ':id',
          element: <OrderDetail/>,
        },
      ],
    },
    { path: '*', element: <NotFound /> },
  ]
  let mRouteTable: any[] = []
  const syncRouter = (table: any[]) => {
    table.forEach((route) => {
      if (route.element && route.mobile) {
        route.element = isPc() ? route.element : route.mobile
        // route.element = route.element
      }
      mRouteTable.push({
        path: route.path,
        element: route.element,
        children: route.children && syncRouter(route.children),
      })
    })
    return mRouteTable
  }
  syncRouter(RouteConfig)
  return useRoutes(RouteConfig)
}

export default GetRoutes
