/*
 * @Author: axu 1342248325@qq.com
 * @Date: 2022-07-04 11:49:24
 * @LastEditors: axu 1342248325@qq.com
 * @LastEditTime: 2022-07-18 17:24:11
 * @FilePath: /my-app/src/utils/router.ts
 * @Description: 路由hooks
 */
import {
  useNavigate,
  useLocation,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { getUrlShopId } from '@/utils/common'

const useRouter = () => {
  const navigate = useNavigate()
  const routerParams = useParams()
  const location = useLocation()
  const [searchParams] = useSearchParams()

  const navigateTo = (url: string) => {
    console.log(getUrlShopId());
    let shopId = getUrlShopId()
    if(shopId) {
      url = `${url}${url.includes('?') ? '&' : '?'}shop_id=${getUrlShopId()}`
    }
    navigate(url)
  }

  const navigateBlank = (url: string) => {
    let shopId = getUrlShopId()
    if(shopId) {
      url = `${url}${url.includes('?') ? '&' : '?'}shop_id=${getUrlShopId()}`
    }
    window.open(url, '_blank')
  }

  const navigateReplace = (url: string) => {
    navigate(url, { replace: true })
  }

  const navigateBack = (backIndex = -1) => {
    navigate(backIndex)
  }

  const navigateForward = (forwardIndex = 1) => {
    navigate(forwardIndex)
  }

  return {
    navigateTo,
    navigateBlank,
    navigateBack,
    navigateReplace,
    navigateForward,
    location,
    searchParams,
    routerParams,
  }
}

export default useRouter
