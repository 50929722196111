/*
 * @Author: axu
 * @Date: 2021-04-28 19:49:34
 * @LastEditTime: 2021-10-11 15:36:38
 * @LastEditors: Please set LastEditors
 * @Description: 商品详情规格属性弹框
 * @FilePath: /fitatom-shop-miniprogram/src/pages/product/Popdetail/index.tsx
 */
import React, { useState, useEffect } from 'react'
import { multiply } from '@/utils/common'

import './index.scss'

interface IProps {
  /**
   * @name sku列表
   */
  attrs: Store.Product.IProductAttrs
  /**
   * @name 商品主图
   */
  img?: string
  /**
   * @name 关闭弹框
   */
  onClose?: () => void
  /**
   * @name 确认按钮
   */
  onConfirm: (params) => void
  /**
   * @name 是否显示
   */
  visible?: boolean
  /**
   * @name 是否可以更改数量
   */
  changeNum?: boolean
  /**
   * @name 选中的盒型
   */
  selectSkuId?: number | string
  /**
   * @name 是否需要安全区域的高度
   */
  safeArea?: boolean
  // 选中
  goodsInfo?: Store.Goods.GoodsInfo
  number?: number
  chooseSku?: Store.Product.IProductAttrsSkus | undefined
}

interface tempSkuAttrs {
  stock?: number
  attrValues?: any
  customizeName?: any
  attrId: string
  isShow: boolean
  selectInd: number
  greyList: Array<string> //置灰数组
  stockList: Array<string> //缺货数组
  isMatchModel: boolean
  valsList: Array<any>
  vals: any
  [proppName: string]: any
}

interface ISku {
  unitPrice?: string
  originalPrice?: string
  [proppName: string]: any
}

const SpecificationAttrs: React.FC<IProps> = (props: IProps) => {
  const { selectSkuId, attrs, safeArea, goodsInfo, number, chooseSku } = props
  const [sku, setSku] = useState<ISku>({
    unitPrice: '',
    originalPrice: '',
  })
  const [tempSkuAttrs, setTempSkuAttrs] = useState<tempSkuAttrs[]>([]) //规格属性列表
  const [img, setImg] = useState('')
  const [num, setNum] = useState(1)
  const [totalPrice, setTotalPrice] = useState('') // 商品总价

  useEffect(() => {
    init()
  }, [attrs, goodsInfo])

  // 根据数量和价格计算阶梯价格的展示
  useEffect(() => {
    if (number && chooseSku && chooseSku.price_list.length > 0 && chooseSku.price_list_status) {
      console.log('chooseSku', chooseSku);
      console.log('number', number);
      chooseSku.price_list.forEach((item) => {
        if (number >= item.min_num && number <= item.max_num) {
          // setTotalPrice(`￥${multiply(number, item.sale_price_format)}`)
          setTotalPrice(`￥${item.sale_price_format}`)
        }
      }
      )
    }
  }, [chooseSku, number])

  const init = async () => {
    if (goodsInfo) {
      if (goodsInfo.min_price != goodsInfo.max_price) {
        setTotalPrice(`￥${goodsInfo.min_price}~${goodsInfo.max_price}`)
      } else {
        setTotalPrice(`￥${goodsInfo.min_price}`)
      }
    }
    if (attrs.spec_arr && attrs.spec_arr.length > 0) {
      processingSkuData(attrs.spec_arr, attrs.sku_arr)
    } else if (attrs.sku_arr && attrs.sku_arr.length === 1) {
      setSku(attrs.sku_arr[0])
    }
  }

  // 处理 属性、机型匹配
  const processingSkuData = async (
    attrsList: Store.Product.IproductAttrList[],
    skus: Store.Product.IProductAttrsSkus[]
  ) => {
    const newAttrs: tempSkuAttrs[] = []
    // 初始化，方便页面渲染，数据格式为[{attrname:'分类名称1',valsList:[此分类对应的所有属性选项]}]
    attrsList.forEach((item: Store.Product.IproductAttrList) => {
      // 先在数组中插入基本的分类选项
      newAttrs.push({
        attrId: item.name,
        attrName: item.name,
        isShow: true,
        ...item,
        selectInd: -1,
        greyList: [], //置灰数组
        stockList: [], //缺货数组
        isMatchModel: false,
        valsList: [],
        vals: {},
      })
    })
    skus.forEach((skuItem: Store.Product.IProductAttrsSkus) => {
      skuItem.customizeName = skuItem.spec_info
        .map((item) => item.value)
        .join('-')
      newAttrs.forEach((attrItem: tempSkuAttrs) => {
        const findIndex: number = skuItem.spec_info.findIndex(
          (item) => item.key === attrItem.attrId
        )
        if (findIndex >= 0) {
          const val_new = attrItem.vals[skuItem.spec_info[findIndex].id]
          if (!val_new || val_new.id === skuItem.spec_info[findIndex].id) {
            attrItem.vals[skuItem.spec_info[findIndex].id] = {
              ...skuItem.spec_info[findIndex],
              skus: val_new ? [...val_new.skus, skuItem] : [skuItem],
            }
            publicFunc(attrItem, attrItem.vals)
          }
        }
      })
    })

    if (selectSkuId) {
      const arr = checkSkuId(newAttrs, selectSkuId)
      const result = filterAttrsGreay(arr, -1)
      setTempSkuAttrs(result)
      checkSkuInfo(result)
      console.log('result', result)
    } else {
      const result = filterAttrsGreay(newAttrs, -1)
      setTempSkuAttrs(result)
      // checkSkuInfo(result)
      console.log('result', result)
    }
  }

  // 设置选中值
  const checkSkuInfo = (result) => {
    const sku_new = getFinalSkuinfo(result)
    if (sku_new && sku_new.img) {
      if (sku_new.price) {
        setTotalPrice(`¥${String(sku_new.price)}`)
      }
      setImg(sku_new.img)
      setSku(sku_new)
      console.log('sku_new', sku_new)
      props.onConfirm(sku_new)
    } else {
      setSku({})
    }
  }

  // 已有选中的skuid
  const checkSkuId = (arr, id) => {
    const newArr = [...arr]
    if (id) {
      const index = attrs.sku_arr.findIndex(
        (item) => Number(item.shop_sku_id) === Number(id)
      )
      if (index > -1) {
        const skuInfo = attrs.sku_arr[index].spec_info
        newArr.map((item) => {
          const attrIndex = skuInfo.findIndex(
            (i) => String(i.key) === String(item.attrId)
          )
          if (attrIndex > -1) {
            const ValIndex = item.valsList.findIndex(
              (val) => Number(val.valId) === Number(skuInfo[attrIndex].id)
            )
            item.selectInd = ValIndex
          }
        })
      }
    }
    return newArr
  }

  // 数据处理--缺货/置灰
  const filterAttrsGreay = (
    newAttrs: tempSkuAttrs[],
    currentAttrInd: number
  ) => {
    newAttrs.forEach((attrsItem, index, originArr) => {
      let haveList = []
      // 当前属性 置灰 是由除自身以外的属性筛选出的 sku  来决定的
      originArr.forEach((secAttrs) => {
        if (attrsItem.attrId !== secAttrs.attrId) {
          if (secAttrs.selectInd > -1) {
            // 从已选中的sku中再次筛选
            haveList = (
              haveList.length > 0
                ? haveList
                : secAttrs.vals[secAttrs.valsList[secAttrs.selectInd].valId]
                    .skus
            ).filter((skuItem) => {
              return (
                skuItem.customizeName.indexOf(
                  secAttrs.valsList[secAttrs.selectInd].valName
                ) > -1
              )
            })
          }
        }
      })
      attrsItem.greyList = []
      attrsItem.stockList = []
      // 从上一步 筛选出得 skus 中 找出 当前 属性 所对应的属性值添加到一个临时Set结构中
      const tempSet = new Set()
      const stockSet = new Set()
      ;(haveList.length > 0 ? haveList : attrs.sku_arr).forEach((item) => {
        item.spec_info.forEach((attr) => {
          if (attr.key === attrsItem.attrId) {
            tempSet.add(String(attr.id))
          }
        })
        if (item.inventory <= 0) {
          item.spec_info.forEach((attr) => {
            stockSet.add(String(attr.id))
          })
        }
      })
      // 从当前属性对应的属性值列表中根据上一步生成的 Set 结构中，找出不存在属性值添加到置灰列表中
      publicFunc(attrsItem, attrsItem.vals)
      attrsItem.valsList.forEach((vals_item) => {
        if (!tempSet.has(vals_item.valId)) {
          attrsItem.greyList.push(vals_item.valId)
          for (const i in attrsItem.vals) {
            if (Number(attrsItem.vals[i].id) === Number(vals_item.valId)) {
              const sku_index = attrsItem.vals[i].skus.findIndex(
                (sku_item) => sku_item.inventory > 0
              )
              if (sku_index === -1) {
                attrsItem.stockList.push(vals_item.valId)
              }
            }
          }
        }
        if (stockSet.has(vals_item.valId)) {
          for (const i in attrsItem.vals) {
            if (Number(attrsItem.vals[i].id) === Number(vals_item.valId)) {
              const sku_index = attrsItem.vals[i].skus.findIndex(
                (sku_item) => sku_item.inventory > 0
              )
              if (sku_index === -1) {
                attrsItem.stockList.push(vals_item.valId)
              }
            }
          }
        }
        //更改按钮时取消选中已置灰的选项
        if (
          attrsItem.valsList[attrsItem.selectInd] &&
          attrsItem.greyList.findIndex(
            (item) => item === attrsItem.valsList[attrsItem.selectInd].valId
          ) > -1
        ) {
          attrsItem.selectInd = -1
        }
      })
      // 设置唯一项选中  currentAttrInd盒型，尺寸等的索引
      if (
        currentAttrInd > -1 &&
        currentAttrInd !== index &&
        attrsItem.valsList.length === attrsItem.greyList.length + 1
      ) {
        const index_new = attrsItem.valsList.findIndex((val) =>
          tempSet.has(val.valId)
        )
        const stockIndex = attrsItem.valsList.findIndex((val) =>
          stockSet.has(val.valId)
        )
        //当唯一选中项变化的时候
        if (index_new !== attrsItem.selectInd && index_new !== stockIndex) {
          //选中唯一项
          attrsItem.selectInd = index_new
          newAttrs = filterAttrsGreay(newAttrs, index_new)
        }
      }
    })
    return newAttrs
  }

  // 数据处理--添加valsList
  const publicFunc = (obj: tempSkuAttrs, mapObj: tempSkuAttrs) => {
    // 跳过第一个标签对应的 标签值 排序，对剩余的 标签和 属性（值）进行排序
    obj.valsList = Object.keys(mapObj)
      .map((valId: string) => ({ valId, valName: mapObj[valId].value }))
      .sort((a, b) => (a.valId > b.valId ? 1 : -1))
    let subscripts = -1
    if (
      (subscripts = obj.valsList.findIndex((vals) => vals.valName === '其它')) >
      -1
    ) {
      // 如果找到其它 就把 其它 跟末尾的 数据进行 交换
      const lastInd = obj.valsList.length - 1
      ;[obj.valsList[subscripts], obj.valsList[lastInd]] = [
        obj.valsList[lastInd],
        obj.valsList[subscripts],
      ]
    }
  }

  // 点击切换属性
  const attrClick = (attr: tempSkuAttrs, valsIdx: number, attrInd: number) => {
    //点击置灰则直接return
    if (tempSkuAttrs[attrInd].greyList.includes(attr.valId)) return
    if (tempSkuAttrs[attrInd].stockList.includes(attr.valId)) return
    if (tempSkuAttrs[attrInd].selectInd === valsIdx) {
      tempSkuAttrs[attrInd].selectInd = -1
    } else {
      tempSkuAttrs[attrInd].selectInd = valsIdx
    }
    const result = filterAttrsGreay(
      tempSkuAttrs,
      tempSkuAttrs[attrInd].selectInd < 0 ? -1 : attrInd
    )
    const sku_new = getFinalSkuinfo(result)
    if (sku_new && sku_new.img) {
      if (sku_new.price) {
        setTotalPrice(`¥${String(sku_new.price)}`)
      }
      setImg(sku_new.img)
      setSku(sku_new)
      console.log('sku_new', sku_new)
      props.onConfirm(sku_new)
    } else {
      setSku({})
    }
  }

  // 数据处理--最终选中的sku
  const getFinalSkuinfo = (list?) => {
    let arr = list ? list : [...tempSkuAttrs]
    // 对所有已选中和未选中的标签值和属性值拼接成字符串，为后续筛选少做一步数组的遍历
    const str = arr
      .map((it) => {
        if (it.selectInd === -1) return ''
        return it.vals[it.valsList[it.selectInd].valId].value
      })
      .join('-')
    const lastObj = arr[arr.length - 1]
    // 从排序在最后一个属性或标签下的skus做最终选中的sku筛选
    if (!lastObj.valsList[lastObj.selectInd]) {
      return
    }
    arr = lastObj.vals[lastObj.valsList[lastObj.selectInd].valId].skus.filter(
      (skuItem) => {
        // 找出所有符合已选属性和标签对应的 sku
        return skuItem.spec_info.every((v) => {
          if (v.id === '-1') {
            //标签最终要去掉
            return true
          }
          return str.indexOf(v.value) > -1
        })
      }
    )
    if (arr.length) {
      return arr[0]
    }
  }

  // 提交
  const submit = () => {
    if (attrs.spec_arr && attrs.spec_arr.length > 0) {
      const no_select = tempSkuAttrs.filter((item) => item.selectInd === -1)
      if (no_select.length > 0) {
        // Taro.showToast({
        //   title: '请先选择' + no_select[0].attrName,
        //   icon: 'none',
        // })
        return
      }
      const result: tempSkuAttrs = getFinalSkuinfo()
      const obj = {
        num: num,
        ...result,
        shop_spu_id: attrs.shop_spu_id,
        sku_title: result.customizeName,
      }
      console.log('规格属性', obj)
      props.onConfirm(obj)
    } else {
      const obj = {
        num: num,
        ...attrs.sku_arr[0],
        shop_spu_id: attrs.shop_spu_id,
        sku_title: attrs.shop_spu_name,
      }
      console.log('规格属性', obj)
      props.onConfirm(obj)
    }
  }

  // 规格属性渲染
  const renderSpec = () => {
    return tempSkuAttrs.map((item, ind) => (
      <div className="speci-sku-box mt-24" key={item.id}>
        {/* {sku.price} */}
        <div className="t4 mt-24 mb-24 speci-sku-box-attr">{item.attrName}</div>
        <div>
          {item.valsList.map((sub: any, index: number) => {
            const isdisable = item.greyList.includes(sub.valId)
            const nostock = item.stockList.includes(sub.valId)
            return (
              <div
                className={[
                  'speci-sku-item px-24 mr-8 mb-8',
                  item.selectInd === index ? 'action' : ' ',
                  isdisable ? 'disable' : '',
                  nostock ? 'disable' : '',
                ].join(' ')}
                style={item.valName === '其他' ? { display: 'none' } : {}}
                key={sub.valId}
                onClick={() => attrClick(sub, index, ind)}
              >
                <span>{sub.valName}</span>
              </div>
            )
          })}
        </div>
      </div>
    ))
  }

  const getName = () => {
    let skuName = '--'
    if (attrs.spec_arr && attrs.spec_arr.length > 0) {
      skuName = sku && sku.customizeName ? sku.customizeName : '--'
    } else {
      skuName = attrs.shop_spu_name
    }
    return skuName
  }

  return (
    <div>
      <div className="title flex flex-col borderBtm">
        <span className="t2 bold">{goodsInfo?.shop_spu_name}</span>
        {
          goodsInfo?.shop_spu_title ? (
            <span className="t2 text-gray600">{goodsInfo?.shop_spu_title}</span>
          ) : null
        }
        <span className="t1 text-light mt-6 mb-20">{totalPrice}</span>
      </div>
      <div
        className={`speci ${
          !attrs.spec_arr || attrs.spec_arr.length <= 0
            ? 'speci-active'
            : 'speci-hot'
        }`}
      >
        <div className="speci-scroll-div">
          {attrs.spec_arr && attrs.spec_arr.length > 0 && (
            <div>{renderSpec()}</div>
          )}
        </div>
      </div>
    </div>
  )
}

export default React.memo(SpecificationAttrs)
