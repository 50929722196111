import React, { useEffect, useState, useLayoutEffect } from 'react'
import Header from '@/components/Header'
import { useRouter } from '@/hooks'
import { Select, Spin, Empty } from 'antd'
import { useAppSelector, store } from '@/store/store'
import { getFirstTagInfo, getTagGoods } from '@/apis/home'
import GoodsCard from '@/components/GoodsCard'
import InfiniteScroll from 'react-infinite-scroll-component'
import { isPc, whiteList } from '@/utils/common'
import './index.scss'
import { RightCircleOutlined } from '@ant-design/icons'

let timer: number
const Tag_goods: React.FC = () => {
  // const { Option } = Select
  const { routerParams, searchParams } = useRouter()
  const [chooseSecond, setChooseSecond] = useState<Store.Category.TagItem>(
    {} as Store.Category.TagItem
  )
  const [chooseThree, setChooseThree] = useState<Store.Category.TagItem>(
    {} as Store.Category.TagItem
  )
  const [chooseTag, setChooseTag] = useState<Store.Category.TagItem>(
    {} as Store.Category.TagItem
  )
  const gridPadding = useAppSelector((state) => state.global.gridPadding)
  const userInfo = useAppSelector((state) => state.user.userInfo)
  const [goods, setGoods] = useState<Store.Goods.GoodsListItem[]>([])
  const [page, setPage] = useState(1)
  const [more, setMore] = useState(true)
  const [first_tag_info, set_first_tag_info] = useState<any>({})
  const [second_tag_list, set_second_tag_list] = useState<any>([])
  const [showCategory, setShowCategory] = useState(true)
  const [showRightArrow, setShowRightArrow] = useState(false)
  const dispatch = store.dispatch

  useEffect(() => {
    let shopInfo = JSON.parse(window.localStorage.getItem('shop') ?? '{}')
    if (searchParams.get('shop_id')) {
      dispatch.user.onGetShopInfo({ shop_id: searchParams.get('shop_id') })
    }
    if (!whiteList.includes(window.location.origin)) {
      dispatch.user.onGetShopInfo({ shop_id: undefined })
    }
    if (
      searchParams.get('shop_id') &&
      shopInfo.id &&
      searchParams.get('shop_id') != shopInfo.id
    ) {
      return
    }
    if (searchParams.get('first_tag_id')) {
      onGetFirstTagInfo(searchParams.get('first_tag_id'))
    }
  }, [])

  useEffect(() => {
    if (second_tag_list.length > 0) {
      onShowRightArrow()
    }
  }, [second_tag_list])

  // 判断是否显示右箭头
  const onShowRightArrow = () => {
    let tagInactiveDom: any = document.querySelectorAll('.tag-inactive')
    if (tagInactiveDom.length !== 0) {
      let offsetLeft = tagInactiveDom[tagInactiveDom.length - 1]?.offsetLeft
      if (offsetLeft + 100 > window.innerWidth) {
        setShowRightArrow(true)
      } else {
        setShowRightArrow(false)
      }
    }
  }

  // 右箭头点击事件
  const gotoRight = () => {
    let filterDom = document.querySelector('.filter')
    if (filterDom) {
      filterDom.scrollLeft = filterDom.scrollLeft + window.innerWidth * 0.9
    }
  }

  // 获取标签列表
  const onGetFirstTagInfo = (first_tag_id) => {
    getFirstTagInfo({ first_tag_id: first_tag_id }).then((res) => {
      if (res.first_tag_info) {
        set_first_tag_info(res.first_tag_info)
        set_second_tag_list(res.second_tag_list)
        let secondIndex = 0
        if (searchParams.get('three_tag_id')) {
          res.second_tag_list.some((item: Store.Category.TagItem) => {
            if (item.id == searchParams.get('second_tag_id')) {
              secondIndex = res.second_tag_list.indexOf(item)
              return true
            }
          })
        }
        setChooseSecond(res.second_tag_list[secondIndex])
        let chooseThird = { id: 0 } as Store.Category.TagItem
        if (res.second_tag_list[secondIndex].children?.length > 0) {
          if (searchParams.get('three_tag_id')) {
            let three_tag_id = searchParams.get('three_tag_id')
            let three_tag = res.second_tag_list[secondIndex].children.find(
              (item) => item.id == three_tag_id
            )
            chooseThird = three_tag ?? ({ id: 0 } as Store.Category.TagItem)
          }
        } else {
          chooseThird = { id: 0 } as Store.Category.TagItem
        }
        setChooseThree(chooseThird)
        fetchGoodesData(1, res.second_tag_list[0].id, chooseThird.id)
      } else {
        setGoods([])
        setMore(false)
      }
    })
  }

  // 上拉加载
  const handelChangePage = () => {
    fetchGoodesData(page + 1, chooseTag.id, chooseThree.id)
  }

  // 切换二级按钮
  const handleChangeSecond = (item: Store.Category.TagItem) => {
    setChooseSecond(item)
    setChooseThree({ id: 0 } as Store.Category.TagItem)
    fetchGoodesData(1, item.id, 0)
    setChooseTag(item)
  }
  // 切换三级按钮
  const handleChangeThree = (item: Store.Category.TagItem) => {
    setChooseThree(item)
    fetchGoodesData(1, chooseSecond.id, item.id)
    setChooseTag(item)
  }

  // 获取商品数据
  const fetchGoodesData = (
    page: number,
    second_tag_id: number | string | undefined,
    third_tag_id: number | string | undefined
  ) => {
    let params: any = {
      page_size: 20,
      page: page,
    }
    if (!['undefined', undefined, -10].includes(second_tag_id)) {
      params = {
        ...params,
        second_tag_id: second_tag_id,
        third_tag_id: third_tag_id ?? 0,
      }
    }

    getTagGoods(params).then((res) => {
      console.log('===', res)
      setGoods(res.goods_list)
      setMore(false)
    })
  }

  return (
    <div className="category pt-72">
      <Header activeNavigate="分类" zIndex={10} />
      <InfiniteScroll
        className="InfiniteScroll1"
        style={{ padding: `0 ${gridPadding}px` }}
        dataLength={goods.length}
        next={handelChangePage}
        height={window.innerHeight - 72}
        hasMore={more}
        pullDownToRefresh={false}
        scrollThreshold={'100px'}
        loader={
          <div className="flex flex-center mb-20">
            <Spin />
          </div>
        }
        endMessage={
          <div className="flex flex-center flex-col mb-20 mt-20">
            {goods.length === 0 && (
              <Empty description={''} image={Empty.PRESENTED_IMAGE_DEFAULT} />
            )}
            <b className="text-gray font-normal">暂无更多商品</b>
          </div>
        }
      >
        {first_tag_info?.banner_img && (
          <div className="banner_img_box">
            <img src={first_tag_info?.banner_img} className="banner_img" />
          </div>
        )}
        {showCategory && (
          <div className="filter-box">
            <div className="filter mt-36 mb-12 ">
              <div
                className="filter-con mt-28"
                style={{
                  justifyContent: showRightArrow ? 'flex-start' : 'center',
                }}
              >
                <div className="flex-inline">
                  {second_tag_list?.map((item, index) => (
                    <div
                      key={index}
                      className={`tag-inactive mr-8 mb-8 `}
                      onClick={() => handleChangeSecond(item)}
                    >
                      <span></span>
                      <div
                        className={`tag-title  ${
                          item.id === chooseSecond.id ? 'tag-active' : ''
                        }`}
                      >
                        {item.title}
                      </div>
                      {item.id === chooseSecond.id ? (
                        <div className="icon-current-box">
                          <img
                            src={require('../../static/icon/icon_current.png')}
                            className="icon-current"
                          ></img>
                        </div>
                      ) : null}
                      {index !== second_tag_list.length - 1 ? (
                        <div className="tag-line">/</div>
                      ) : (
                        <span></span>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {showRightArrow ? (
              <span className="right-arrow-box">
                <RightCircleOutlined
                  style={{ fontSize: '20px' }}
                  onClick={() => gotoRight()}
                />
              </span>
            ) : null}
            {/* 三级标签 */}
            {chooseSecond.children?.length > 0 ? (
              <div className="filter-three">
                <div
                  className={`filter-three-item`}
                  onClick={() =>
                    handleChangeThree({ id: 0 } as Store.Category.TagItem)
                  }
                >
                  <div
                    className={`tag-title  ${
                      chooseThree.id === 0 ? 'filter-three-item-active' : ''
                    }`}
                  >
                    全部
                  </div>
                </div>
                {chooseSecond.children.map((item, index) => (
                  <div
                    key={index}
                    className={`filter-three-item`}
                    onClick={() => handleChangeThree(item)}
                  >
                    <div
                      className={`tag-title  ${
                        item.id === chooseThree.id ? 'filter-three-item-active' : ''
                      }`}
                    >
                      {item.title}
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        )}

        <div className="flex flex-wrap flex-start flex-between filter-m mt-28">
          {goods.length > 0 &&
            goods.map((item, index) => (
              <GoodsCard
                key={index}
                className={`${isPc() ? 'mb-32' : 'mb-16'}`}
                goodsInfo={item}
              ></GoodsCard>
            ))}
          <p className="w-248"></p>
          <p className="w-248"></p>
          <p className="w-248"></p>
          <p className="w-248"></p>
          <p className="w-248"></p>
          <p className="w-248"></p>
          <p className="w-248"></p>
          <p className="w-248"></p>
          <p className="w-248"></p>
          <p className="w-248"></p>
        </div>
      </InfiniteScroll>
    </div>
  )
}

export default Tag_goods
