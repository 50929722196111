/*
 * @Author: axu 1342248325@qq.com
 * @Date: 2022-07-06 17:33:04
 * @LastEditors: zhao_juchang 2233737325@qq.com
 * @LastEditTime: 2022-09-05 14:06:07
 * @FilePath: /js-view-newbox-h5/src/components/GoodsCard/index.tsx
 * @Description: 商品卡片组件
 */
import React, { memo, useRef } from 'react'
import { isEqual } from 'lodash'
import { navigate_blank } from '@/utils/common'
import { useRouter } from '@/hooks'
import { isPc, getUrlShopId } from '@/utils/common'
import TemplateModel from '@/components/TemplateModel'
import Vipmark from '@/components/Vipmark'
import './index.scss'

interface ComponentProps {
  className?: string
  goodsInfo: Store.Goods.GoodsListItem
}
const GoodsCard: React.FC<ComponentProps> = (props: ComponentProps) => {
  const { className = '', goodsInfo } = props
  const { navigateTo, navigateReplace } = useRouter()

  const handelToGood = () => {
    if (goodsInfo.is_custom === 1) {
      window.open(`/goods_heli/${goodsInfo.id}?shop_id=${getUrlShopId()}`, '_blank')
      return
    }
    let url
    switch (goodsInfo.type) {
      case 1:
        url = `/package/${goodsInfo.id}?shop_id=${getUrlShopId()}`
        break
      case 2:
        url = `/goods/${goodsInfo.id}?shop_id=${getUrlShopId()}`
        break
      default:
        break
    }
    // navigateTo(url)
    window.open(url, '_blank')
  }

  return (
    <>
      <div
        className={`box-goods-card ${className} flex flex-col pointer`}
        onClick={handelToGood}
      >
        <div className={`good-card-cover  round-sm `}>
          {goodsInfo.type === 1 && (
            <img
              className='productSingle-item-tag'
              src='https://produce01.xiyin.love/js-view-c-mini-shop/images/taocan@2x.png'
            />
          )}
          {goodsInfo.is_custom === 1 && (
            <img
              className='productSingle-item-tag'
              src='https://produce01.xiyin.love/js-view-c-mini-shop/images/dingzhi.png'
            />
          )}
          <img
            src={goodsInfo.img}
            alt="商品图"
            loading="lazy"
            className="fit-img"
          />
        <div className='productSingle-item-footer'>
          <span className="good-card-title">
            {goodsInfo.name}
          </span>
          <span className="good-card-price">
            <span className="price-pre">¥</span>
            {goodsInfo.price}
          </span>
        </div>
        </div>
      </div>
      {/* 模版弹窗 */}
      {/* <TemplateModel ref={templateModelRef}></TemplateModel> */}
    </>
  )
}

export default memo(GoodsCard, isEqual)
