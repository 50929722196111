/*
 * @Author: axu 1342248325@qq.com
 * @Date: 2022-07-07 18:59:42
 * @LastEditors: axu 1342248325@qq.com
 * @LastEditTime: 2022-07-15 14:16:43
 * @FilePath: /js-view-newbox-h5/src/pages/good/GoodImgs/index.tsx
 * @Description: 商品营销图列表
 */
import React, { useEffect, useState } from 'react'
import './index.scss'

interface ComponentProps {
  market_img: string[]
}
const GoodsImgs: React.FC<ComponentProps> = (props: ComponentProps) => {
  const { market_img } = props
  const [chooseImg, setChooseImg] = useState<string>('' as string)

  useEffect(() => {
    if (market_img.length > 0) {
      setChooseImg(market_img[0])
    }
  }, [market_img])

  const handelChangeImg = (item: string) => {
    setChooseImg(item)
  }

  return (
    <div className='good-img-box'>
      <div className="good-img img">
        {market_img.length > 5 && (
          <div className="img-more shadow-primary top  flex flex-center">
            <span className="iconfont icon-arrow t4"></span>
          </div>
        )}
        <div className="img-content">
          {market_img.map((item, index) => (
            <div
              onClick={() => handelChangeImg(item)}
              className={`img-item round-sm ${
                index !== market_img.length - 1 ? 'mb-12 ' : ''
              } pointer`}
              key={index}
            >
              <img src={item} alt="营销图" className="fit-img" />
            </div>
          ))}
        </div>
        {market_img.length > 5 && (
          <div className="img-more shadow-primary bottom  flex flex-center">
            <span className="iconfont icon-arrow t4"></span>
          </div>
        )}
      </div>
      <div className="main-center round-sm mr-30">
        {chooseImg && <img src={chooseImg} alt="营销图" className="fit-img" />}
      </div>
    </div>
  )
}

export default GoodsImgs
