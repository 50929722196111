/*
 * @Author: axu 1342248325@qq.com
 * @Date: 2022-07-07 19:32:49
 * @LastEditors: zhao_juchang 2233737325@qq.com
 * @LastEditTime: 2022-09-02 19:47:48
 * @FilePath: /js-view-newbox-h5/src/apis/vip.ts
 * @Description: 会员相关
 */
import request from '@/utils/request'

/**
 * 获取充值金额
 */
export const getRechargeOptions = () =>
  request({
    method: 'GET',
    url: '/api/ToC/V1/Recharge/getRechargeOptions',
  })

/**
 * 获取会员充值选项
 */
export const getRechargeMemberOptions = () =>
  request({
    method: 'GET',
    url: '/api/ToC/V1/Recharge/getRechargeMemberOptions',
  })

// 创建充值订单
export const createRechargeOrder = (data) =>
  request({
    method: 'POST',
    url: '/api/ToC/V1/Recharge/createRechargeOrder',
    data,
  })

// 获取微信二维码
export const wechatScanRechargePay = (data: any) =>
  request({
    method: 'POST',
    url: '/api/ToC/V1/Pay/wechatScanRechargePay',
    data,
  })

// 获取支付宝二维码
export const alipayScanRechargePay = (data: any) =>
  request({
    method: 'POST',
    url: '/api/ToC/V1/Pay/alipayScanRechargePay',
    data,
  })

// 获取订单信息
export const getOrderInfo = (params: any) =>
  request({
    method: 'GET',
    url: '/api/ToC/V1/Recharge/getOrderInfo',
    params,
  })
