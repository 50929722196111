/*
 * @Author: axu 1342248325@qq.com
 * @Date: 2022-07-11 14:18:24
 * @LastEditors: zhao_juchang 2233737325@qq.com
 * @LastEditTime: 2022-09-07 16:38:47
 * @FilePath: /js-view-newbox-h5/src/components/OrderCard/index.tsx
 * @Description: 订单/购物车商品卡片
 */
import React from 'react'
import GoodsNumsPopover from '../GoodsNumsPopover'
import Vipmark from '@/components/Vipmark'
import { useAppSelector } from '@/store/store'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import './index.scss'

interface ComponentProps {
  className?: string
  onChangeNum?: (num: number) => void
  info: any
}
const OrderCard: React.FC<ComponentProps> = (props: ComponentProps) => {
  const { className, onChangeNum, info } = props
  const numFont = { sm: 't5', md: 't5', lg: 't4' }

  return (
    <div className={`box-order-card items-center flex flex-between ${className} `}>
      <div className='t2'>我要买的套装</div>
      <div
        className={`flex sm} ${
          info.status === 2 ? 'opacity' : ''
        }`}
      >
        {onChangeNum && info.num && (
          <div className="flex package-inside-item-btm speci-sku-num-box">
            <div
              className="speci-sku-btn "
              onClick={() => onChangeNum(info.num - 1)}
            >
              <MinusOutlined
                className={[
                  'iconfont t6 span-dark',
                  info.num === 1 ? 'icon-disable' : '',
                ].join(' ')}
              />
            </div>
            <input
              className="speci-sku-div"
              maxLength={4}
              value={info.num as any}
              onChange={(e) => onChangeNum(parseInt(e.target.value) || 1)}
            ></input>
            <div
              className="speci-sku-btn"
              onClick={() => onChangeNum(info.num + 1)}
            >
              <PlusOutlined className="iconfont t6 span-dark" />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default OrderCard
