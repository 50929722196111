/*
 * @Author: axu 1342248325@qq.com
 * @Date: 2022-07-04 20:12:26
 * @LastEditors: axu 1342248325@qq.com
 * @LastEditTime: 2022-08-08 16:54:27
 * @FilePath: /my-app/src/store/models/global.ts
 * @Description: global全局状态管理
 */
import { createModel } from '@rematch/core'
import { RootModel } from '.'
import { store } from '@/store/store'
import { isPc, gridInit } from '@/utils/common'

window.addEventListener('resize', () => {
  store.dispatch.global.resize()
})

export const global = createModel<RootModel>()({
  state: {
    userToken: window.localStorage.getItem('BY_userToken') || '',
    gridPadding: gridInit().padding,
    gridLen: gridInit().len,
    bannerBox: gridInit().bannerBox,
    bannerAdvertise: gridInit().bannerAdvertise,
    isPc: isPc(),
    openLoginStatus: false,
    searchInput: "",
  } as Store.Global.IGlobal,
  reducers: {
    setToken: (state, userToken: string) => {
      return {
        ...state,
        userToken,
      }
    },
    setResize: (state, data: any) => {
      return {
        ...state,
        ...data,
      }
    },
    setOpenLoginStatus: (state, data: any) => {
      return {
        ...state,
        ...data,
      }
    },
    setSearchInput: (state, data: any) => {
      return {
        ...state,
        ...data,
      }
    }
  },
  effects: (dispatch) => ({
    setUserToken(token: string) {
      dispatch.global.setToken(token)
    },
    clearLoginStatus() {
      window.localStorage.removeItem('BY_userToken')
      window.localStorage.removeItem('user_info')
      dispatch.user.setUserInfo({})
      dispatch.global.setToken('')
    },
    resize() {
      const data = {
        gridPadding: gridInit().padding,
        gridLen: gridInit().len,
        bannerBox: gridInit().bannerBox,
        bannerAdvertise: gridInit().bannerAdvertise,
        isPc: isPc(),
      }
      dispatch.global.setResize(data)
    },
    // 防止登录失效时多次弹出登录框
    changeLoginStatus(status) {
      dispatch.global.setOpenLoginStatus({ openLoginStatus: status })
    },
    // setSearchInput(value) {
    //   dispatch.global.setSearchInput({ searchInput: value })
    // }
  }),
})
