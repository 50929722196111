/*
 * @Author: axu 1342248325@qq.com
 * @Date: 2022-07-07 19:32:49
 * @LastEditors: axu 1342248325@qq.com
 * @LastEditTime: 2022-08-08 16:33:15
 * @FilePath: /js-view-newbox-h5/src/apis/user.ts
 * @Description: 用户相关
 */
import request from '@/utils/request'

/**
 * 开发人员登录
 */
export const idLogin = (id: number) =>
  request({
    method: 'POST',
    url: '/api/ToC/V1/Login/idLogin',
    data: { id: id },
  })

/*
 * 获取用户信息
 */
export const getUserInfo = () =>
  request({ method: 'GET', url: '/api/ToC/V1/Shop/getUserInfo' })

/**
 * 登录
 */
export const login = (code: string) =>
  request({
    method: 'POST',
    url: '/api/ToC/V1/Login/scanCode',
    data: { code },
  })

/**
 * 获取验证码
 */
export const getCode = (params: any) =>
  request({ method: 'GET', url: '/api/ToC/V1/Login/sendSms', params })

/**
 * 手机号验证码登录
 */
export const MClogin = (data: any) =>
  request({
    method: 'POST',
    url: '/api/ToC/V1/Login/loginByMobile',
    data: data,
  })

/**
 * 绑定手机号
 */
export const setMobile = (params: any) =>
  request({
    method: 'GET',
    url: '/api/ToC/V1/User/bindMobile',
    params,
  })

/**
 * 获取店铺信息
 */
export const getShopInfo = (params: any) =>
  request({
    method: 'GET',
    url: '/api/ToC/V1/Login/getShopInfo',
    params,
  })
