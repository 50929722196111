import React from 'react'
import './index.scss'

const TongTangFooter = () => {
  return (
    <div className="TongTangFooter">
      <div className="TongTangFooter-con">
        <div className="TongTangFooter-main">
          <div className="TongTangFooter-scetion1">
            <div className="scetion1-left">
              <div>
                <img src="https://baiyin-produce.oss-cn-hangzhou.aliyuncs.com/js-view-c-h5/images/tongTang1.png" />
              </div>
              <div className="label1 mt-18">
                <div>经营地址:北京市朝阳区望京中环南路甲2号17层A1907</div>
                <div>电话号码:400-888-3005(工作日09:30-18:00)</div>
                <div>电子邮箱:market@tongtanggift.com</div>
              </div>
            </div>
            <div className="scetion1-right">
              <div className="mr-80">
                <img
                  className="logo"
                  src="https://baiyin-produce.oss-cn-hangzhou.aliyuncs.com/js-view-c-h5/images/tongTangLogo.png"
                />
                <div className="label2 mt-10 ml-2">礼品定制就上一点礼至</div>
                <div
                  className="label2 mt-54 pointer"
                  onClick={() =>
                    window.open(
                      'https://yidianlizhi.com/pcMarketing/14?shop_id=7081'
                    )
                  }
                >
                  关于我们
                </div>
              </div>
              <div className="official-account pl-80">
                <img
                  src="https://baiyin-produce.oss-cn-hangzhou.aliyuncs.com/js-view-c-h5/images/tongTangGongZhongHao.png"
                  className="official-account-img"
                />
                <div className="official-account-label">关注官方公众号</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="TongTangFooter-scetion2">
        <div className="mr-70">京ICP备2023039018号-1</div>
        <div className="mr-70">版权所有北京礼至科技有限公司</div>
        <div>部分设计元素由客户提供,本站服务案例展示不代表版权归属情况</div>
      </div>
    </div>
  )
}
export default TongTangFooter
