import React, { useEffect, useState } from 'react'
import { scrollTo } from '@/utils/common'
import { Spin, Affix, FloatButton } from 'antd'
import './index.scss'
import { VerticalAlignTopOutlined } from '@ant-design/icons'

interface ComponentProps {
  topId: string
}

const CustomAffix: React.FC<ComponentProps> = (props: ComponentProps) => {
  const { topId } = props

  return (
    // <Affix
    //   offsetBottom={80}
    //   onChange={(affixed) => console.log(affixed)}
    //   style={{ position: 'absolute', right: '60px' }}
    // >
    //   <div className="Affix-icon-box" onClick={() => scrollTo(topId)}>
    //     <VerticalAlignTopOutlined />
    //   </div>
    // </Affix>
    <FloatButton
      shape="circle"
      type="default"
      style={{ right: 94 }}
      onClick={() => scrollTo(topId)}
      icon={<VerticalAlignTopOutlined />}
    />
  )
}
export default CustomAffix
