/*
 * @Author: axu 1342248325@qq.com
 * @Date: 2022-07-11 14:18:24
 * @LastEditors: zhao_juchang 2233737325@qq.com
 * @LastEditTime: 2022-09-07 16:38:47
 * @FilePath: /js-view-newbox-h5/src/components/OrderCard/index.tsx
 * @Description: 订单/购物车商品卡片
 */
import React from 'react'
import GoodsNumsPopover from '../GoodsNumsPopover'
import Vipmark from '@/components/Vipmark'
import { useAppSelector } from '@/store/store'
import { useRouter } from '@/hooks'
import { getUrlShopId, canCustome } from '@/utils/common'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import './index.scss'

interface ComponentProps {
  size?: 'sm' | 'md' | 'lg'
  className?: string
  onChangeNum?: (num: number) => void
  info: any
  source: 'cart' | 'order' | 'order_detail'
}
const OrderCard: React.FC<ComponentProps> = (props: ComponentProps) => {
  const { size = 'sm', className, onChangeNum, info, source } = props
  const { navigateTo, navigateReplace } = useRouter()
  const titleFont = { sm: 't5', md: 't4', lg: 't6 bold' }
  const sizeFont = { sm: 't5', md: 't5', lg: 't4' }
  const priceFont = { sm: 't5', md: 't4', lg: 't6 bold' }
  const numFont = { sm: 't5', md: 't5', lg: 't4' }
  const ml = { sm: 'ml-12', md: 'ml-16', lg: 'ml-20' }
  const webInfo = useAppSelector((state) => state.app.webInfo)

  // 去定制
  const handelToCustom = (sku_item: any) => {
    const devurl1 = 'http://192.168.200.72:3333/'
    const url = `${process.env.REACT_APP_NEW_H5}customMadeScss/scss?origin=${
      window.location.origin
    }&shop_id=${getUrlShopId()}&spuId=${Number(
      sku_item.shop_spu_id
    )}&sale_cart_item_id=${sku_item.id}&shop_spu_id=${
      sku_item.shop_spu_id
    }&mini_token=${window.localStorage.getItem('BY_userToken')}&source=pad`
    // navigateToH5(url)
    window.open(url, '_blank')
  }

  // 去商品详情
  const handelToGood = (item) => {
    if (item.is_custom === 1) {
      navigateTo(`/goods_heli/${item.shop_spu_id}`)
      return
    } else {
      navigateTo(`/goods/${item.shop_spu_id}`)
    }
  }

  return (
    <div className={`box-order-card flex flex-between ${className} `}>
      <div className="flex ">
        <div className={`card-img round-xs ${size}`}>
          <img src={info.img || info.sku_img} alt="" className="fit-img" />
          {info.status === 2 && (
            <div className="card-img-invalid t4 bold flex flex-center">
              失效
            </div>
          )}
          {info.is_member && <Vipmark radius="0 4px"></Vipmark>}
        </div>
        <div
          className={`flex flex-col ${ml[size]} ${
            size === 'lg' ? 'pt-8' : ''
          } ${info.status === 2 ? 'opacity' : ''}`}
          onClick={() => handelToGood(info)}
        >
          <span
            className={`${titleFont[size]} mb-8 ${
              source === 'cart' ? 'card-name' : ''
            }`}
          >
            {info.title}
          </span>
          <span className={`${priceFont[size]} text-gray`}>{info.spec}</span>
          <span className={`${priceFont[size]} text-light`}>
            {/* ¥{ info.sale_price || info.price} */}
            {info.showPrice}
          </span>
          {info.size && info.serial_number && (
            <span className={`${sizeFont[size]} text-gray mb-2`}>
              {info.serial_number} ({info.size.long} x {info.size.weight} x{' '}
              {info.size.high} cm)
            </span>
          )}
          {info.key_name && (
            <span className={`${sizeFont[size]} text-gray`}>
              {info.key_name}
            </span>
          )}
        </div>
      </div>
      <div
        className={`flex ${size === 'lg' ? '' : ''} ${
          info.status === 2 ? 'opacity' : ''
        }`}
      >
        {onChangeNum && (
          <div className="flex flex-col flex-y-end">
            <div className="flex package-inside-item-btm speci-sku-num-box">
              <div
                className="speci-sku-btn "
                onClick={() => info.status === 1 && onChangeNum(info.num - 1)}
              >
                <MinusOutlined
                  className={[
                    'iconfont t6 span-dark',
                    info.num <= info.min_buy_num ? 'icon-disable' : '',
                  ].join(' ')}
                />
              </div>
              <input
                className="speci-sku-div"
                maxLength={4}
                value={info.num as any}
                disabled={info.status !== 1}
                onChange={(e) =>
                  onChangeNum(
                    parseInt(e.target.value) <= info.min_buy_num
                      ? info.min_buy_num
                      : parseInt(e.target.value) || 1
                  )
                }
              ></input>
              <div
                className="speci-sku-btn"
                onClick={() => info.status === 1 && onChangeNum(info.num + 1)}
              >
                <PlusOutlined className="iconfont t6 span-dark" />
              </div>
            </div>
            {info.is_custom === 1 && canCustome() ? (
              <div
                className="packages-box-content-spec-dingzhi mt-6"
                onClick={() => handelToCustom(info)}
              >
                去定制
              </div>
            ) : null}
          </div>
        )}
        <div className={`flex flex-col`}>
          {!onChangeNum && (
            <span className={`${numFont[size]} text-gray mt-8`}>
              X{info.num}
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

export default OrderCard
