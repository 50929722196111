/*
 * @Author: axu 1342248325@qq.com
 * @Date: 2022-07-12 16:13:04
 * @LastEditors: axu 1342248325@qq.com
 * @LastEditTime: 2022-08-09 15:46:14
 * @FilePath: /js-view-newbox-h5/src/components/DatePicker/index.tsx
 * @Description: 日期选择器
 */
import React from 'react'
import { DatePicker } from 'antd'
import type { RangePickerProps } from 'antd/es/date-picker'
import moment, { Moment } from 'moment'
import dayjs from 'dayjs'
import 'moment/locale/zh-cn'
import locale from 'antd/es/date-picker/locale/zh_CN'
import './index.scss'

interface ComponentProps {
  LogisticsTime: any
  LogisticsType: number
  onChangeDate: (current: string) => void
  dateValue: string
  can_custom: boolean
}

const TimePicker: React.FC<ComponentProps> = (props: ComponentProps) => {
  moment.locale('zh-cn')
  const { LogisticsTime, LogisticsType, onChangeDate, dateValue, can_custom } =
    props

  const handelChange = (date: any) => {
    console.log('date', dayjs(date).format('YYYY/MM/DD'));
    
    // onChangeDate(moment(date).format('YYYY/MM/DD'))
    onChangeDate(dayjs(date).format('YYYY/MM/DD') as any)
  }

  const disabledDate = (current: Moment) => {
    const date = can_custom ? moment(new Date()).add(2, 'days') : new Date()
    const isAfter = current.isAfter(date)
    const datas: number[] = LogisticsTime.sign
    let flag = isAfter
    if (LogisticsType === 2) {
      flag = datas.includes(current.weekday() + 1) && isAfter
    }
    return !flag
  }

  return (
    <div className="box-datepicker flex flex-center pointer">
      <DatePicker
        disabledDate={disabledDate as RangePickerProps['disabledDate']}
        showToday={false}
        onChange={(e) => handelChange(e)}
        bordered={false}
        locale={locale}
        format={'YYYY/MM/DD'}
        value={dateValue ? dayjs(dateValue, 'YYYY/MM/DD') : null}
        className="box-datepicker-input round-xs flex flex-center"
        // dropdownClassName={'box-datepicker-popover'}
      />
    </div>
  )
}

export default TimePicker
