/*
 * @Author: axu 1342248325@qq.com
 * @Date: 2022-07-12 14:22:28
 * @LastEditors: axu 1342248325@qq.com
 * @LastEditTime: 2022-07-15 17:17:10
 * @FilePath: /js-view-newbox-h5/src/components/AddressList/index.tsx
 * @Description: 地址列表卡片
 */
import React, { useEffect, useState } from 'react'
import { Modal, Input } from 'antd'
import { useAppSelector, useAppDispatch } from '@/store/store'
import './index.scss'

interface ComponentProps {
  visable: boolean
  onClose: () => void
  onAddAddress: () => void
  onEditAddress: (res: Store.Address.AddressInfo) => void
}

const AddressList: React.FC<ComponentProps> = (props: ComponentProps) => {
  const { visable, onClose, onAddAddress, onEditAddress } = props
  const addressList = useAppSelector((state) => state.address.addressList)
  const [newAddressList, setNewAddressList] = useState<
    Store.Address.AddressInfo[]
  >(JSON.parse(JSON.stringify(addressList)))
  const addressInfo = useAppSelector((state) => state.address.addressInfo)
  const [chooseAddress, setChooseAddress] = useState<Store.Address.AddressInfo>(
    {} as Store.Address.AddressInfo
  )
  const dispatch = useAppDispatch()
  const { Search } = Input

  useEffect(() => {
    if (addressInfo?.username) {
      setChooseAddress({ ...addressInfo })
    }
  }, [addressInfo])

  useEffect(() => {
    setNewAddressList(JSON.parse(JSON.stringify(addressList)))
  }, [addressList])

  // 提交
  const handelSubmit = () => {
    dispatch.address.setAddressInfo(chooseAddress)
    dispatch.address.setDefaultAddressInfo(chooseAddress)
    onClose()
  }

  // 选中地址
  const handelChoose = (item: Store.Address.AddressInfo) => {
    setChooseAddress(item)
  }

  // 编辑
  const handelEdit = (item: Store.Address.AddressInfo) => {
    onEditAddress(item)
  }

  // 删除
  const handelDel = (item: Store.Address.AddressInfo) => {
    dispatch.address.delAddressInfo(item)
    if (item.id === chooseAddress.id) {
      setChooseAddress({} as Store.Address.AddressInfo)
    }
  }

  // 搜索地址列表过滤
  const addressSearch = (value) => {
    const newAddressList = addressList.filter((item) => {
      if (JSON.stringify(item).includes(value)) {
        return item
      }
    })
    setNewAddressList(newAddressList)
  }

  return (
    <Modal
      visible={visable}
      onCancel={onClose}
      centered={true}
      footer={null}
      // width={550}
      className="modal-address-list"
      bodyStyle={{ padding: '0' }}
      closeIcon={
        <span className="iconfont icon-yuyinguanbi t2 text-gray mt-20"></span>
      }
      zIndex={101}
    >
      <div className="box-address-list round-sm flex flex-col flex-y-center pt-48 pb-32 px-28">
        <span className="t1 mb-24">选择收货地址</span>
        <div className="box-address-list-main full">
          <Search
            placeholder="搜索"
            className="mb-12 address-search"
            onSearch={(value) => addressSearch(value)}
          />
          {newAddressList.map((item, index) => (
            <div
              className="box-address-list-item round-xs px-16 py-16 full flex flex-between flex-y-center mb-8"
              key={index}
            >
              <div className="flex flex-y-center">
                <span
                  className={`iconfont icon-icon_select t1 pointer ${
                    chooseAddress.id === item.id
                      ? 'text-icon-light'
                      : 'text-icon-gray'
                  }`}
                  onClick={() => handelChoose(item)}
                ></span>
                <div className="flex flex-col ml-16">
                  <span className="t4">
                    {item.username}，{item.mobile}
                  </span>
                  <span className="t4 text-gray mt-6">
                    {item?.province}
                    {item?.city}
                    {item?.area}
                    {item?.detail_address}
                  </span>
                </div>
              </div>
              <div className="flex flex-center">
                <span
                  className="iconfont icon-bianji t1 text-primary"
                  onClick={() => handelEdit(item)}
                ></span>
                <span className="t3 ml-8 mr-8" style={{ color: '#e4e4e4' }}>
                  |
                </span>
                <span
                  className="iconfont icon-shanchutianchong t1 text-icon-gray pointer mr-12"
                  onClick={() => handelDel(item)}
                ></span>
              </div>
            </div>
          ))}
        </div>

        <div className="mt-40 full px-30 box-address-list-footer">
          <button
            className="button button-plain-white button-lg"
            onClick={onAddAddress}
          >
            新增地址
          </button>
          <button
            className="button button-primary button-lg ml-16"
            onClick={handelSubmit}
          >
            确定
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default AddressList
