/*
 * @Author: axu 1342248325@qq.com
 * @Date: 2022-07-11 10:56:32
 * @LastEditors: axu 1342248325@qq.com
 * @LastEditTime: 2022-08-04 10:52:25
 * @FilePath: /js-view-newbox-h5/src/utils/toast.ts
 * @Description: 全局提示
 */
import { message } from 'antd'
let timer: any = window.localStorage.getItem('timer')
const Toast = {
  success: (content: string, duration = 2) => {
    if (timer) {
      clearTimeout(timer)
      message.destroy()
    }
    message.success({
      content,
      duration,
    })
    timer = setTimeout(() => {
      message.destroy()
      clearTimeout(timer)
    }, duration * 1000)
    window.localStorage.setItem('timer', timer)
  },
  warning: (content: string, duration = 2) => {
    if (timer) {
      clearTimeout(timer)
      message.destroy()
    }
    message.warning({
      content,
      duration,
    })
    timer = setTimeout(() => {
      message.destroy()
      clearTimeout(timer)
    }, duration * 1000)
  },
  error: (content: string, duration = 2) => {
    if (timer) {
      clearTimeout(timer)
      message.destroy()
    }
    message.error({
      content,
      duration,
    })
    timer = setTimeout(() => {
      message.destroy()
      clearTimeout(timer)
    }, duration * 1000)
  },
  loading: (content: string, duration = 0) => {
    if (timer) {
      clearTimeout(timer)
      message.destroy()
    }
    const hide = message.loading({
      content,
      duration,
      className: 'newbox-loading-message',
    })
    const hideCallBack = () => {
      hide()
      message.destroy()
    }
    return hideCallBack
  },
  hide: () => {
    message.destroy()
  },
}
export default Toast
