import React, { useEffect, useState } from 'react'
import Header from '@/components/Header'
import { useRouter } from '@/hooks'
import { Select, Spin, Empty } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons'
import { whiteList } from '@/utils/common'
import { useAppSelector, store } from '@/store/store'
import { getGoodsList, getTagTreeList, getTemplateList } from '@/apis/goods'
import { getCategoryList, getCategoryData } from '@/apis/home'
import GoodsCard from '@/components/GoodsCard'
import InfiniteScroll from 'react-infinite-scroll-component'
import { isPc } from '@/utils/common'
import logger from '@/utils/logger'
import './index.scss'

let timer: number
const Category: React.FC = () => {
  // const { Option } = Select
  const { routerParams, searchParams } = useRouter()
  const { categoryId, tagId } = routerParams
  // const { searchInput } = searchParams
  const [categoryList, setCategoryList] = useState<Store.Category.TagItem[]>([
    { title: '全部', id: -10 },
  ] as Store.Category.TagItem[])
  const [chooseCategory, setChooseCategory] = useState<Store.Category.TagItem>(
    {} as Store.Category.TagItem
  )
  const [chooseTag, setChooseTag] = useState<Store.Category.TagItem>(
    {} as Store.Category.TagItem
  )
  const gridPadding = useAppSelector((state) => state.global.gridPadding)
  const userInfo = useAppSelector((state) => state.user.userInfo)
  const [goods, setGoods] = useState<Store.Goods.GoodsListItem[]>([])
  const [page, setPage] = useState(1)
  const [more, setMore] = useState(true)
  const [showCategory, setShowCategory] = useState(true)
  const dispatch = store.dispatch

  useEffect(() => {
    let shopInfo = JSON.parse(window.localStorage.getItem('shop') ?? '{}')
    if (searchParams.get('shop_id')) {
      dispatch.user.onGetShopInfo({ shop_id: searchParams.get('shop_id') })
    }
    if (!whiteList.includes(window.location.origin)) {
      dispatch.user.onGetShopInfo({ shop_id: undefined })
    }
    if (
      searchParams.get('shop_id') &&
      shopInfo.id &&
      searchParams.get('shop_id') != shopInfo.id
    ) {
      return
    }
    fetchTagTreeList()
    if (isNaN(Number(categoryId))) {
      // setShowCategory(false)
      // fetchGoodesData(1, String(tagId))
      // @ts-ignore
      setChooseTag({ id: String(tagId) })
    } else {
      setShowCategory(true)
      fetchTagTreeList()
    }
  }, [])

  // 获取标签列表
  const fetchTagTreeList = () => {
    getCategoryList().then((res) => {
      setCategoryList([...categoryList, ...res])
      setChooseCategory(categoryList[0])
      fetchGoodesData(1, categoryList[0].id)
    })
  }

  // 上拉加载
  const handelChangePage = () => {
    fetchGoodesData(page + 1, chooseTag.id)
  }

  // 切换标签按钮
  const handleChangeTag = (item: Store.Category.TagItem) => {
    setChooseCategory(item)
    fetchGoodesData(1, item.id)
    setChooseTag(item)
  }

  // 获取商品数据
  const fetchGoodesData = (
    page: number,
    category_id: number | string | undefined,
    keyword?: string
  ) => {
    let params: any = {
      keyword: keyword ?? searchParams.get('searchInput'),
      page_size: 20,
      page: page,
    }
    if (!['undefined', undefined, -10].includes(category_id)) {
      params = {
        ...params,
        category_id: category_id,
      }
    }

    getCategoryData(params).then((res) => {
      setGoods(page == 1 ? [...res] : [...goods, ...res])
      setPage(page)
      if (res.length < 20) {
        setMore(false)
      } else {
        setMore(true)
      }
    })
  }

  return (
    <div className="category pt-72">
      <Header
        activeNavigate="分类"
        zIndex={10}
        searchConfirm={(e) => {
          fetchGoodesData(1, categoryList[0].id, e)
        }}
      />
      <InfiniteScroll
        className="InfiniteScroll1"
        style={{ padding: `0 ${gridPadding}px` }}
        dataLength={goods.length}
        next={handelChangePage}
        height={window.innerHeight - 72}
        hasMore={more}
        pullDownToRefresh={false}
        scrollThreshold={'100px'}
        loader={
          <div className="flex flex-center mb-20">
            <Spin />
          </div>
        }
        endMessage={
          <div className="flex flex-center flex-col mb-20 mt-20">
            {goods.length === 0 && (
              <Empty description={''} image={Empty.PRESENTED_IMAGE_DEFAULT} />
            )}
            <b className="text-gray font-normal">暂无更多商品</b>
          </div>
        }
      >
        {/* {showCategory && (
          <div className="filter mt-36  ">
            <div className="flex flex-y-center mt-28 filter-b flex-wrap">
              {categoryList?.map((item, index) => (
                <button
                  key={index}
                  className={`button mr-8 mb-8 button-cate ${
                    item.id === chooseCategory.id
                      ? 'button-plain '
                      : 'button-gray'
                  }`}
                  onClick={() => handleChangeTag(item)}
                >
                  {item.title}
                </button>
              ))}
            </div>
          </div>
        )} */}

        <div className="flex flex-wrap flex-start flex-between filter-m mt-28">
          {goods.length > 0 &&
            goods.map((item, index) => (
              <GoodsCard
                key={index}
                className={`${isPc() ? 'mb-32' : 'mb-16'}`}
                goodsInfo={item}
              ></GoodsCard>
            ))}
          <p className="w-248"></p>
          <p className="w-248"></p>
          <p className="w-248"></p>
          <p className="w-248"></p>
          <p className="w-248"></p>
          <p className="w-248"></p>
          <p className="w-248"></p>
          <p className="w-248"></p>
          <p className="w-248"></p>
          <p className="w-248"></p>
        </div>
      </InfiniteScroll>
    </div>
  )
}

export default Category
