/*
 * @Author: axu 1342248325@qq.com
 * @Date: 2022-07-08 14:40:33
 * @LastEditors: zhao_juchang 2233737325@qq.com
 * @LastEditTime: 2022-09-07 16:48:11
 * @FilePath: /js-view-newbox-h5/src/components/GoodsNumsPopover/index.tsx
 * @Description: 商品数量选择框
 */
import React, { ReactNode, useState, memo, useEffect } from 'react'
import { Popover, Input, Tooltip } from 'antd'
import { isEqual } from 'lodash'
import { useAppSelector } from '@/store/store'
import './index.scss'

interface ComponentProps {
  children: ReactNode
  interval_price: Store.Goods.GoodsSkusPrice[] | undefined
  onChangeNum: (num: number, price: number) => void
  width?: number
  number: number
  spu_id: number
  minBuyNum: number
  noPopover?: boolean
}

const GoodsNumsPopover: React.FC<ComponentProps> = (props: ComponentProps) => {
  const {
    children,
    interval_price,
    onChangeNum,
    number,
    minBuyNum = 1,
    noPopover = false,
  } = props
  const [visible, setVisible] = useState(false)
  const [isShowTip, setIsShowTip] = useState(false)
  const [num, setNum] = useState<number | string>('')
  const webInfo = useAppSelector((state) => state.app.webInfo)

  useEffect(() => {
    setNum(number === 0 ? '' : number)
  }, [number])

  const handleVisibleChange = () => {
    if (interval_price) {
      setVisible(!visible)
      if (visible === true && num) {
          onChangeNumPre(num)
      }
    }
  }

  const handelTipsChange = () => {
    if (interval_price) {
      setIsShowTip(false)
    } else {
      setIsShowTip(!isShowTip)
    }
  }

  const handelChooseNum = (num: number) => {
    onChangeNumPre(num)
    setNum(num)
  }

  const handelChangeNum = (e: any, minBuyNumNew: number) => {
    let newNum = 0
    minBuyNumNew = minBuyNumNew || minBuyNum
    if (e.target.value < minBuyNumNew) {
      newNum = minBuyNumNew
    } else if (e.target.value.length > 8) {
      newNum = e.target.value.splice(0, 8)
    } else {
      newNum = e.target.value
    }
    if (minBuyNumNew > 1) {
      onChangeNumPre(newNum)
    }
    setNum(newNum)
  }

  // 触发父组件的num和价格变化
  const onChangeNumPre = (newNum) => {
    if (newNum < minBuyNum) {
      newNum = minBuyNum
    }
    if (interval_price) {
      let price = 0
      interval_price.map((item) => {
        if (newNum >= item.min_num && newNum <= item.max_num) {
          price = Number(item.sale_price_format)
        }
      })
      onChangeNum(Number(newNum), price)
    }
  }

  const Content = () => {
    const { interval_price, width = 392 } = props
    return (
      <div
        className="box-num-popover px-12 py-12"
        style={{ width: `${width}px` }}
      >
        <div className="num-input">
          <Input
            placeholder="输入数量"
            bordered={false}
            className="num-input-content t4"
            value={num}
            onChange={(e) => handelChangeNum(e, 1)}
            onBlur={(e) => handelChangeNum(e, minBuyNum)}
            type="number"
            autoFocus={true}
          />
        </div>
        <div className="flex flex-col num-content">
          {interval_price?.map((item: any, index) => (
            <div
              className="flex flex-y-center flex-between mt-18 pointer"
              key={index}
              onClick={() => handelChooseNum(item.min_num)}
            >
              <span className="t4">
                {item.min_num}{index === interval_price.length - 1 ? '以上' : `-${item.max_num}`}
              </span>
              <div className="flex flex-y-center">
                <span className="t4 bold">¥{item.sale_price_format}/个</span>
                <span
                  className={`iconfont icon-icon_select t3  ml-8 ${
                    num >= item.min_num && num <= item.max_num
                      ? 'text-icon-light'
                      : 'text-icon-gray'
                  }`}
                ></span>
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }

  return noPopover ? (
    <Content />
  ) : (
    <Popover
      content={<Content />}
      title=""
      trigger="click"
      open={visible}
      placement="bottom"
      onOpenChange={handleVisibleChange}
      // arrowPointAtCenter={false}
      arrow={{ pointAtCenter: true }}
      overlayClassName="box-goods-num"
    >
      <Tooltip
        title="请先选择规格属性"
        color={'red'}
        open={isShowTip}
        onOpenChange={handelTipsChange}
      >
        {children}
      </Tooltip>
    </Popover>
  )
}

const isEqualS = (prevProps: ComponentProps, nextProps: ComponentProps) => {
  return isEqual(
    {
      interval_price: prevProps.interval_price,
      num: prevProps.number,
      spu_id: prevProps.spu_id,
    },
    {
      interval_price: nextProps.interval_price,
      num: nextProps.number,
      spu_id: nextProps.spu_id,
    }
  )
}

export default memo(GoodsNumsPopover, isEqualS)
