/*
 * @Author: axu 1342248325@qq.com
 * @Date: 2022-07-11 11:48:20
 * @LastEditors: zhao_juchang 2233737325@qq.com
 * @LastEditTime: 2022-09-08 10:09:30
 * @FilePath: /js-div-newbox-h5/src/pages/order/index.tsx
 * @Description: 订单列表
 */
import React, { useEffect, useState } from 'react'
import Header from '@/components/Header'
import OrderCard from '@/components/OrderCard'
import { getOrderList } from '@/apis/order'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Spin, Empty } from 'antd'
import { useRouter } from '@/hooks'
import { useAppSelector } from '@/store/store'
import './index.scss'

const Order: React.FC = () => {
  const [order, setOrder] = useState<Store.Order.OrderItem[]>([])
  const webInfo = useAppSelector((state) => state.app.webInfo)
  const [more, setMore] = useState(false)
  const { navigateTo } = useRouter()
  const [page, setPage] = useState(0)
  const orderClass = {
    待付款: 'text-red',
    待发货: 'text-green',
    部分发货: 'text-green',
    全部发货: 'text-green',
    已取消: 'text-primary',
  }

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {
    getOrderList().then((res) => {
      setOrder(res)
    })
  }

  const handelToDetail = (id: number) => {
    navigateTo(`/order_detail/${id}`)
  }

  return (
    <div className="order pt-72">
      <Header></Header>
      {order.length > 0 && (
        <InfiniteScroll
          className="flex flex-col flex-y-center"
          dataLength={order.length}
          next={() => {}}
          height={window.innerHeight - 72}
          scrollThreshold={'100px'}
          hasMore={more}
          pullDownToRefresh={false}
          loader={
            <div className="flex flex-center mb-20">
              <Spin />
            </div>
          }
          endMessage={
            <div className="flex flex-center mb-20">
              <b>暂无更多数据...</b>
            </div>
          }
        >
          <div className="order-content round-sm px-20 py-20 mt-60 mb-20">
            {order.map((item: any, index) => (
              <div className="order-card mb-16 pointer" key={index}>
                <div className="card-title flex flex-y-center flex-between pb-16">
                  <div>
                    <span className="t5 mr-30">
                    创建时间：{item.create_time}
                    </span>
                    <span className="t5">订单编号：{item.sale_order_sn}</span>
                  </div>
                  {/* @ts-ignore */}
                  <span className={`t5 order-status`}>{item.status_str}</span>
                </div>
                <div className="card-main pt-24 ">
                  <div className="flex">
                    {item.sale_order_item.map((item, i) => {
                      return i < 3 ? (
                        <div key={i}>
                          {
                            // @ts-ignore
                            item.sale_order_item_sku &&
                            item.sale_order_item_sku.length > 1 ? (
                              <div className="packages-box-imgList">
                                <img
                                  className="packages-box-imgList-img"
                                  src={
                                    item?.sale_order_item_sku?.[0].shop_sku_img
                                  }
                                />
                                {item.sale_order_item_sku?.[1] ? (
                                  <img
                                    className="packages-box-imgList-img"
                                    src={
                                      item.sale_order_item_sku?.[1].shop_sku_img
                                    }
                                  />
                                ) : (
                                  <div className="packages-box-imgList-bg"></div>
                                )}
                                {item.sale_order_item_sku?.[2] ? (
                                  <img
                                    className="packages-box-imgList-img"
                                    src={
                                      item.sale_order_item_sku?.[2].shop_sku_img
                                    }
                                  />
                                ) : (
                                  <div className="packages-box-imgList-bg"></div>
                                )}
                                {item.sale_order_item_sku?.[3] ? (
                                  <img
                                    className="packages-box-imgList-img"
                                    src={
                                      item.sale_order_item_sku?.[3].shop_sku_img
                                    }
                                  />
                                ) : (
                                  <div className="packages-box-imgList-bg"></div>
                                )}
                              </div>
                            ) : (
                              <div>
                                <img
                                  className="packages-box-img"
                                  src={
                                    item.sale_order_item_sku?.[0]?.shop_sku_img
                                  }
                                />
                              </div>
                            )
                          }
                        </div>
                      ) : (
                        ''
                      )
                    })}
                  </div>
                  <div className="card-right">
                    <div className="cart-right-info">
                      <div>共{item.sale_order_item.length}种组合</div>
                      <div className="mt-6">实付¥{item.pay_price}</div>
                    </div>
                    <div className="cart-right-btns">
                      <div onClick={() => handelToDetail(item.id)}>
                        查看详情
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </InfiniteScroll>
      )}
      {order.length === 0 && (
        <div
          className="flex flex-center"
          style={{ height: window.innerHeight - 72 + 'px' }}
        >
          <Empty description={'暂无内容'} image={Empty.PRESENTED_IMAGE_DEFAULT}>
            <button
              className="button button-primary button-lg cart-empty mt-20"
              onClick={() => navigateTo('/')}
            >
              去选购
            </button>
          </Empty>
        </div>
      )}
    </div>
  )
}

export default Order
