/*
 * @Author: axu 1342248325@qq.com
 * @Date: 2022-07-04 20:12:26
 * @LastEditors: zhao_juchang 2233737325@qq.com
 * @LastEditTime: 2022-09-05 10:58:52
 * @FilePath: /js-view-newbox-h5/src/store/models/user.ts
 * @Description: 用户信息
 */
import { createModel } from '@rematch/core'
import {
  idLogin,
  getUserInfo,
  login,
  MClogin,
  setMobile,
  getShopInfo,
} from '@/apis/user'
import { delUrlParam, getUrlShopId } from '@/utils/common'
import { message } from 'antd'
import { history } from '@/utils/history'
import { TriggerLogin } from '@/components/LoginWrapper'
import { store } from '@/store/store'
import { RootModel } from '.'

const userInfo: Store.User.UserInfo = JSON.parse(
  window.localStorage.getItem('user_info') ?? '{}'
)
const shop: Store.User.Shop = JSON.parse(
  window.localStorage.getItem('shop') ?? '{}'
)
const shop_id: string = window.localStorage.getItem('shop_id') ?? ''

export const user = createModel<RootModel>()({
  state: {
    userInfo: userInfo,
    shop: shop,
  },
  reducers: {
    setUserInfo: (state, userInfo: any) => {
      return {
        ...state,
        userInfo,
      }
    },
    setShop: (state, shop: any) => {
      return {
        ...state,
        shop,
      }
    },
  },
  effects: (dispatch) => ({
    fetchUserInfo() {
      if (window.localStorage.getItem('BY_userToken')) {
        getUserInfo().then((res: any) => {
          dispatch.user.setLoginParams(res)

          if (shop.config.customer && shop.config.customer.ico) {
            dispatch.user.setShopIcon(shop.config.customer.ico)
          }
          document.title = shop.shop_name
          // dispatch.user.setUserInfo(res.info)
          // dispatch.user.setShop(res.shop)
        })
      }
    },
    // 用户id登录：仅开发环境使用
    useIdLogin(id) {
      idLogin(id).then((res: any) => {
        dispatch.user.setLoginParams(res)
        // 没有手机号，弹出绑定手机号的弹框
        if (!res.info.mobile) {
          // TriggerLogin.open('bindMobile')
          TriggerLogin.open('bindMobile', `${window.location.pathname}${window.location.search}`, true)
        }
      })
    },
    // 微信扫码登录
    login(code) {
      login(code)
        .then((res: any) => {
          dispatch.user.setLoginParams({ ...res, type: 'wechat' })
          // 没有手机号，弹出绑定手机号的弹框
          if (!res.info.mobile) {
            // TriggerLogin.open('bindMobile',)
            TriggerLogin.open('bindMobile', `${window.location.pathname}${window.location.search}`, true)
          }
        })
        .catch((err) => {
          delUrlParam(['code', 'state'])
        })
    },
    //手机号验证码登录
    mobileLogin(res) {
      return new Promise((resolve, reject) => {
        if (!window.localStorage.getItem('shop')) {
          message.error('店铺不存在')
          return
        }
        MClogin({
          mobile: res.mobile,
          code: res.code,
          shop_id: JSON.parse(window.localStorage.getItem('shop') ?? '')?.id,
        }).then((res) => {
          dispatch.user.setLoginParams(res)
          resolve(res)
          // window.location.reload()
          window.location.href = `/?shop_id=${getUrlShopId()}`
        })
      })
    },
    // 绑定手机号
    bindMobile(res) {
      return new Promise((resolve, reject) => {
        setMobile({ mobile: res.mobile, code: res.code }).then((res: any) => {
          dispatch.user.setLoginParams(res)
          resolve(res)
        })
      })
    },
    setShopIcon(ico) {
      var links = document.head.getElementsByTagName('link')
      // 遍历 link 元素，找到 rel 属性为 "icon" 的元素
      for (var i = 0; i < links.length; i++) {
        if (links[i].rel === 'icon') {
          // 修改 href 属性为新的 favicon 地址
          links[i].href = ico
          break // 如果找到了，可以提前结束循环
        }
      }
    },
    // 记录公共参数
    setLoginParams(res: any) {
      dispatch.global.changeLoginStatus(false)
      window.localStorage.setItem('BY_userToken', res.token)
      window.localStorage.setItem('user_info', JSON.stringify(res.user))
      dispatch.global.setToken(res.token)
      dispatch.user.setUserInfo(res.user)
      // if (res.shop) {
      //   window.localStorage.setItem('shop', JSON.stringify(res.shop))
      //   dispatch.user.setShop(res.shop)
      //   document.title = res.shop.shop_name
      // }
      dispatch.cart.getCartnum()
    },
    // 获取店铺信息
    onGetShopInfo(res: any) {
      getShopInfo({ shop_id: res.shop_id }).then((res: any) => {
        // console.log('res=====', res);
        let shopInfo = JSON.parse(window.localStorage.getItem('shop') ?? '{}')

        if (res.config.customer && res.config.customer.ico) {
          dispatch.user.setShopIcon(res.config.customer.ico)
        }
        document.title = res.shop_name

        // console.log('shopInfo', shopInfo);

        if (res.id && shopInfo.id && res.id != shopInfo.id) {
          dispatch.global.clearLoginStatus()
        }
        window.localStorage.setItem('shop', JSON.stringify(res))
        dispatch.user.setShop(res)
        document.title = res.shop_name
      })
    },
  }),
})
