/*
 * @Author: axu 1342248325@qq.com
 * @Date: 2022-07-06 13:58:50
 * @LastEditors: zhao_juchang 2233737325@qq.com
 * @LastEditTime: 2022-09-13 11:35:40
 * @FilePath: /js-view-newbox-h5/src/components/Header/index.tsx
 * @Description: 公共头部
 */
import React, { useEffect, useState, useRef } from 'react'
import HeaderSearch from '@/components/HeaderSearch'
import LoginWrapper from '@/components/LoginWrapper'
import { useRouter } from '@/hooks'
import { useAppSelector, useAppDispatch } from '@/store/store'
import PayPurse from '@/components/PayPurse'
import AddressCard from '@/components/AddressCard'
import { Popover } from 'antd'
import { TriggerLogin } from '@/components/LoginWrapper'
import Toast from '@/utils/toast'
const classifyAll = require('@/static/img/classify-all.png')
const classifyAllActive = require('@/static/img/classify-all-active.png')
import MLoginWrapper from '../MLoginWrapper'
import './index.scss'
import { DownOutlined, UpOutlined } from '@ant-design/icons'

interface ComponentProps {
  activeNavigate?: string
  zIndex?: number
  searchConfirm?: (e?: string) => void
}

const Header: React.FC<ComponentProps> = (props: ComponentProps) => {
  const { activeNavigate = '', zIndex = 101, searchConfirm } = props
  const { navigateTo, searchParams, navigateBlank } = useRouter()
  const userToken = useAppSelector((state) => state.global.userToken)
  const userInfo = useAppSelector((state) => state.user.userInfo)
  const tagTree = useAppSelector((state) => state.home.tagTree)
  const shop = useAppSelector((state) => state.user.shop)
  const addressInfo = useAppSelector((state) => state.address.addressInfo)
  const webInfo = useAppSelector((state) => state.app.webInfo)
  const cartnum = useAppSelector((state) => state.cart.cart_num)
  const dispatch = useAppDispatch()
  const [payPurseVisible, setPayPurseVisible] = useState(false)
  const isPc = useAppSelector((state) => state.global.isPc)
  const [logoImgWidth, setLogoImgWidth] = useState('148px')
  const childRef = useRef<any>()
  const [curClassifyChildIndex, setCurClassifyChildIndex] = useState(0)
  const [showCLassify, setShowCLassify] = useState(false)

  const handelToPage = (path: string) => {
    // navigateTo(path)
    navigateBlank(path)
  }

  const handelLogOut = () => {
    dispatch.global.clearLoginStatus()
  }

  // 获取logo图片的宽高
  const logoImgInfo = () => {
    // 图片地址
    var img_url = webInfo.info?.logo
    // 创建对象
    var img = new Image()
    // 改变图片的src
    img.src = img_url
    // 打印
    // 加载完成执行
    img.onload = function () {
      // 打印
      // alert('width:' + img.width + ',height:' + img.height)
      console.log(img.width, img.height)
      setLogoImgWidth(`${img.width / (img.height / 28)}px`)
    }
  }
  // 设置ico图标
  const setIco = () => {
    let link = document.createElement('link')
    link.rel = 'icon'
    link.href = webInfo.info.icon
    document.getElementsByTagName('head')[0].appendChild(link)
  }
  // 设置title标题
  const setTitle = () => {
    document.title = webInfo.info.title
  }
  useEffect(() => {
    // dispatch.app.fetchWebInfo()
    let shopInfo = JSON.parse(window.localStorage.getItem('shop') ?? '{}')
    dispatch.home.getTagTree()
    if (userToken) {
      if (
        searchParams.get('shop_id') &&
        shopInfo.id &&
        searchParams.get('shop_id') != shopInfo.id
      ) {
        return
      }
      dispatch.cart.getCartnum()
      // if (userInfo) {
      //   return
      // }
      dispatch.user.fetchUserInfo()
    }
  }, [])

  useEffect(() => {
    if (webInfo.info?.icon) {
      setIco()
    }
    if (webInfo.info?.title) {
      setTitle()
    }
    if (webInfo.info?.logo) {
      logoImgInfo()
    }
  }, [webInfo])

  const callChildFunction = () => {
    if (childRef.current) {
      dispatch.address.fetchRegionOptions()
      childRef.current.handelShowAddressList()
      // console.log(childRef.current);
    }
  }

  // pc端popver
  const content = (
    <div className="box-header-popover">
      <div className="flex flex-strat py-12 borderBtm">
        <div className="avatar ml-14">
          <img
            src={
              userInfo.avatar ||
              'https://baiyin-produce.oss-cn-hangzhou.aliyuncs.com/js-view-newBox-h5/image/avatar.png'
            }
          />
        </div>
        {/* {shop.shop_name && (
          <div className="info">
            <span className="t4 ml-14">{shop?.shop_name}</span>
          </div>
        )} */}
      </div>
      <div
        className="py-10 t5 pl-16 pointer"
        onClick={() => callChildFunction()}
      >
        我的收货地址
      </div>
      <div
        className="py-10 t5 pl-16 pointer"
        onClick={() => handelToPage('/order')}
      >
        我的订单
      </div>
      <div className="py-10 t5 text-gray pl-16 pointer" onClick={handelLogOut}>
        退出登录
      </div>
      <PayPurse
        visible={payPurseVisible}
        onClose={() => {
          setPayPurseVisible(false)
        }}
      ></PayPurse>
      {/* 地址卡片 */}
      <AddressCard ref={childRef} source="header" addressInfo={addressInfo} />
    </div>
  )

  const renderClassify = () => {
    return (
      <div className="renderClassify">
        <div className="renderClassify-one-box">
          <div className="renderClassify-one">
            {tagTree.map((item: any, index: number) => {
              return (
                <div
                  className={`one-item ${
                    curClassifyChildIndex === index ? 'one-item-active' : ''
                  }`}
                  key={index}
                  // onClick={() => handelToPage(`/classify?tag_id=${item.id}`)}
                  onMouseEnter={() => {
                    setCurClassifyChildIndex(index)
                  }}
                >
                  <img src={item.icon_img} className="one-icon ml-24" />
                  <div className="one-text">{item.title}</div>
                </div>
              )
            })}
          </div>
        </div>
        <div className="renderClassify-right-box">
          <div className="renderClassify-right">
            {tagTree[curClassifyChildIndex]?.children?.map(
              (item: any, index: number) => {
                return (
                  <div
                    className="right-item"
                    key={index}
                    onClick={() =>
                      handelToPage(
                        `/tagGoods?first_tag_id=${tagTree[curClassifyChildIndex].id}&second_tag_id=${item.id}`
                      )
                    }
                  >
                    <div className="right-two">
                      <span className="right-two-line"></span>
                      <span className="right-two-text">{item.title}</span>
                    </div>
                    {item.children && item.children.length > 0 ? (
                      <div className="right-three">
                        {item.children &&
                          item.children?.map(
                            (threeItem: any, threeIndex: number) => {
                              return (
                                <span
                                  className="right-three-text"
                                  onClick={(e) => {
                                    handelToPage(
                                      `/tagGoods?first_tag_id=${tagTree[curClassifyChildIndex].id}&second_tag_id=${item.id}&three_tag_id=${threeItem.id}`
                                    )
                                    e.stopPropagation()
                                  }}
                                >
                                  {threeItem.title}
                                </span>
                              )
                            }
                          )}
                      </div>
                    ) : null}
                  </div>
                )
              }
            )}
          </div>
        </div>
      </div>
    )
  }
  return (
    <div
      className={`box-header pl-28 pr-28 flex flex-y-center flex-between`}
      style={{ zIndex: zIndex }}
    >
      {/* logo 菜单 */}
      <div className="flex flex-y-center">
      <div
          className="header-logo flex"
          onClick={() => handelToPage('/')}
        >
          {
            shop?.id ? (
              <>
              {shop?.logo ? (
                <img src={shop.logo} />
              ) : (
                <img src={require('../../static/img/heli.png')} alt="" />
              )}
              </>
            ) : null
          }
        </div>
        <div className="header-navigate flex ml-32">
          <Popover
            content={renderClassify}
            placement="bottomLeft"
            arrow={false}
            overlayClassName={'header-classify'}
            overlayStyle={{
              width: 400,
              height: 400
            }}
            onOpenChange={(e) => {
              setShowCLassify(e)
            }}
          >
            <div
              className={`mr-32 pointer classify-all ${
                showCLassify ? 'classify-hover' : ''
              }`}
            >
              {showCLassify ? (
                <img className="classify-left" src={classifyAllActive} />
              ) : (
                <img className="classify-left" src={classifyAll} />
              )}
              <span className={`mr-8 classify-all-text`}>全部分类</span>
              {showCLassify ? (
                <UpOutlined style={{ fontSize: '12px' }} />
              ) : (
                <DownOutlined style={{ fontSize: '12px' }} />
              )}
            </div>
          </Popover>
        </div>
      </div>
      <div className="header-user flex flex-y-center flex-x-end">
        {/* 搜索input */}
        <div className="header-search mr-20">
          <HeaderSearch
            confirm={(e) => {
              searchConfirm && searchConfirm(e)
            }}
          ></HeaderSearch>
        </div>
        <LoginWrapper redirect_uri="/cart">
          <div
            className="header-cart iconfont icon-gouwuche pointer ml-20"
            onClick={() => handelToPage('/cart')}
          >
            {userToken && cartnum > 0 && (
              <div className="cart-num flex flex-center bold"></div>
            )}
          </div>
        </LoginWrapper>
        {!userToken && (
          <span onClick={() => window.localStorage.removeItem('login_redirect_uri')}>
          <LoginWrapper>
            <button className="button button-plain-gray t5 bold pointer" >
              登录
            </button>
          </LoginWrapper>
          </span>
        )}
        {userToken && (
          <Popover
            trigger="hover"
            placement="bottom"
            title={''}
            content={content}
          >
            <div className="header-avatar pointer ml-20">
              <img
                src={
                  userInfo.avatar ||
                  'https://baiyin-produce.oss-cn-hangzhou.aliyuncs.com/js-view-newBox-h5/image/avatar.png'
                }
                alt=""
              />
            </div>
          </Popover>
        )}
      </div>
    </div>
  )
}

export default Header
