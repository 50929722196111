/*
 * @Author: axu 1342248325@qq.com
 * @Date: 2022-07-04 11:24:38
 * @LastEditors: axu 1342248325@qq.com
 * @LastEditTime: 2022-07-16 10:29:49
 * @FilePath: /my-app/src/pages/notFound/index.tsx
 * @Description: 404页面
 */
import React from 'react'
import useRouter from '@/hooks/useRouter'
import { Result } from 'antd'

const NotFound: React.FC = () => {
  const { navigateBack } = useRouter()

  const handelBack = () => {
    navigateBack()
  }

  return (
    <div className="flex flex-center" style={{ height: '100vh' }}>
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <button
            className="button button-primary button-lg"
            onClick={handelBack}
          >
            返回上一页
          </button>
        }
      />
    </div>
  )
}

export default NotFound
