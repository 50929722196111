import React, { useState, useEffect } from 'react'
import useRouter from '@/hooks/useRouter'
import { getCustomInfo } from '@/apis/custom'
import { useAppSelector, store } from '@/store/store'
import { isPc, whiteList } from '@/utils/common'
import { Empty } from 'antd'
import './index.scss'

const PcMarketing: React.FC = () => {
  const { routerParams, navigateBack, searchParams } = useRouter()
  const { id } = routerParams
  const [innerhtml, setInnerHtml] = useState<any>()
  const dispatch = store.dispatch

  useEffect(() => {
    onGetCustomInfo()
  }, [id])

  useEffect(() => {
    let shopInfo = JSON.parse(window.localStorage.getItem('shop') ?? '{}')
    if (searchParams.get('shop_id')) {
      dispatch.user.onGetShopInfo({ shop_id: searchParams.get('shop_id') })
    }
    // if (!whiteList.includes(window.location.origin)) {
    //   dispatch.user.onGetShopInfo({ shop_id: undefined })
    // }
  }, [])

  const onGetCustomInfo = async () => {
    try {
      const res: any = await getCustomInfo({ id: id })
      console.log('====', res)
      // setInnerHtml(res.content)
      if (res.list.length > 0 && res.list[0].html) {
        setInnerHtml(res.list[0].html)
      } else {
        setInnerHtml('')
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const handelBack = () => {
    navigateBack()
  }

  const handleClick = (event) => {
    if (
      event.target.tagName === 'IMG' &&
      event.target.hasAttribute('data-href')
    ) {
      const dataHref = event.target.getAttribute('data-href')
      //  window.location.href = dataHref;
      window.open(dataHref, '_blank')
    }
  }

  return (
    <div className="PcMarketing">
      {innerhtml && innerhtml.length > 0 ? (
        <div
          className="innerHtml"
          dangerouslySetInnerHTML={{ __html: innerhtml }}
          onClick={(e) => handleClick(e)}
        ></div>
      ) : (
        <Empty description={''} image={Empty.PRESENTED_IMAGE_DEFAULT} />
      )}
    </div>
  )
}

export default PcMarketing
