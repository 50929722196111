/*
 * @Author: axu 1342248325@qq.com
 * @Date: 2022-07-04 10:00:47
 * @LastEditors: axu 1342248325@qq.com
 * @LastEditTime: 2022-08-11 10:41:53
 * @FilePath: /my-app/src/router/router.tsx
 * @Description: 路由入口
 */
import React, { lazy, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '@/store/store'
import { getQueryVariable } from '@/utils/common'
import WrapRouter from './wrapRouter'
import { history, HistoryRouter } from '@/utils/history'
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom'
const home = lazy(() => import('@/pages/design_template'))
const goods = lazy(() => import('@/pages/goods'))
import GetRoutes from './routeConfig'

const AppRoutes: React.FC = () => {
  const dispatch = useAppDispatch()
  const userToken = useAppSelector((state) => state.global.userToken)

  // useEffect(() => {
  //   const code = getQueryVariable('code')
  //   if (code) {
  //     dispatch.user.login(code)
  //   }
  // }, [])

  return (
    <Router>
      <GetRoutes />
    </Router>
  )
}

export default AppRoutes
