/*
 * @Author: axu 1342248325@qq.com
 * @Date: 2022-07-06 19:16:00
 * @LastEditors: zhao_juchang 2233737325@qq.com
 * @LastEditTime: 2022-09-06 14:54:02
 * @FilePath: /js-view-newbox-h5/src/components/PayPurse/index.tsx
 * @Description: 全局支付
 */
import React, { useState, useEffect } from 'react'
import { Modal, Spin, Statistic } from 'antd'
import { store } from '@/store/store'
import Toast from '@/utils/toast'
// import QRCode from 'qrcode'
import {
  getRechargeOptions,
  createRechargeOrder,
  wechatScanRechargePay,
  alipayScanRechargePay,
  getOrderInfo,
} from '@/apis/vip'
import './index.scss'

interface ComponentProps {
  visible: boolean
  onClose: () => void
}

const PayVip: React.FC<ComponentProps> = (props: ComponentProps) => {
  const { visible, onClose } = props
  const [alipayCode, setAlipayCode] = useState('')
  const [wechatCode, setWechatCode] = useState('')
  const [timer, setTimer] = useState<any>('')
  const { Countdown } = Statistic
  const [deadline, setDeadline] = useState(0)
  const [rechargeOptions, setRechargeOptions] = useState([])
  const [amount, setAmount] = useState(0)
  const [orderId, setorderId] = useState('')
  const dispatch = store.dispatch

  useEffect(() => {
    if (visible) {
      // if (order_time) {
      //   setDeadline(new Date(order_time).getTime() + 1000 * 60 * 60 * 2)
      // } else {
      //   setDeadline(Date.now() + 1000 * 60 * 60 * 2)
      // }
      // getWechatCode({ order_id }).then((res) => {
      //   QRCode.toDataURL(res)
      //     .then((url: string) => {
      //       setWechatCode(url)
      //     })
      //     .catch((err: string) => {
      //       console.error(err)
      //     })
      // })
      // getAlipayCode({ order_id }).then((res) => {
      //   QRCode.toDataURL(res)
      //     .then((url: string) => {
      //       setAlipayCode(url)
      //     })
      //     .catch((err: string) => {
      //       console.error(err)
      //     })
      // })
      fetchOrderInfo()
      // 获取充值列表
      getRechargeOptions().then((res) => {
        setRechargeOptions(res)
        setAmount(res[0])
        console.log(res)
      })
    }
  }, [visible])
  // 创建充值订单
  const onCreateRechargeOrder = function () {
    createRechargeOrder({
      type: 1,
      amount: amount,
    }).then((res) => {
      setorderId(res.recharge_order_id)
    })
  }

  const fetchOrderInfo = () => {
    const timer = setInterval(() => {
      getOrderInfo({ recharge_order_id: orderId }).then((res) => {
        if (res.status === 2) {
          Toast.success('支付成功')
          dispatch.user.fetchUserInfo()
          clearInterval(timer)
          onClose()
        }
      })
    }, 2000)
    setTimer(timer)
  }

  // 金额选项变化时创建充值订单
  useEffect(() => {
    if (amount > 0 && visible) {
      onCreateRechargeOrder()
    }
  }, [amount, visible])
  // 订单id变化时重新获取微信和支付宝二维码
  useEffect(() => {
    if (visible) {
      wechatScanRechargePay({ recharge_order_id: orderId }).then((res) => {
        // QRCode.toDataURL(res)
        //   .then((url: string) => {
        //     setWechatCode(url)
        //   })
        //   .catch((err: string) => {
        //     console.error(err)
        //   })
      })
      alipayScanRechargePay({ recharge_order_id: orderId }).then((res) => {
        // QRCode.toDataURL(res)
        //   .then((url: string) => {
        //     setAlipayCode(url)
        //   })
        //   .catch((err: string) => {
        //     console.error(err)
        //   })
      })
      fetchOrderInfo()
    }
  }, [orderId])

  // 清除定时器副作用
  useEffect(() => {
    return () => {
      clearInterval(timer)
    }
  }, [timer])

  const handleCancel = () => {
    clearInterval(timer)
    setAlipayCode('')
    setWechatCode('')
    onClose()
  }

  const onFinish = () => {
    console.log('finished!')
    onClose()
  }

  return (
    <div className="box-paycode">
      <Modal
        destroyOnClose
        visible={visible}
        onCancel={handleCancel}
        footer={null}
        width={540}
        bodyStyle={{ padding: '0' }}
        centered
        closeIcon={
          <span className="iconfont icon-yuyinguanbi t2 text-gray mt-20"></span>
        }
        zIndex={103}
      >
        <div className="paycode flex flex-col flex-x-center">
          <span className="t1 bold flex flex-x-center mt-30">选择充值金额</span>
          <div
            className="code mt-12 flex flex-center  mb-60 flex-col mt-16"
            id="pay_container"
          >
            <div className="recharge-options">
              {rechargeOptions.map((item) => {
                return (
                  <div
                    className={amount === item ? 'recharge-checked' : ''}
                    onClick={() => setAmount(item)}
                  >
                    <span className="t4 mr-4">¥</span>
                    {item}
                  </div>
                )
              })}
            </div>
            <div className="flex mt-48">
              <div className="flex flex-col flex-y-centex">
                {wechatCode && <img src={wechatCode} alt="" className="img" />}
                {!wechatCode && (
                  <div className="img flex flex-center">
                    <Spin />
                  </div>
                )}
                <div className="flex flex-center">
                  <span className="iconfont icon-wechat t3 text-wechat"></span>
                  <span className="t3 ml-8">微信支付</span>
                </div>
              </div>
              {/* <div className="flex flex-col flex-y-centex  ml-12">
                {alipayCode && <img src={alipayCode} alt="" className="img" />}
                {!alipayCode && (
                  <div className="img flex flex-center">
                    <Spin />
                  </div>
                )}
                <div className="flex flex-center">
                  <span className="iconfont icon-alipay t3 text-alipay"></span>
                  <span className="t3 ml-8">支付宝支付</span>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default PayVip
