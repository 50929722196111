/*
 * @Author: axu 1342248325@qq.com
 * @Date: 2022-07-07 18:10:23
 * @LastEditors: zhao_juchang 2233737325@qq.com
 * @LastEditTime: 2022-09-08 10:35:00
 * @FilePath: /js-view-newbox-h5/src/pages/mall/mallBanner/index.tsx
 * @Description: 商城banner组件
 */

import React, { useRef } from 'react'
import './index.scss'
import { useAppSelector } from '@/store/store'
import { useRouter } from '@/hooks'
import { Carousel, Button } from 'antd'
import { navigate_blank } from '@/utils/common'
import { useSwipeable } from 'react-swipeable'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'

interface ComponentProps {
  bannerBox: {
    width: number
    height: number
  }
  bannerAdvertise: {
    width: number
    height: number
  }
  bannerList: any
  gridPadding: number
}

const MallBanner: React.FC<ComponentProps> = (props: ComponentProps) => {
  const { bannerBox, bannerAdvertise, bannerList, gridPadding } = props
  const { navigateTo, navigateReplace } = useRouter()
  const webInfo = useAppSelector((state) => state.app.webInfo)
  const carouselEL: any = useRef(null)

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      eventData.event.preventDefault()
    },
    onSwipedLeft: (eventData) => {
      console.log(eventData)
      carouselEL.current.next()
      eventData.event.preventDefault()
    },
    onSwipedRight: (eventData) => {
      console.log(eventData)
      carouselEL.current.prev()
      eventData.event.preventDefault()
    },
    // swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true,
  })

  return (
    <div className="mall-banner banner flex mt-28 flex-between" id="mallBanner">
      {bannerList.length > 1 ? (
        <div
          className="banner-box round-sm"
          style={{
            // width: `${bannerBox.width}px`,
            width: `100vw`,
            // height: `${bannerBox.height + 140}px`,
            height: `30vw`,
          }}
        >
          <div
            className="heli-Carousel"
            style={{
              // height: `${bannerBox.height + 140}px`
              height: `30vw`,
            }}
          >
            <div
              className="leftButton"
              style={{ left: '10%' }}
              onClick={() => {
                carouselEL.current.prev()
              }}
            >
              <LeftOutlined />
            </div>
            <div
              className="rightButton"
              style={{ right: '10%' }}
              onClick={() => {
                carouselEL.current.next()
              }}
            >
              <RightOutlined />
            </div>
            <div>
              <Carousel
                autoplay
                centerMode
                centerPadding="8%"
                autoplaySpeed={5000}
                ref={carouselEL}
              >
                {bannerList.map((item: any) => {
                  return (
                    <div key={item.img} {...handlers}>
                      <div
                        className="imgbox"
                        style={{
                          // height: `${bannerBox.height + 140}px`,
                          height: `30vw`,
                        }}
                        onClick={() => {
                          if (item.type === 3 && item.path) {
                            let url = item.path.startsWith('http')
                              ? item.path
                              : `http://${item.path}`
                            window.open(url)
                          }
                        }}
                      >
                        <img
                          src={item.img}
                          alt="banner"
                          style={{
                            // minHeight: `${bannerBox.height + 140}px`,
                            height: `30vw`,
                          }}
                        />
                      </div>
                    </div>
                  )
                })}
              </Carousel>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ width: '100%' }}>
          <img
            src={bannerList[0]?.img}
            alt="banner"
            className="banner-one-img"
            onClick={() => {
              if (bannerList[0]?.type === 3 && bannerList[0]?.path) {
                let url = bannerList[0]?.path.startsWith('http')
                  ? bannerList[0]?.path
                  : `http://${bannerList[0]?.path}`
                window.open(url)
              }
            }}
          ></img>
        </div>
      )}

      {/* <div
        className="banner-advertise round-sm"
        style={{
          width: `${bannerAdvertise.width}px`,
          height: `${bannerAdvertise.height}px`,
        }}
      >
        {webInfo?.info?.service ? (
          <img src={webInfo.info.service} alt="banner" className="fit-img" />
        ) : (
          <img
            src="https://produce01.xiyin.love/js-view-newBox-h5/image/qsd_advertise1.png"
            alt="banner"
            className="fit-img"
          />
        )}
      </div> */}
    </div>
  )
}

export default MallBanner
