/*
 * @Author: axu 1342248325@qq.com
 * @Date: 2022-07-06 19:16:00
 * @LastEditors: zhao_juchang 2233737325@qq.com
 * @LastEditTime: 2022-09-05 14:32:36
 * @FilePath: /js-view-newbox-h5/src/components/PayCode/index.tsx
 * @Description: 全局支付
 */
import React, { useState, useEffect } from 'react'
import { Modal, Spin, Statistic } from 'antd'
import { getWechatCode, getAlipayCode } from '@/apis/pay'
import QRCode from 'qrcode'
import { getOrderInfo } from '@/apis/order'
import './index.scss'

interface ComponentProps {
  visible: boolean
  onClose: () => void
  order_id: number | string
  order_time: string
  pay_type?: number | string // -1: 微信+支付宝支付 1: 微信支付 2: 支付宝支付
}

const PayCode: React.FC<ComponentProps> = (props: ComponentProps) => {
  const { visible, onClose, order_id, order_time, pay_type = -1 } = props
  const [alipayCode, setAlipayCode] = useState('')
  const [wechatCode, setWechatCode] = useState('')
  const [timer, setTimer] = useState<any>('')
  const { Countdown } = Statistic
  const [deadline, setDeadline] = useState(0)

  useEffect(() => {
    if (visible) {
      if (order_time) {
        setDeadline(new Date(order_time).getTime() + 1000 * 60 * 60 * 2)
      } else {
        setDeadline(Date.now() + 1000 * 60 * 60 * 2)
      }
      if (pay_type === 1) {
        getWechatCode({ sale_order_id: order_id, pay_type: 'scan' }).then((res) => {
          QRCode.toDataURL(res.code_url)
            .then((url: string) => {
              setWechatCode(url)
            })
            .catch((err: string) => {
              console.error(err)
            })
        })
      }
      if (pay_type === 2) {
        getAlipayCode({ sale_order_id: order_id }).then((res) => {
          QRCode.toDataURL(res)
            .then((url: string) => {
              setAlipayCode(url)
            })
            .catch((err: string) => {
              console.error(err)
            })
        })
      }
      fetchOrderInfo()
    }
  }, [visible])

  const fetchOrderInfo = () => {
    const timer = setInterval(() => {
      getOrderInfo({ id: order_id }).then((res) => {
        if (res.status === 2) {
          onClose()
        }
      })
    }, 2000)
    setTimer(timer)
  }

  useEffect(() => {
    return () => {
      clearInterval(timer)
    }
  }, [timer])

  const handleCancel = () => {
    clearInterval(timer)
    setAlipayCode('')
    setWechatCode('')
    onClose()
  }

  const onFinish = () => {
    console.log('finished!')
    onClose()
  }

  return (
    <div className="box-paycode">
      <Modal
        destroyOnClose
        visible={visible}
        onCancel={handleCancel}
        footer={null}
        width={380}
        bodyStyle={{ padding: '0' }}
        centered
        closeIcon={
          <span className="iconfont icon-yuyinguanbi t2 text-gray "></span>
        }
        zIndex={103}
      >
        <div className="paycode flex flex-col flex-x-center">
          <span className="t1 bold flex flex-x-center mt-30">
            请扫描二维码付款
          </span>
          {/* 微信支付和支付宝支付并存 */}
          {pay_type === -1 && (
            <div
              className="code mt-12 flex flex-center  mb-60 flex-col mt-16"
              id="pay_container"
            >
              <Countdown
                value={deadline}
                onFinish={onFinish}
                className="code-cuontdown"
              />
              <div className="flex mt-48">
                <div className="flex flex-col flex-y-centex">
                  {wechatCode && (
                    <img src={wechatCode} alt="" className="img" />
                  )}
                  {!wechatCode && (
                    <div className="img flex flex-center">
                      <Spin />
                    </div>
                  )}
                  <div className="flex flex-center">
                    <span className="iconfont icon-wechat t3 text-wechat"></span>
                    <span className="t3 ml-8">微信支付</span>
                  </div>
                </div>
                <div className="flex flex-col flex-y-centex  ml-12">
                  {alipayCode && (
                    <img src={alipayCode} alt="" className="img" />
                  )}
                  {!alipayCode && (
                    <div className="img flex flex-center">
                      <Spin />
                    </div>
                  )}
                  <div className="flex flex-center">
                    <span className="iconfont icon-alipay t3 text-alipay"></span>
                    <span className="t3 ml-8">支付宝支付</span>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* 微信支付或者支付宝支付 */}
          {[1, 2].includes(Number(pay_type)) && (
            <div
              className="code mt-12 flex flex-center  mb-60 flex-col mt-16"
              id="pay_container_each"
            >
              <Countdown
                value={deadline}
                onFinish={onFinish}
                className="code-cuontdown"
              />
              <div className="flex mt-24">
                {pay_type === 1 && (
                  <div className="flex flex-col flex-y-centex">
                    {wechatCode && (
                      <img src={wechatCode} alt="" className="img" />
                    )}
                    {!wechatCode && (
                      <div className="img flex flex-center">
                        <Spin />
                      </div>
                    )}
                  </div>
                )}
                {pay_type === 2 && (
                  <div className="flex flex-col flex-y-centex  ml-12">
                    {alipayCode && (
                      <img src={alipayCode} alt="" className="img" />
                    )}
                    {!alipayCode && (
                      <div className="img flex flex-center">
                        <Spin />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  )
}

export default PayCode
