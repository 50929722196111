import React, { memo, useState } from 'react'
import './index.scss'

interface ITemplateImg {
  is_member?: number
  original_img: string
  render_img: string
  template_id: number
}

interface IProps {
  templateImg: Store.Product.IProductDetailTemplate[]
  chooseTemplateId: number | string
  label?: string
  setChooseTemplateId: (id: number) => void
}

const TemplateList: React.FC<IProps> = (props) => {
  const { templateImg, chooseTemplateId, setChooseTemplateId, label="模版" } = props

  // 切换模版
  const handelChangeTemplate = (id: number, goodItem: any) => {
    setChooseTemplateId(id)
  }

  return (
    <>
      {templateImg?.length > 0 && (
        <div className='product-detailmap'>
          <div className="t4 bold mb-10 pt-10">{label}</div>
          <div className='template flex flex-between flex-wrap'>
            {templateImg.map((item, index) => (
              <div
                className={`temitem round-sm mb-8 ${
                  Number(chooseTemplateId) === Number(item.template_id)
                    ? 'hot'
                    : ''
                }`}
                onClick={() => handelChangeTemplate(item.template_id, item)}
                key={index}
              >
                <img
                  src={`${item.image}?x-oss-process=image/resize,w_800,m_lfit`}
                  className='fit-img'
                />
                {/* {item.is_member > 0 && (
                        <Vipmark radius='0 4rpx'></Vipmark>
                      )} */}
              </div>
            ))}
            <div className='null'></div>
            <div className='null'></div>
            <div className='null'></div>
            <div className='null'></div>
            <div className='null'></div>
          </div>
        </div>
      )}
    </>
  )
}

export default memo(TemplateList)
