/*
 * @Author: axu 1342248325@qq.com
 * @Date: 2022-07-06 10:04:54
 * @LastEditors: zhao_juchang 2233737325@qq.com
 * @LastEditTime: 2022-09-06 11:12:58
 * @FilePath: /js-view-newbox-h5/src/pages/mall/index.tsx
 * @Description: 商城
 */
import React, { useEffect, useState } from 'react'
import Header from '@/components/Header'
import GoodsCard from '@/components/GoodsCard'
import { useAppSelector, store } from '@/store/store'
import InfiniteScroll from 'react-infinite-scroll-component'
import { TriggerLogin } from '@/components/LoginWrapper'
import { Spin, Affix, FloatButton } from 'antd'
import { useRouter } from '@/hooks'
import { getGoodsList, getTagTreeList } from '@/apis/goods'
import { getBanner, getFirstTagContent } from '@/apis/home'
import FloatingLayer from '@/components/FloatingLayer'
import { whiteList } from '@/utils/common'
import MallTabs from './mallTabs'
import MallBanner from './mallBanner'
import MallTag from './mallTag'
import TongTangFooter from './TongTangFooter'
import CustomAffix from '@/components/CustomAffix'
import Kefu from '@/components/Kefu'
import './index.scss'
import { VerticalAlignTopOutlined } from '@ant-design/icons'

const Mall: React.FC = () => {
  const { routerParams, searchParams } = useRouter()
  const shop = useAppSelector((state) => state.user.shop)
  const gridPadding = useAppSelector((state) => state.global.gridPadding)
  const gridLen = useAppSelector((state) => state.global.gridLen)
  const bannerBox = useAppSelector((state) => state.global.bannerBox)
  const bannerAdvertise = useAppSelector(
    (state) => state.global.bannerAdvertise
  )
  const [category, setCategory] = useState<Store.Category.CategoryItem[]>([])
  const [goods, setGoods] = useState<Store.Goods.GoodsListItem[]>([])
  const [page, setPage] = useState(1)
  const [more, setMore] = useState(true)
  const [floatVisiable, setFloatVisiable] = useState(true)
  const [isFloatVisiable, setIsFloatVisiable] = useState(false)
  const [timer, setTimer] = useState<any>(undefined)
  const [bannerList, setBannerList] = useState<any>([])
  const [tagList, setTagList] = useState<any>([])
  const [scale, setScale] = useState<any>(1)
  const [pc_mid_html, set_pc_mid_html] = useState<any>()
  const [pc_bottom_html, set_pc_bottom_html] = useState<any>()
  const [shop_id, set_shop_id] = useState<string | null>('')
  const dispatch = store.dispatch

  useEffect(() => {
    let shopInfo = JSON.parse(window.localStorage.getItem('shop') ?? '{}')
    if (searchParams.get('shop_id') && searchParams.get('shop_id') !== null) {
      dispatch.user.onGetShopInfo({ shop_id: searchParams.get('shop_id') })
      set_shop_id(searchParams.get('shop_id'))
    } else if (!whiteList.includes(window.location.origin)) {
      dispatch.user.onGetShopInfo({ shop_id: undefined })
    } else {
      if (shopInfo.id) {
        set_shop_id(shopInfo.id)
      }
    }
    if (searchParams.get('login')) {
      TriggerLogin.open()
    }
    onGetBanner(searchParams.get('shop_id') ?? shopInfo.id)
    onGetFirstTagContent(searchParams.get('shop_id') ?? shopInfo.id)
    if (window.innerWidth < 1000) {
      let scale = window.innerWidth / 1000
      setScale(scale)
    }
  }, [])

  // 获取banner图片
  const onGetBanner = (shop_id) => {
    getBanner({ shop_id: shop_id }).then((res) => {
      console.log(res)
      setBannerList(res.banner)
      if (res.pc_mid_html) {
        set_pc_mid_html(res.pc_mid_html)
      }
      if (res.pc_bottom_html) {
        set_pc_bottom_html(res.pc_bottom_html)
      }
    })
  }

  // 获取ipad/pc 新首页主要数据
  const onGetFirstTagContent = async (shop_id) => {
    try {
      let res = await getFirstTagContent({ shop_id: shop_id })
      console.log('res', res)
      if (res.tag_list) {
        setTagList(res.tag_list)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handelChangePage = () => {}

  const handleClick = (event) => {
    if (
      event.target.tagName === 'IMG' &&
      event.target.hasAttribute('data-href')
    ) {
      const dataHref = event.target.getAttribute('data-href')
      //  window.location.href = dataHref;
      window.open(dataHref, '_blank')
    }
  }

  // 监听滚动
  const handelScroll = (e: any) => {
    setFloatVisiable(false)
    clearTimeout(timer)
    if (!isFloatVisiable) {
      const timer = setTimeout(() => {
        setFloatVisiable(true)
      }, 1000)
      setTimer(timer)
    }
  }

  return (
    <div className="mall pt-72">
      <Header activeNavigate="商城" zIndex={10}></Header>
      <MallBanner
        bannerBox={bannerBox}
        bannerAdvertise={bannerAdvertise}
        bannerList={bannerList}
        gridPadding={gridPadding}
      />
      <div className="mall-scroll">
        <div
          className="mall-main main"
          style={{
            padding: `0 ${window.innerWidth >= 1280 ? gridPadding : 0}px`,
          }}
        >
          <MallTag tagList={tagList} />

          {tagList.map((item, index) => (
            <MallTabs tags={item} key={index} gridLen={gridLen} />
          ))}
          {pc_mid_html && pc_mid_html.length > 0 ? (
            <div
              className="innerHtml innerHtml-center"
              dangerouslySetInnerHTML={{ __html: pc_mid_html }}
              onClick={(e) => handleClick(e)}
            ></div>
          ) : null}
        </div>
      </div>
      {pc_bottom_html && pc_bottom_html.length > 0 ? (
        <div
          className="innerHtml"
          dangerouslySetInnerHTML={{ __html: pc_bottom_html }}
          onClick={(e) => handleClick(e)}
        ></div>
      ) : null}
      {/* 铜棠有礼特有的底部 */}
      {shop.id && shop.id === 7081 ? <TongTangFooter></TongTangFooter> : null}
      {/* <FloatingLayer
        visiable={floatVisiable}
        onClose={() => {
          setFloatVisiable(false)
          setIsFloatVisiable(true)
        }}
      /> */}
      <FloatButton.Group shape="circle" style={{ right: 24 }}>
        {shop.config && shop.config.length !== 0 && shop.config.customer ? (
          <Kefu></Kefu>
        ) : null}
        <CustomAffix topId="mallBanner"></CustomAffix>
      </FloatButton.Group>
    </div>
  )
}

export default Mall
