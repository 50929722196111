/*
 * @Author: axu 1342248325@qq.com
 * @Date: 2022-07-07 19:55:16
 * @LastEditors: zhao_juchang 2233737325@qq.com
 * @LastEditTime: 2022-09-07 16:35:47
 * @FilePath: /js-view-newbox-h5/src/utils/common.ts
 * @Description: 工具类
 */
import { store } from '@/store/store'
const webInfo: any = JSON.parse(window.localStorage.getItem('webInfo') || '{}')
const shop: any = JSON.parse(window.localStorage.getItem('shop') || '{}')
const user: any = JSON.parse(window.localStorage.getItem('user_info') || '{}')

export const whiteList = ['http://localhost:3000', 'http://127.0.0.1:3000', 'https://2c.h5.helibox.com', 'http://121.40.66.187:31694']

// 获取地址栏参数
export const getQueryVariable = (variable: string) => {
  const query = decodeURIComponent(window.location.href).split('?')[1]
  if (query) {
    const vars = query.split('&')
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=')
      if (pair[0] === variable) {
        return pair[1]
      }
    }
  }
  return ''
}

export const getUrlShopId = () => {
  const shopId = getQueryVariable('shop_id')
  return shopId
}

// 获取网络图片宽高
export const getImageWidth = (url: string) => {
  return new Promise<{ width: number; height: number }>((resolve, reject) => {
    const img = new Image()
    img.src = url
    img.onload = () => {
      const obj = {
        width: img.width,
        height: img.height,
      }
      resolve(obj)
    }
  })
}

// 悄无声息的删除地址栏部分参数😊
export const delUrlParam = (paramkeys: string[]) => {
  let url = window.location.href //页面url
  let urlParam = window.location.search.substr(1) //页面参数
  let beforeUrl = window.location.pathname //页面主地址（参数之前地址）
  let nextUrl = ''
  let arr = new Array()
  if (urlParam !== '') {
    var urlParamArr = urlParam.split('&') //将参数按照&符分成数组
    for (var i = 0; i < urlParamArr.length; i++) {
      let paramArr = urlParamArr[i].split('=') //将参数键，值拆开
      //如果键雨要删除的不一致，则加入到参数中
      if (!paramkeys.includes(paramArr[0])) {
        arr.push(urlParamArr[i])
      }
    }
  }
  if (arr.length > 0) {
    nextUrl = '?' + arr.join('&')
  }
  url = beforeUrl + nextUrl
  window.history.replaceState(null, '', url)
}

// 开启新的标签页
export const navigate_blank = (params: string) => {
  const url = window.location.origin + params
  window.open(url, '_blank')
}

export const isPc = () => {
  // 如果是分站的话（恒晟图文、蝶儿只有pc）
  if (webInfo.id) {
    return true
  }
  if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
    return false
  } else {
    return true
  }
}

// 适配，计算当前页面宽度，一行最多能展示几个商品
export const gridInit = () => {
  const WW = window.innerWidth
  const len = Math.floor(WW / 248)
  const ceilPadding = WW > 900 ? 248 : 0
  const maxpadding = WW - (len * 248 + (len - 1) * 1) - ceilPadding 
  const minpadding = WW - ((len - 1) * 248 + (len - 2) * 1)
  let padding = maxpadding > 0 ? maxpadding / 2 : minpadding / 2
  const maxlen = maxpadding > 0 ? len : len - 1
  const contentW = WW - padding * 2
  const bannerBox = { width: 0, height: 0 }
  const bannerAdvertise = { width: 0, height: 0 }
  // banner在1280以下的宽度和1280以上的宽度不一样
  if (WW >= 1280) {
    bannerAdvertise.width = contentW * 0.1957 //  0.1217
    bannerAdvertise.height = (380 / 360) * bannerAdvertise.width
  } else {
    // padding = 0
    bannerAdvertise.width = contentW * 0.2038
    bannerAdvertise.height = (212 / 210) * bannerAdvertise.width
  }
  bannerBox.width = contentW * 1 // 0.8696
  bannerBox.height = (380 / 1460) * bannerBox.width
  return { len: maxlen, padding: padding, bannerBox, bannerAdvertise }
}


export const download = (href, title?) => {
  const a = document.createElement('a')
  a.setAttribute('href', href)
  a.setAttribute('download', title || '')
  a.click()
  a.remove()
}

// 图片base64转化为file
export const base64ToFile = (dataUrl: any, name: any) => {
  const baseList = dataUrl.split(',')
  const bstr = atob(baseList[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], `${name}.jpg`, { type: 'image/png' })
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = decodeURIComponent(url.split('?')[1]).replace(/\+/g, ' ')
  if (!search) {
    return {}
  }
  const obj = {}
  const searchArr = search.split('&')
  searchArr.forEach((v) => {
    const index = v.indexOf('=')
    if (index !== -1) {
      const name = v.substring(0, index)
      const val = v.substring(index + 1, v.length)
      obj[name] = val
    }
  })
  return obj
}

// 判断设备的横竖屏状态,并重新处理font-size
export const checkOrientation = () => {
  if (window.orientation === 0 || window.orientation === 180) {
    // setTimeout(() => {
      // alert(window.innerWidth + ' ' + window.innerHeight);
      console.log('竖屏模式');
      // 在竖屏模式下执行的操作
      // document.documentElement.style.fontSize = Math.max(window.innerWidth / 95, 12) + "px";
      document.documentElement.style.fontSize = Math.max(window.innerWidth / 90, 12) + "px";
    // }, 1000);
  } else if (window.orientation === 90 || window.orientation === -90) {
    // setTimeout(() => {
      // alert(window.innerWidth + ' ' + window.innerHeight);
      console.log('横屏模式');
      // 在横屏模式下执行的操作
      document.documentElement.style.fontSize = Math.max(window.innerWidth / 90, 12) + "px";
    // }, 1000);
  } else {
    document.documentElement.style.fontSize = Math.max(window.innerWidth / 120, 12) + "px";
  }
}

// 获取sku的实际价格
export const getActualPrice = (sku) => {
  return sku.member_card_price > 0 ? sku.member_card_price : sku.price
}

// 计算折后价
export const on_final_price = (skuList, skuBox?) => {
  const list = skuList ? skuList : []
  const box = skuBox ? skuBox : {}
  const total_price = (
    Number(box?.price || 0) +
    list.reduce(
      (num, item) =>
        num + Number(getActualPrice(item)) * 100 * Number(item.num),
      0
    ) /
      100
  ).toFixed(2)
  const final_price = ((Number(total_price) * 100 * Number(1)) / 100).toFixed(2)
  return final_price
}

// 处理数据---总内搭数
export const on_total_num = (skuList, skuBox?) => {
  const list = skuList ? skuList : []
  // console.log(skuBox)
  // const box = skuBox ? skuBox : {}
  // const n = box?.sku_id ? 1 : 0
  const total_num = list.reduce((num, item) => num + Number(item.num), 0)
  return total_num
}

// 在打开 Modal 时添加事件监听器
export function disableScroll() {
  document.body.style.overflow = 'hidden';
}

// 在关闭 Modal 时移除事件监听器
export function enableScroll() {
  document.body.style.overflow = 'auto';
}

// 滚动到对应id元素
export const scrollTo = (id) => {
  setTimeout(() => {
    let el: any = document.getElementById(`${id}`)
    el.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'nearest',
    })
  }, 100)
}

// 加法
export function add(a, b) {
  const precision = Math.max(getPrecision(a), getPrecision(b))
  const multiplier = Math.pow(10, precision)
  return (a * multiplier + b * multiplier) / multiplier
}

// 减法
export function subtract(a, b) {
  const precision = Math.max(getPrecision(a), getPrecision(b))
  const multiplier = Math.pow(10, precision)
  return (a * multiplier - b * multiplier) / multiplier
}

// 乘法
export function multiply(a, b) {
  const precision = getPrecision(a) + getPrecision(b)
  const multiplier = Math.pow(10, precision)
  return (a * multiplier * (b * multiplier)) / (multiplier * multiplier)
}

// 除法
export function divide(a, b) {
  const precision = getPrecision(a) - getPrecision(b)
  const divisor = Math.pow(10, Math.abs(precision))
  return (a * divisor) / (b * divisor)
}

// 获取小数位数
export function getPrecision(num) {
  const match = String(num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/)
  if (!match) return 0
  return Math.max(
    0,
    // Number of digits right of decimal point.
    (match[1] ? match[1].length : 0) -
      // Adjust for scientific notation.
      (match[2] ? +match[2] : 0)
  )
}

// 判断是否可以定制
export const canCustome = () => {
  console.log('store.user', store.getState().user);
  const user1 = store.getState().user.userInfo
  const shop1 = store.getState().user.shop
  if (!user1 || !shop1) {
    return false
  }
  if (!shop1.config.vipCanCustomize || ((shop1.config.vipCanCustomize || [undefined, null].includes(shop1.config.vipCanCustomize) ) && user1.member)) {
    return true
  }
  return false
}